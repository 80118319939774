
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import CategoryPage from "../generatedTablePage/CategoryPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddCategory from "../generatedAddEdit/category/AddCategory";
  import EditCategory from "../generatedAddEdit/category/EditCategory";
  

  
  
  const CategoryRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<CategoryPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditCategory/>
    }
  />
  <Route
    path="/add"
    element={
      <AddCategory/>
    }
  />      
         
      </Routes>
    );
  };
  export default CategoryRoutes
