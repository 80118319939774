
    import { createSlice, PayloadAction } from '@reduxjs/toolkit'
    
    type TAccessoryState =  {
        data: [],
        perPage: number,
        currentPage: number,
        totalPage: number,
        searchTerm: string,
        loading: boolean,
        sortBy: string,
        sortColumn:string,
        rerender1:boolean,
        searchMappedAccessories:string
    }
    
    
    const initialAccessoryState: TAccessoryState = {
        data: [],
        perPage: 10,
        currentPage: 1,
        totalPage: 1,
        searchTerm: "",
        loading: false,
        sortBy: "desc",
        sortColumn:"",
        rerender1:false,
        searchMappedAccessories:""
    }
    
    export const accessorySlice = createSlice({
        name: 'accessory',
        initialState:initialAccessoryState,
        reducers: {
            setAccessory: (state:TAccessoryState, { payload }: PayloadAction<[]>) => {
              state.data=payload
             },
            setPerPage: (state:TAccessoryState, {payload}: PayloadAction<number>) => {
                state.perPage = payload;
            },
            setCurrentPage: (state:TAccessoryState, {payload}: PayloadAction<number>) => { 
                state.currentPage = payload;
            },
            setTotalPage: (state:TAccessoryState, {payload}: PayloadAction<number>) => { 
              state.totalPage= payload  
            },
            setSearchTerm: (state:TAccessoryState, {payload}: PayloadAction<string>) => { 
                state.searchTerm = payload;
            },
            setSortBy: (state:TAccessoryState, {payload}: PayloadAction<string>) => { 
              state.sortBy = payload;
          },
          setSortColumn: (state:TAccessoryState, {payload}: PayloadAction<string>) => { 
            state.sortColumn = payload;
            state.sortBy = "desc";
        },
            setLoading: (state:TAccessoryState, {payload}: PayloadAction<boolean>) => { 
              state.loading = payload
            },
            setRerender: (state:TAccessoryState, {payload}: PayloadAction<boolean>) => {
                state.rerender1 = payload
             },
            setMappedAccessorySearchTerm:(state:TAccessoryState,{payload}: PayloadAction<string>) => {
                  state.searchMappedAccessories = payload
            },
        },
    })
    
    export const { setAccessory, setPerPage, setCurrentPage, setSortColumn,setSortBy, setTotalPage, setSearchTerm, setLoading,setRerender,setMappedAccessorySearchTerm } = accessorySlice.actions
    export default accessorySlice.reducer
    