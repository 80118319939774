import React from 'react'
import { TBadgeProps } from '../../types/Badge';


const Badge = ({ variant, className, ...props }: TBadgeProps) => {
  let _className = "";
  _className = ` badge badge-light-${variant} ` + className;

  return (
    <span {...props} className={_className}>{props.children}</span>
  )
}

export default Badge