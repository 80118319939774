import { useCallback, useEffect, useState } from "react";
import TableView from "../components/TableView";
import LeadTableAction from "../generatedTableActions/LeadTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setLead,
  setSearchTerm,
  setTotalPage,
} from "../generatedSlice/leadSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getLead } from "../generatedRequest/lead";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import LeadList from "../generatedTableList/LeadList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import { ThandleAfterSearchData } from "../types/TableActions";

const columns = [
  {
    name: "Name",
    renderHeader: () => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row: any) => <>{row.fullName}</>,
  },
  {
    name: " Email",
    renderHeader: () => (
      <>
        <b> Email</b>
      </>
    ),
    renderCell: (row: any) => <>{row.email}</>,
  },
  {
    name: " Address",
    renderHeader: () => (
      <>
        <b> Address</b>
      </>
    ),
    renderCell: (row: any) => <>{row.address}</>,
  },
  {
    name: " Phone",
    renderHeader: () => (
      <>
        <b> Phone</b>
      </>
    ),
    renderCell: (row: any) => <>{row.phone}</>,
  },
  {
    name: " CreatedAt",
    renderHeader: () => (
      <>
        <b> CreatedAt</b>
      </>
    ),
    renderCell: (row: any) => <>{row.createdAt}</>,
  },
  {
    name: " UpdatedAt",
    renderHeader: () => (
      <>
        <b> UpdatedAt</b>
      </>
    ),
    renderCell: (row: any) => <>{row.createdAt}</>,
  },
  {
    name: " Country",
    renderHeader: () => (
      <>
        <b> Country</b>
      </>
    ),
    renderCell: (row: any) => <>{row.country}</>,
  },
  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row: any) => <LeadTableAction lead={row} />,
  },
];

const LeadPage = () => {
  const dispatch = useDispatch();
  const [initload, setInitload] = useState(true);

  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
    rerender1,
  } = useSelector((state: RootState) => state.lead);
  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
      dispatch(setLoading(true));
      try {
        const response = await getLead(searchQuery);
        if (response.data.status) {
          dispatch(setLead(response?.data?.data));
          dispatch(
            setTotalPage(Math.ceil(response?.data?.data?.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      }
      finally {
        dispatch(setLoading(false));
        setInitload(false);
      }
    };
    fn();
  }, [dispatch, rerender1]);

  const { doSearch, searchLoading } = useSearchApi(getLead, handleAfterSearch);

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, perPage, searchTerm,doSearch]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery,perPage);
    },
    [dispatch,perPage, searchTerm,doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [currentPage, perPage, dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, [])

  function handleAfterSearch(
    isSuceess: boolean,
    data: ThandleAfterSearchData,
    value: number
  ) {
    dispatch(setLead(data?.data?.data?.data));
    dispatch(setTotalPage(Math.ceil(data?.data?.data?.totalCount / value)));
  }

  

  const configuration: TTableProps = {
    columns: columns,
    rows: Array.isArray(data) ? data : (data as { data: any[] }).data ?? [],
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    searchLoading: searchLoading,
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Leads",
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Leads</h2>
        {/* <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add Lead
        </Button> */}
      </div>
      <LeadList {...configuration} />
    </>
  );
};

export default LeadPage;
