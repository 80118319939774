import clsx from "clsx";
import React, { useState, ChangeEvent  } from "react";
import { TRoleProps } from "../../../types/Module/Users & Permissons/RoleForm";
import {
  TActivity,
  TModule,
} from "../../../types/Module/Users & Permissons/RoleFormModal";

const RoleForm = ({
  values,
  isSubmitting,
  handleChange,
  getFieldProps,
  touched,
  errors,
}: TRoleProps) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = (e:any) => {
    const { checked } = e.target;
    setSelectAll(checked);
    values.permissions.forEach((permission, permissionIndex) => {
      permission.moduleActivities.forEach((activity, activityIndex) => {
        const fieldName = `permissions[${permissionIndex}].moduleActivities[${activityIndex}].isSelected`;
        handleChange({ target: { name: fieldName, value: checked } }as ChangeEvent<HTMLInputElement>);
      });
    });
  };
  return (
    <div>
      <div
        className="mb-5"
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          padding: "20px",
        }}
      >
        <input
          type="text"
          placeholder="Enter Role Name"
          disabled={isSubmitting}
          {...getFieldProps("roleName")}
          className={clsx("form-control bg-transparent", {
            "is-invalid": touched?.roleName && errors?.roleName,
          })}
        />
      </div>
      <div style={{display:"inline-block"}}>
        <label className="form-check form-check-custom form-check-solid form-label fs-7 mb-3 cursor-pointer">
          <input
            className="form-check-input me-3"
            type="checkbox"
            disabled={isSubmitting}
            checked={selectAll}
            onChange={handleSelectAll}
          />
          Select All
        </label>
      </div>

      <div className="d-flex flex-column flex-md-row flex-md-wrap w-100 w-md-100 pt-5 pe-1 ">
        {values?.permissions?.map(
          (permission: TModule, permissionIndex: number) => {
            return (
              <div
                className="d-flex flex-column flex-md-column w-md-25 w-100 mb-4"
                key={permission.moduleName}
                //style={{ width: "150px" }}
              >
                <div>
                  <div>
                    <h3 className="form-label fs-6 fw-bolder text-dark mb-5">
                      {permission.moduleName}
                    </h3>
                  </div>
                  <div className="d-flex card-body flex-column w-100">
                    {permission.moduleActivities && (
                      <div className="ms-4">
                        {permission.moduleActivities.map(
                          (activity: TActivity, activityIndex: number) => {
                            const addRole = `permissions[${permissionIndex}].moduleActivities[${activityIndex}].isSelected`; // Use the original index
                            return (
                              <label className="form-check form-check-custom form-check-solid form-label fs-7 mb-3 cursor-pointer">
                                <input
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  disabled={isSubmitting}
                                  name={addRole}
                                  checked={activity.isSelected}
                                  onChange={handleChange}
                                />
                                {activity.activityName}
                              </label>
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default RoleForm;
