import { useDropzone } from "react-dropzone";
import { TDropBoxProps } from "../../types/DropBox";
import * as fs from "fs";
import "../../generatedForms/Quiz.css";
function DropBox({label, onChange, multiple, maxFiles }: TDropBoxProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onChange as any,
    multiple: multiple || true,
    maxFiles: maxFiles,
  });
  return (
    <div className="kt-dropzone" {...getRootProps()}>
      <input {...getInputProps()} />

      <div className="dropbox-content" style={{"cursor":"pointer"}}>
        {/* Wrap the text with the p element and apply the styles */}
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Click to select files</p>
        )}
      </div>
    </div>
  );
}

export async function fileImage(fileList: any[]) {
  // create function which return resolved promise
  // with data:base64 string
  function getBase64(file: any) {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = (ev) => {
        resolve(ev?.target?.result);
      };
      reader.readAsDataURL(file);
    });
  }
  // here will be array of promisified functions
  const promises = [];

  // loop through fileList with for loop
  for (let i = 0; i < fileList.length; i++) {
    promises.push(fileList[i]);
  }

  // array with base64 strings
  return await Promise.all(promises);
}

export async function fileListToBase64(fileList: (File | string)[]) {
  // create function which return resolved promise
  // with data:base64 string
  function getBase64(file: File | string) {
    const reader = new FileReader();
    return new Promise<string | undefined>((resolve) => {
      reader.onload = (ev) => {
        resolve(ev?.target?.result as string | undefined);
      };
      if (typeof file === "string") {
        resolve(file);
      } else {
        reader.readAsDataURL(file);
      }
    });
  }

  // here will be array of promisified functions
  const promises: Promise<string | undefined>[] = [];

  // loop through fileList with for loop
  for (let i = 0; i < fileList.length; i++) {
    promises.push(getBase64(fileList[i]));
  }

  // array with base64 strings
  return await Promise.all(promises);
}

export default DropBox;
