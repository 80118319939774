import axios from "axios";
import { TPayload } from "../types/Module/Category/CategoryFormModal";

const BASE_URL = process.env.REACT_APP_API_URL;
const CATEGORY_GET_URL = BASE_URL + "/categories";
const CATEGORY_DELETE_URL = BASE_URL + "/categories/";
const CATEGORY_ADD_URL = BASE_URL + "/categories/";
const CATEGORY_EDIT_URL = BASE_URL + "/categories/";

export const getCategory = (searchParams?: string) => {
  return axios.get(CATEGORY_GET_URL + "?" + searchParams);
};
export const getSingleCategory = (categoryId: string) => {
  return axios.get(CATEGORY_GET_URL + "/" + categoryId);
};

export const deleteCategory = (categoryId: string) => {
  return axios.delete(CATEGORY_DELETE_URL + categoryId);
};

export const addCategory = (payload: TPayload) => {
  return axios.post(CATEGORY_ADD_URL,payload);
};

export const editCategory = (payload: TPayload, categoryId: string) => {
  return axios.patch(CATEGORY_EDIT_URL + categoryId, payload);
};
