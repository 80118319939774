
    import { createSlice, PayloadAction } from '@reduxjs/toolkit'
    
    type TJatinState =  {
        data: [],
        perPage: number,
        currentPage: number,
        totalPage: number,
        searchTerm: string,
        loading: boolean,
        sortBy: string,
        sortColumn:string,
    }
    
    
    const initialJatinState: TJatinState = {
        data: [],
        perPage: 10,
        currentPage: 1,
        totalPage: 1,
        searchTerm: "",
        loading: false,
        sortBy: "desc",
        sortColumn:""
    
        
    }
    
    export const jatinSlice = createSlice({
        name: 'jatin',
        initialState:initialJatinState,
        reducers: {
            setJatin: (state:TJatinState, { payload }: PayloadAction<[]>) => {
              state.data=payload
             },
            setPerPage: (state:TJatinState, {payload}: PayloadAction<number>) => {
                state.perPage = payload;
            },
            setCurrentPage: (state:TJatinState, {payload}: PayloadAction<number>) => { 
                state.currentPage = payload;
            },
            setTotalPage: (state:TJatinState, {payload}: PayloadAction<number>) => { },
            setSearchTerm: (state:TJatinState, {payload}: PayloadAction<string>) => { 
                state.searchTerm = payload;
            },
            setSortBy: (state:TJatinState, {payload}: PayloadAction<string>) => { 
              state.sortBy = payload;
          },
          setSortColumn: (state:TJatinState, {payload}: PayloadAction<string>) => { 
            state.sortColumn = payload;
            state.sortBy = "desc";
        },
            setLoading: (state:TJatinState, {payload}: PayloadAction<boolean>) => { 
              state.loading = payload
            },
        },
    })
    
    
    export const { setJatin, setPerPage, setCurrentPage, setSortColumn,setSortBy, setTotalPage, setSearchTerm, setLoading } = jatinSlice.actions
    export default jatinSlice.reducer
    