import AttributeForm from "../generatedForms/Attribute";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import {
  addAttribute,
  editAttribute,
  getSingleAttribute,
} from "../generatedRequest/attribute";
import { useSearchParams } from "react-router-dom";
import { getUnit } from "../app/request/unitRequest";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import { attributeSchema } from "../utils/validationSchema/attributeSchema";
import { useNavigate } from "react-router-dom";
import {
  TAttributeConfig,
  TAttributeFormData,
  TSupportedUnit,
} from "../types/Module/Attribute/Attribute";
import "./AttributeFormModal.css";
import "../styles/multiselectStyles.css";

const AttributeFormModal = ({ isEdit }: TAttributeConfig) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [allunits, setAllUnits] = useState([]);
  const [initload, setInitload] = useState(true);
  const attributeID = queryParameters.get("q") || "";
  
  let title = isEdit ? "Edit" : "Add";
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    const fetchRegionData = async () => {
      setLoading(true);
      try {
        const response = await getSingleAttribute(attributeID); // Call your API to get user data
        if (response.data.status) {
          formik.setValues({
            ...response.data.data,
            notes: response.data.data.notes,
          });
          formik.setFieldValue("status", {
            value: response.data.data.status,
            label: capitalizeFirstLetter(response.data.data.status),
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchRegionData();
    } else {
      setInitload(false);
    }
  }, [isEdit,attributeID]);

  const handleSubmit = async (payload: TAttributeFormData) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editAttribute(payload, attributeID)
        : await addAttribute(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Attribute is edited Successfully")
          : toast.success("Attribute is added Successfully");
      } else {
        if (typeof res.data.message == "string") {
          toast.error(res.data.message);
        } else if (typeof res.data.message == "object") {
          toast.error(res.data.message[0]);
        }
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<TAttributeFormData>({
    initialValues: {
      attributeName: "",
      supportedUnits: [],
      notes: [],
      status: { value: "active", label: "Active" },
      icecatName:""
    },
    validationSchema: attributeSchema,
    onSubmit: (values) => {
      let payload = {
        attributeName: values.attributeName,
        supportedUnits: values.supportedUnits.map(
          (unit: string | TSupportedUnit) =>
            typeof unit === "string" ? unit : unit.id || unit._id
        ),
        notes: values.notes || [],
        status:
          typeof values.status === "string"
            ? values.status
            : values.status.value,
        icecatName:values.icecatName     
      };
      handleSubmit(payload);
    },
  });

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getUnit();
        if (resp.data.status) {
          setAllUnits(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);
  const navigate = useNavigate();

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} size={"250px"} />;
  }
  
  return (
    <>
      <div className="card p-5 max-width-800">
        <div className="card-header p-0 border-1 cursor-pointer">
          <div className="card-title m-0">
            <div
              role={"button"}
              className="btn btn-sm btn-light me-5"
              onClick={() => navigate("/attribute")}
            >
              <i className="fa-solid fa-arrow-left fs-2"></i>
            </div>
            <h3 className="fw-bolder m-0">{title} Attribute</h3>
          </div>
        </div>
        <AttributeForm allunits={allunits} {...formik} isloading={loading} />
        <div className="d-flex justify-content-end">
          <button
            type="button"
            id="kt_password_reset_submit"
            disabled={loading || !formik.isValid}
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
          >
            {!loading && <span className="indicator-label">Save</span>}
            {loading && (
              <span className="indicator-progress display-block">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default AttributeFormModal;
