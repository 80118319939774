import React from 'react'
import { TCardProps } from '../../types/Card'


const Card = ({children, className, ...props}: TCardProps) => {
  return (
    <div className={"card p-5 " + className} {...props}>
        {children}
    </div>
  )
}

export default Card