import RegionsTableAction from "../generatedTableActions/RegionsTableAction";
import Badge from "../components/Badge";
import { TColumn } from "../types/Module/Region/RegionsPage";
import { NotesDisplay } from "../components/Reusable/NotesDisplay";

export const columns: TColumn[] = [
  {
    name: "name",
    renderHeader: () => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row) => (
      <span>
        {`${row && row.regionName}`.toUpperCase()}
        {row.isDefault ? <strong> (Default)</strong> : ""}
      </span>
    ),
  },
  {
    name: "regionUrl",
    renderHeader: () => (
      <>
        <b>Url</b>
      </>
    ),
    renderCell: (row) => (
      <a
        href={row.regionUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline" }}
      >
        {row.regionUrl}
      </a>
    ),
  },
  //  {
  //     name: "notes",
  //     renderHeader: () => (
  //       <>
  //         <b>Notes</b>
  //       </>
  //     ),
  //     renderCell: (row) => {
  //      return (
  //         <span>
  //          <NotesDisplay notes={row.notes}/>
  //         </span>
  //       );
  //     },
  //   },
  {
    name: "languages",
    renderHeader: () => (
      <>
        <b>Languages</b>
      </>
    ),
    renderCell: (row) => (
      <>
        {Array.prototype.map
          .call(row && row?.language, function (item) {
            return item?.languageName;
          })
          .join(",")}
      </>
    ),
  },

  {
    name: "status",
    renderHeader: () => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (row) => (
      <Badge
        variant={row.status === "active" ? "success" : "danger"}
        className="fs-7 fw-bolder"
      >
        {row.status}
      </Badge>
    ),
  },

  {
    name: "updated_at",
    renderHeader: () => (
      <>
        <b>Updated At</b>
      </>
    ),
    renderCell: (row) => <span>{row.updatedAt}</span>,
  },

  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row) => <RegionsTableAction region={row} />,
  },
];
