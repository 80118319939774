import { Modal } from "react-bootstrap";
import { KTSVG } from "../_metronic/helpers";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import MappingForm from "../generatedForms/Mapping";
import { useDispatch } from "react-redux";
import {
  TAddLanguageModalProps,
  TFormikValues,
} from "../types/Module/Language/LanguageFormModal";
import Button from "../components/Button";
import { getSubCategory } from "../generatedRequest/subcategory";
import { editMapping } from "../generatedRequest/mapping";
import { addMapping } from "../generatedRequest/mapping";
import { start } from "repl";

const MappingFormFormModal = ({
  onClose,
  currentRecord,
  isEdit,
}: {
  onClose?: () => void;
  currentRecord: {
    productSubcategory: any;
    accessorySubcategory: any;
    connections: any[];
    id?: string;
  };
  isEdit: boolean;
}) => {
  const [subCategories, setSubCategories] = useState<TFormikValues>([]);
  const [loading, setLoading] = useState(false);

  const [loadingAiAssistance, setLoadingAiAssistance] = useState(false);
  const [initialValues, setInitialValues] = useState({
    productSubcategory: {} as any,
    accessorySubcategory: {} as any,
    connections: [] as any[],
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      try {
        const res = await getSubCategory();
        if (res.data.status) {
          setSubCategories(res.data.data.data);
        } else {
          toast.error("Something went wrong");
          typeof onClose === "function" && onClose();
        }
      } catch (error) {
        toast.error("Network Error");
        typeof onClose === "function" && onClose();
      } finally {
        setLoading(false);
      }
    };
    fn();
  }, []);

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editMapping(payload,currentRecord.id)
        : await addMapping(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Accessory is edited Successfully")
          : toast.success("Accessory is added Successfully");
        typeof onClose === "function" && onClose();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting }) => {
      const payload = {
        accessorySubcategory: values.accessorySubcategory,
        productSubcategory: values.productSubcategory,
        connections: values.connections.map((connection) => {
          const newStart = connection.start?.replace(/edit$/g, ""); // Remove trailing "edit"
          const newEnd = connection.end?.replace(/edit$/g, ""); // Remove trailing "edit"
          return { ...connection, start: newStart, end: newEnd };
        }),
      };
      handleSubmit(payload);
    },
  });

  useEffect(() => {
    const ModifyArrows = () => {
      // 1. Clone arrows to avoid mutation:
      const arrowsForEditModal = [...(currentRecord?.connections || [])]; // Use spread operator to create a copy

      // 2. Modify arrow endpoints (conditional logic for clarity):
      arrowsForEditModal.forEach((item) => {
        item.start = item.start + "edit";
        item.end = item.end + "edit";
      });

      // 3. Update form values atomically:
      formik.setValues({ ...currentRecord, connections: arrowsForEditModal });
    };

    isEdit && ModifyArrows();
  }, [isEdit, currentRecord]);

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      size="xl"
      show={true}
      dialogClassName="modal-md modal-dialog-centered w-100"
      aria-hidden="true"
      onHide={onClose}
    >
      <div className="modal-header">
        <Button disabled={loading}>Get AI Assistance</Button>

        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onClose}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div
        className="modal-body scroll-y"
        style={{ minHeight: "60vh", maxHeight: "60vh" }}
      >
        <MappingForm
          {...formik}
          subCategories={subCategories}
          loading={loading}
          isEdit={isEdit}
          idofMapping={currentRecord.id}
        />
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <button type="button" className="btn btn-danger me-4" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={formik.isSubmitting || !formik.isValid || loading}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default MappingFormFormModal;
