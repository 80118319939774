import { TTableProps } from "../types/TableView";
import TableView from "../components/TableView";
import EmptyState from "../components/EmptyState";

const SubCategoryList = ({
  columns,
  rows,
  showPagination,
  showSearch,
  showPerPage,
  paginationData,
  onPageChange,
  onPerPageChange,
  handleSearchParams,
  searchPlaceholder,
  showFilter,
  searchLoading
}: TTableProps) => {
  return (
    <div className="card">
      <TableView
        columns={columns}
        rows={rows}
        showPagination={showPagination}
        showSearch={showSearch}
        showPerPage={showPerPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
        handleSearchParams={handleSearchParams}
        searchPlaceholder={searchPlaceholder}
        searchComponent={
          <EmptyState
            title="Sorry"
            message="No SubCategory Found"
            buttonTitle="Reset"
            image="/media/misc/empty-State.svg"
          />
        }
        showFilter={showFilter}
        searchLoading={searchLoading}
      />
    </div>
  );
};

export default SubCategoryList;
