/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from "react";
import TableView from "../../../components/TableView";
import TableSkeleton from "../../../components/TableSkeleton";
import {
  setCurrentPage,
  setPerPage,
  setTotalPage,
  setRerender,
  setSearchTerm,
  setSearchTermForGoogleLang,
} from "../../../redux/slices/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import { RootState } from "../../../redux/store";
import useSearchApi from "../../../customHooks/useSearchApi";
import LanguageFormModal from "./LanguageFormModal";
import { getLanguage } from "../../request/languageRequest";
import TableLoader from "../../../utils/Loaders/tableLoader";
import Badge from "../../../components/Badge";
import { toast } from "react-toastify";
import EmptyState from "../../../components/EmptyState";
import {
  TColumn,
  ThandleAfterSearchData,
} from "../../../types/Module/Language/LanguageList";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

const columns: TColumn[] = [
  {
    name: "Language Name",
    renderHeader: () => (
      <>
        <b>Language Name</b>
      </>
    ),
    renderCell: (language) => <span>{language.languageName}</span>,
  },
  {
    name: "Language Code",
    renderHeader: () => (
      <>
        <b>Language Code</b>
      </>
    ),
    renderCell: (language) => <span>{language.languageCode}</span>,
  },
  {
    name: "Native Name",
    renderHeader: () => (
      <>
        <b>Native Name</b>
      </>
    ),
    renderCell: (language) => <span>{language.languageNativeName}</span>,
  },
  {
    name: "status",
    renderHeader: () => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (language) => (
      <Badge
        variant={language.status === "active" ? "success" : "danger"}
        className="fs-7 fw-bolder"
      >
        {language.status}
      </Badge>
    ),
  },
  {
    name: "Created At",
    renderHeader: () => (
      <>
        <b>Created At</b>
      </>
    ),
    renderCell: (language) => <span>{language.createdAt}</span>,
  },
  {
    name: "Updated_at",
    renderHeader: () => (
      <>
        <b>Updated At</b>
      </>
    ),
    renderCell: (language) => <span>{language.updatedAt}</span>,
  },
];

const LanguagesList = () => {
  const [language, setLanguage] = useState([]);
  const { perPage, currentPage, totalPage, rerender1, searchTerm } =
    useSelector((state: RootState) => state.language);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [initload, setInitload] = useState(true);
  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      try {
        const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
        const res = await getLanguage(searchQuery);
        if (res.data.status) {
          setLanguage(res.data.data.data);
          dispatch(setTotalPage(Math.ceil(res.data.data.totalCount / perPage)));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };
    fn();
  }, [rerender1, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, [])

  function handleAfterSearch(
    isSuceess: boolean,
    data: ThandleAfterSearchData,
    value: number
  ) {
    setLanguage(data.data.data.data);
    dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
  }

  const { doSearch, searchLoading } = useSearchApi(
    getLanguage,
    handleAfterSearch
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch, dispatch]
  );
  if (loading && initload) {
    return <TableSkeleton rows={5} columns={6} />;
  }

  return (
    <>
      <TableView
        rows={language}
        columns={columns}
        showPagination={true}
        showSearch={true}
        showPerPage={true}
        showFilter={false}
        paginationData={{ perPage, currentPage, totalPage }}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        searchPlaceholder={"Search Languages"}
        handleSearchParams={handleSearchParams}
        searchComponent={
          <EmptyState
            title="Sorry"
            message="No Languages Found"
            buttonTitle="Reset"
            image="/media/misc/empty-State.svg"
          />
        }
        searchLoading={searchLoading}
      />
      {/* {searchLoading && (
        <TableLoader isLoading={searchLoading} loadingMessage={"loading"} />
      )} */}
    </>
  );
};

const LanguagesPage: FC = () => {
  const { rerender1 } = useSelector((state: RootState) => state.language);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleAddLanguage = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(setRerender(!rerender1));
    dispatch(setSearchTermForGoogleLang(""));
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">

        <div className="d-flex align-items-center" style={{ gap: "5px" }}>
          <h2>Languages </h2>
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/icons/duotune/art/exclamation-icon.svg")}
            className="h-20px cursor-pointer"
            onClick={()=>navigate(`/helps?moduleName=Languages`)}

          />
      
        </div>
        <Button
          variant="primary"
          handleButtonClick={handleAddLanguage}
          size="medium"
        >
          Manage Languages
        </Button>
      </div>

      <LanguagesList />

      {openModal && (
        <LanguageFormModal
          currentRecord={{
            languageName: "",
            languageCode: "",
            languageNativeName: "",
          }}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default LanguagesPage;
