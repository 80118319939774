
import React from 'react'
import RetailerFormModal from '../../generatedFormModal/RetailerFormModal'



const EditRetailer = () => {
  return (
    <div>
      <RetailerFormModal isEdit={true}/>
    </div>
  )
}

export default EditRetailer

