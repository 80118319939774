import RetailerForm from "../generatedForms/Retailer";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import {
  RetailerFileUpload,
  addRetailer,
  addRetailerMappedAccessories,
  editRetailer,
  getRetailerMappedAccessories,
  getRetailerSearchUrl,
  getSingleRetailer,
} from "../generatedRequest/retailer";
import { RetailerSchema } from "../generatedValidations/RetailerSchema";
import { getRegion } from "../app/request/regionRequest";
import { useSearchParams } from "react-router-dom";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import { FileUpload } from "../generatedRequest/accessory";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/retatilerDataSlice";

type TRetailerModalProps = {
  isEdit: boolean;
};

const RetailerFormModal = ({ isEdit }: TRetailerModalProps) => {
  const [activeRegions, setActiveRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initload, setInitload] = useState(true);
  const [searchUrlLoading, setSeacrhUrlLoading] = useState(false);
  const [excelHeaders, setExcelHeaders] = useState([]);
  const [queryParameters] = useSearchParams();
  let retailerData: any = {};
  const retailerid = queryParameters.get("q") || "";
  const { rerender1 } = useSelector((state: RootState) => state.retatilerData);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    retailerName: "",
    retailerRegion: [] as any[],
    retailerFrontendUrl: "",
    isElasticSearch: true,
    isFeed: false,
    price: false,
    searchConfiguration: {
      searchUrl: "",
      searchBy: {} as any,
    },
    feedConfiguration: {
      file: [] as any,
      // mapBy: {} as any,
      // redirectFieldName: "" as any,
    },
  });

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getRegion(`page=1&limit=100`);

        if (resp.data.status) {
          setActiveRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  const fetchRetailerData = async () => {
    setLoading(true);
    try {
      const response = await getSingleRetailer(retailerid); // Call your API to get user data
      if (response.data.status) {
        formik.setValues({
          ...response.data.data,
          retailerRegion: [response?.data?.data?.retailerRegion],
          price: response?.data?.data?.showRetailerPricing,
          feedConfiguration: {
            ...response?.data?.data?.feedConfiguration,
          },
          searchConfiguration: {
            ...response?.data?.data?.searchConfiguration,
            searchBy: {
              value: response?.data?.data?.searchConfiguration?.searchBy,
            },
          },
        });
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
      setInitload(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchRetailerData();
    } else {
      setInitload(false);
    }
  }, [isEdit, retailerid]);

  const handleFileUpload = async (payload: any) => {
    try {
      formik.setFieldValue(`feedConfiguration.file`, payload);
      const res = await RetailerFileUpload(payload);
      if (res.data.status) {
        setExcelHeaders(res.data.data);
      } else {
        toast.error(res.data.message);
        //   setRegions([]);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
    }
  };

  const getSearchUrlWithScrap = async (payload: any) => {
    setSeacrhUrlLoading(true);
    try {
      let temp = {
        searchUrl: formik.values.retailerFrontendUrl,
      };
      const res = await getRetailerSearchUrl(temp);
      if (res.data.status) {
        formik.setFieldValue("searchConfiguration.searchUrl", res?.data?.data);
      } else {
        //   setRegions([]);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setSeacrhUrlLoading(false);
    }
  };

  const retailerAccessoryMapping = async (payload: any) => {
    try {
      const res = await addRetailerMappedAccessories(payload);
      if (res.data.status) {
        isEdit && dispatch(setRerender(!rerender1));
        toast.success("Retailer Accessory Mapped Successfully");
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RetailerSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      const fn = async () => {
        let payload = {
          retailerName: values?.retailerName,
          retailerRegion:
            values?.retailerRegion?.[`0`]?._id ||
            values?.retailerRegion?.[`0`]?.id,
          retailerFrontendUrl: values?.retailerFrontendUrl,
          isElasticSearch: values?.isElasticSearch,
          isFeed: values?.isFeed,
          price: values?.price,
          searchConfiguration: {
            searchUrl: values?.searchConfiguration?.searchUrl,
            searchBy: values?.searchConfiguration?.searchBy?.value,
          },
          feedConfiguration: {
            file: values?.feedConfiguration?.file,
            // mapBy: values?.feedConfiguration?.mapBy?.value,
            // redirectFieldName: values?.feedConfiguration?.redirectFieldName,
          },
        };

        try {
          const res = isEdit
            ? await editRetailer(payload, retailerid)
            : await addRetailer(payload);
          if (res.data.status) {
            isEdit
              ? toast.success(
                  "Retailer is edited Successfully,Accessory Mapping started"
                )
              : toast.success(
                  "Retailer is added Successfully,Accessory Mapping Started"
                );
            retailerData.id = res?.data?.data?._id;
            retailerData.isMappingRequired = res?.data?.data?.isFeed;
            console.log(res.data);
          } else {
            toast.error(res?.data?.message);
          }
        } catch (error) {
          //TODO: make error when go live
          toast.success("Settings saved successfully");
        } finally {
          setLoading(false);
          retailerData.isMappingRequired &&
            retailerAccessoryMapping(retailerData.id);
        }
      };
      fn();
    },
  });

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }


  return (
    <>
      <RetailerForm
        isEdit={isEdit}
        {...formik}
        regions={activeRegions}
        handleFileUpload={handleFileUpload}
        excelHeaders={excelHeaders}
        getSearchUrlWithScrap={getSearchUrlWithScrap}
        searchUrlLoading={searchUrlLoading}
        retailerid={retailerid}
      />
      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default RetailerFormModal;
