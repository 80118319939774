import { useCallback } from 'react'
import { ActionCells } from '.'
import { changeStatus, deleteUser, DELETE_USER_URL, USER_API_URL } from '../../app/request/userRequest'
import ConfirmationPrompt from '../ConfirmationPrompt'
import axios from 'axios'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDelete } from '../../customHooks/useDelete/useDelete'
import { useDispatch,useSelector } from 'react-redux'
import { useStatus } from '../../customHooks/useStatus/useStatus'
import { RootState } from '../../redux/store';
import {setRerender } from '../../redux/slices/userSlice'
import { TRowData, TUserTableActionProps } from '../../types/Module/Users & Permissons/UserManagement'


const UserTableActions = ({ person }: TUserTableActionProps) => {
    const {rerender1}  = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const handleEdit = (userId: number) => {
        navigate(`/edituser?id=${userId}`);
    }
    const handleAfterStatus = useCallback((status: boolean, res: TRowData) => {
        if (status) {
            toast.success("status updated successfully");
            dispatch(setRerender(!rerender1))
        } else {
            //TODO: make error when go live
            toast.error("something went wrong");
        }
    }, [rerender1,dispatch])

    const handleDelete = useCallback((status: boolean, res: TRowData) => {
        if (status) {
            toast.success("Record deleted successfully");
            dispatch(setRerender(!rerender1))
        } else {
            //TODO: make error when go live
           
        }
    }, [rerender1,dispatch])

    const { onDelete, showPrompt, loading, deleteRecord, onCancel } = useDelete(deleteUser, true, handleDelete)

    const { onStatus, showStatusPrompt, statusloading, statusToUpdate, onstatusCancel,ChangeStatus } = useStatus(changeStatus, true, handleAfterStatus)

    return (
        <>
            <ActionCells
                key={person.id}
                actions={["Edit", "Delete","Change Status"]}
                onSelect={(action: string) => {
                    switch (action) {
                        case "Edit": handleEdit(person.id); break;
                        case "Delete": onDelete(person.id); break;
                        case "Change Status": onStatus(person); break;
                    }

                }}

            />
            {showPrompt && <ConfirmationPrompt title='Delete Record' message='Are you sure you want to delete this record?' onConfirm={deleteRecord} onCancel={onCancel} isloading={loading} />}
            {showStatusPrompt && <ConfirmationPrompt title='Change Status' message='Are you sure you want to change the status ?' onCancel={onstatusCancel} onConfirm={ChangeStatus} isloading={statusloading}/> }
        </>


    )
}

export default UserTableActions