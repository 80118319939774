import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../_metronic/helpers';

type TModalProps = {
    title: string;
    message: string;
    onCancel?: () => void;
    onConfirm?: () => void;
    isloading?:boolean

}

const ConfirmationPrompt = ({ title, message, onCancel, onConfirm,isloading }: TModalProps) => {
    return (
        <Modal
            className='modal fade'
            id='kt_modal_select_location'
            data-backdrop='static'
            tabIndex={-1}
            role='dialog'
            show={true}
            dialogClassName='modal-md modal-dialog-centered'
            aria-hidden='true'
            onHide={onCancel}
        >

            <div className='modal-header'>
                <h2 className='modal-title fw-bolder'>{title}</h2>
                <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    onClick={onCancel}
                >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
                </div>
            </div>
            <div className="modal-body scroll-y">
                <h6>{message}</h6>
            </div>
            <div className='modal-footer'>
                <button type='button' className='btn btn-danger' onClick={onCancel}>
                    Cancel
                    
                </button>
                <button id='submit' type='button' className='btn btn-primary' onClick={onConfirm} disabled={isloading}>
                {!isloading && <span className="indicator-label">Confirm</span>}
          {isloading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
                </button>
            </div>

        </Modal>
    )
}

export default ConfirmationPrompt