import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const HELP_GET_URL = BASE_URL + "/help";
const HELP_DELETE_URL = BASE_URL + "/help";
const HELP_ADD_URL = BASE_URL + "/help";
const HELP_EDIT_URL = BASE_URL + "/help";
const HELP_MEDIA_UPLOAD_URL = BASE_URL + "/help/uploadDoc";
const HELP_MEDIA_DELETE_URL = BASE_URL + "/help/deleteDoc";

export const getAllHelp = (searchParams?: string) => {
  let url = HELP_GET_URL;

  if (searchParams && searchParams.trim()) {
    url += `/${searchParams}`;
  }

  return axios.get(url);
};

export const deleteHelp = (helpId: string) => {
  return axios.delete(HELP_DELETE_URL + `/${helpId}`);
};

export const addHelp = (payload: any) => {
  return axios.post(HELP_ADD_URL, payload);
};

export const editHelp = (payload: any, helpId: string) => {
  return axios.patch(HELP_EDIT_URL + `/${helpId}`, payload);
};

export const getSingleHelp = (helpId: string) => {
  return axios.get(HELP_GET_URL + `/${helpId}`);
};

export const helpImageUpload = (payload: any) => {
  let data = new FormData();
  payload &&
    payload.forEach((item: any, i: number) => {
      data.append(`files`, item);
    });
  return axios.patch(HELP_MEDIA_UPLOAD_URL, data);
};


export const AddModeDeleteImage = (imageId: any) =>{
  return axios.delete(`${HELP_MEDIA_DELETE_URL}?imageId=${imageId}`);
}
export const EditModeDeleteImage = (helpId: string, imageId:string) =>{
  return axios.delete( `${HELP_MEDIA_DELETE_URL}/?imageId=${imageId}&id=${helpId}`);
}



