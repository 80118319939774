
    import { TTableProps } from "../types/TableView";
    import TableView from "../components/TableView";

  const ScrappingList = ({ columns, rows, showPagination, showSearch, showPerPage, paginationData, onPageChange, onPerPageChange, handleSearchParams, searchPlaceholder }:TTableProps) => { 
   return (
    <div className='card'>
    <TableView
    columns={columns}
    rows={rows}
    showPagination={showPagination}
    showSearch={showSearch}
    showPerPage={showPerPage}
    paginationData={paginationData}
    onPageChange={onPageChange}
    onPerPageChange={onPerPageChange}
    handleSearchParams={handleSearchParams}
    searchPlaceholder={searchPlaceholder}
  />
    </div>
  );
};

export default ScrappingList;
    