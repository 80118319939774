import * as Yup from 'yup'



export const regionSchema = Yup.object().shape({
    regionName: Yup.string()
      .min(1, 'Minimum 2 characters.')
      .max(50, 'Maximum 50 chracters.')
      .required('Region is required.'),
    regionUrl:Yup.string()
      .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          'Enter correct url!'
      )
      .required('Website url is required.'),
      language: Yup.array()
      .min(1, "At least one language is required."),
      shopify_shop:Yup.string()
      .required('Shop Name is required.'),
      shopify_api_access_token: Yup.string()
      .required('API token is required')
  })