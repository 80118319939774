import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { FormikProps, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

type TAccessoryTypeProps = {
  accessoryType: any;
  // formik: FormikProps<any>;
  submitStep: any;
  stepNumber: any;
};

const AccessoryType = ({
  accessoryType,
  submitStep,
  stepNumber,
}: TAccessoryTypeProps) => {
  const formik = useFormikContext(); // Access the Formik context
  console.log("formik AccessoryType",formik.values);
  const handleRadioButtonChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: any,
    question: any
  ) => {
    formik.resetForm();
    await formik.setFieldValue("accessoryType", e.target.value);


    // const nextStepNumber = stepNumber + 2;
    // const url = `/stepper?stepNumber=${nextStepNumber}`;

    // // Open the URL in a new tab
    // window.open(url, "_blank");

    // Call submitStep to move to the next step
    submitStep(stepNumber, question);
  };

  return (
    <div className="w-100 mt-15">
      <div className="pb-10 pb-lg-15">
        <h2
          className="fw-bolder d-flex align-items-center justify-content-center text-dark"
          style={{ fontSize: "27px" }}
        >
          What type of accessory are you looking for?
        </h2>
      </div>

      <div className="fv-row">
        <div className="row">
          {accessoryType.map((accessory: any, index: number) => {
            return (
              <div className="col-lg-3">
                <input
                  type="radio"
                  className="btn-check"
                  name="accessoryType"
                  value={accessory?.subcategory?.subcategoryName}
                  id={`kt_${accessory?.subcategory?.subcategoryName.replace(
                    " ",
                    "_"
                  )}_${accessory?.questions?.attributeName}`}
                  onChange={(event: any) =>
                    handleRadioButtonChange(
                      event,
                      accessory?.questions?.attributeName,
                      accessory?.questions
                    )
                  }
                />
                <label
                  className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10"
                  htmlFor={`kt_${accessory?.subcategory?.subcategoryName.replace(
                    " ",
                    "_"
                  )}_${accessory?.questions?.attributeName}`}
                >
                  <span className="d-flex justify-content-center flex-column w-100">
                    <span
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "0 auto",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_API_URL_FOR_IMAGE +
                          accessory?.subcategory?.subcategoryImageURL
                        }
                        alt={accessory?.subcategory?.subcategoryName}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </span>
                    <span className="text-dark fw-bolder d-block fs-4 mt-5">
                      {accessory?.subcategory?.subcategoryName}
                    </span>
                  </span>
                </label>
              </div>
            );
          })}

          {/* <div className="col-lg-3">
            <input
              type="radio"
              className="btn-check"
              name="accessoryType"
              value="charger"
              id="kt_charger"
              //onChange={(event:any)=>handleRadioButtonChange(event, 2)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
              htmlFor="kt_charger"
            >
              <span className="d-flex justify-content-center flex-column w-100">
                <span>
                  <img
                    src={toAbsoluteUrl("/media/misc/charger.png")}
                    alt="Charger"
                  />
                </span>
                <span className="text-dark fw-bolder d-block fs-4 mt-5">
                  Charger
                </span>
              </span>
            </label>
          </div>

          <div className="col-lg-3">
            <input
              type="radio"
              className="btn-check"
              name="accessoryType"
              value="privacy screen"
              id="kt_privacy_screen"
              //onChange={(event:any)=>handleRadioButtonChange(event, 3)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
              htmlFor="kt_privacy_screen"
            >
              <span className="d-flex justify-content-center flex-column w-100">
                <span>
                  <img
                    src={toAbsoluteUrl("/media/misc/privacy-screen.png")}
                    alt="Privacy Screen"
                  />
                </span>
                <span className="text-dark fw-bolder d-block fs-4 mt-5">
                  Privacy Screen 
                </span>
              </span>
            </label>
          </div>

          <div className="col-lg-3">
            <input
              type="radio"
              className="btn-check"
              name="accessoryType"
              value="cable lock"
              id="kt_cable_lock"
              //onChange={(event:any)=>handleRadioButtonChange(event, 4)}
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center"
              htmlFor="kt_cable_lock"
            >
              <span className="d-flex justify-content-center flex-column w-100">
                <span
                  style={{ height: "100px", width: "100px", margin: "0 auto" }}
                >
                  <img
                    src={toAbsoluteUrl("/media/misc/cable-lock.png")}
                    alt="Cable Lock"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
                <span className="text-dark fw-bolder d-block fs-4 mt-5">
                  Cable Lock  
                </span>
              </span>
            </label>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AccessoryType;
