import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type TMappingSkeletonType = {
  numberOfInput: number;
  size?: string;
};

const MappingSkeleton = ({ numberOfInput, size }: TMappingSkeletonType) => {
  return (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="card p-5" style={{ width: "48%" }}>
        <div className="" id="kt_login_signin_form">
          <div className="d-flex justify-content-between flex-wrap">
            {[...Array(numberOfInput)].map((t: number, index: number) => {
              const cellIndex = index * 2 + index;
              return (
                <div className="mb-4" key={cellIndex}>
                  <Skeleton width={330} height={30} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="card p-5" style={{ width: "48%" }}>
        <div className="" id="kt_login_signin_form">
          <div className="d-flex justify-content-between flex-wrap">
            {[...Array(numberOfInput)].map((t: number, index: number) => {
              const cellIndex = index * 2 + index;
              return (
                <div className="mb-4" key={cellIndex}>
                  <Skeleton width={330} height={30} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="card p-5" style={{ width: "48%", marginTop:"30px" }}>
        <div className="" id="kt_login_signin_form">
          <div className="d-flex justify-content-between flex-wrap">
            {[...Array(numberOfInput)].map((t: number, index: number) => {
              const cellIndex = index * 2 + index;
              return (
                <div className="mb-4" key={cellIndex}>
                  <Skeleton width={330} height={30} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="card p-5" style={{ width: "48%", marginTop:"30px" }}>
        <div className="" id="kt_login_signin_form">
          <div className="d-flex justify-content-between flex-wrap">
            {[...Array(numberOfInput)].map((t: number, index: number) => {
              const cellIndex = index * 2 + index;
              return (
                <div className="mb-4" key={cellIndex}>
                  <Skeleton width={330} height={30} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingSkeleton;
