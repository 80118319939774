import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { TImageGridProps } from "../../types/ImageGrid";
import { fileListToBase64 } from "../DropBox";

function ImageGrid({
  images,
  handleDelete,
  showDeleteButton,
  showCheckbox,
  isLoading,
  handleImageSelect,
  name,
  preSelected = -1,
}: TImageGridProps) {
  const [selected, setSelected] = useState(preSelected);
  const [preview, setPreview] = useState<any[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  // useEffect(()=>{
  //   const fn = async ()=> {
  //     // const generatePreview = await fileListToBase64 (images) as any []
  //     setPreview(images.map((images:any)=>images.subcategoryImageURL))
  //   }

  //   fn()
  // },[images])

  //console.log("images", images);

  return (
    <div className="image-container d-flex align-items-center justify-content-center mb-3">
      {images?.map((imgurl: any, index: number) => {
        return (
          <div
            key={+new Date() + index}
            className="image-input image-input-outline m-2"
            style={{ position: "relative" }}
          >
            <div
              className="image-input-wrapper cursor-pointer"
              style={{
                backgroundImage: "url(" + (imgurl && imgurl) + ")",
                transform: index === hoveredIndex ? "scale(1.1)" : "scale(1)",
                transition: "transform 0.3s ease-in-out"
              }}
              onClick={() => {
                if (selected === index) {
                  //deselect if selected
                  setSelected(-1);
                  if (typeof handleImageSelect === "function") {
                    handleImageSelect("");
                  }
                } else {
                  setSelected(index);
                  if (typeof handleImageSelect === "function") {
                    handleImageSelect(imgurl.id);
                  }
                }
              }}
            ></div>
            {showDeleteButton !== false && (
              <span
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-5px",
                  background: "white",
                  transform: index === hoveredIndex ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.3s ease-in-out"
                }}
                className="img-delete-btn cursor-pointer"
                data-toggle="tooltip"
                title="Delete Image"
                onClick={() => {
                  handleDelete(index);
                }}
                onMouseEnter={() => setHoveredIndex(index)} // Set hovered index
                onMouseLeave={() => setHoveredIndex(-1)} // Reset hovered index
              >
                <img
                  src={toAbsoluteUrl(
                    "/media/icons/duotune/general/close-icon.png"
                  )}
                />
              </span>
            )}
            {/* {showCheckbox === true && (
              <span
                className="checkbox-btn"
                data-toggle="tooltip"
                title="Select Image"
              >
                <input
                  type="checkbox"
                  onChange={() => {
                    if (selected === index) {
                      //deselect if selected
                      setSelected(-1);
                      if (typeof handleImageSelect === "function") {
                        handleImageSelect("");
                      }
                    } else {
                      setSelected(index);
                      if (typeof handleImageSelect === "function") {
                        handleImageSelect(imgurl.id);
                      }
                    }
                  }}
                  checked={index === selected}
                />
              </span>
            )} */}
          </div>
        );
      })}

      {isLoading && (
        <div className="image-input image-input-outline m-2 border border-solid border-grey border-dashed">
          <div className="image-input-wrapper cursor-pointer d-flex justify-content-center  align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageGrid;
