import React, { useEffect, useRef, useState } from "react";
import "./tooltip.css";

export type TagBoxItem = {
  name: string;
  id?: number | string;
};

export type TagBoxProps = {
  tags?: TagBoxItem[];
  handleNew?: Function;
  handleDelete?: Function;
  placeholder?: string;
  disabled?: boolean;
};

export const TagBox = ({
  tags,
  handleDelete,
  handleNew,
  placeholder,
  disabled,
}: TagBoxProps) => {
  const textRef = useRef<any>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [highlightId, setHighlightId] = useState(0);
 

  useEffect(() => {
    const inputRef = textRef.current;
    setShowTooltip(inputRef.scrollWidth > inputRef.clientWidth);
  }, []);

  const handleMouseEnter = (id: number) => {
    setShowTooltip(true);
    setHighlightId(id);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div
        className="d-flex flex-wrap rounded p-2"
        style={{ border: "1px solid #e1e3ea" }}
        onClick={() => {
          textRef.current.focus();
        }}
      >
        {tags &&
          tags
            ?.filter((data: TagBoxItem) => data.name !== "")
            .map((item: any, index: number) => {
              return (
                <React.Fragment key={index + "_" + item.name}>
                  <div className="d-flex flex-wrap ">
                    <div className="p-2 text-primary me-1 mb-1 badge bg-secondary position-relative">
                      <span>
                        <p
                          className="mb-0"
                          style={{
                            whiteSpace: "nowrap",
                            maxWidth: "100px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          {item?.name ? item?.name : item}{" "}
                        </p>
                        {showTooltip && highlightId === index && (
                          <div className="tooltip">
                            {item?.name ? item?.name : item}{" "}
                          </div>
                        )}
                      </span>

                      <button
                        onClick={(e: any) => {
                          typeof handleDelete === "function" &&
                            handleDelete(item, index);
                        }}
                        type="button"
                        title="Click to delete tag"
                        className="btn btn-primary btn-sm tag-remove-btn ms-2"
                        style={{
                          width: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                          padding: "0",
                          height: "25px",
                        }}
                      >
                        X
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
        <div className="tag-input-parent-box">
          <input
            ref={textRef}
            type="text"
            style={{
              border: "none",
              padding: "4px",
              verticalAlign: "initial",
              width: "auto",
            }}
            className="prevent-select form-control bg-transparent"
            onKeyDown={(e: any) => {
              if (e.key !== "Enter") return;
              typeof handleNew === "function" && handleNew(e.target.value);
              e.target.value = "";
            }}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
