import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import { MODULE_DELETE_URL } from "../app/request/userRequest";
import { TModuleDelete } from "../types/Network_ Request/moduleWiseApiType/deleteApi";
import { TTableAction } from "../types/TableActions";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { addAccessory, deleteAccessory } from "../generatedRequest/accessory";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/accessorySlice";
import { updateStatus } from "../generatedRequest/accessory";
import { useStatus } from "../customHooks/useStatus/useStatus";
import CarouselDemo from "../app/pages/Carousel";
import useClone from "../customHooks/useClone/useClone";
import ClonePrompt from "../components/ClonePrompt";
import { getRegion } from "../app/request/regionRequest";
import CloneRegionModel from "../components/CloneRegionModel";

const moduleName = "Accessory";

const AccessoryTableAction = ({ accessory, regions, isAccessory }: any) => {
  const { rerender1 } = useSelector((state: RootState) => state.accessory);
  const [showPreviewPrompt, setPreviewPrompt] = useState(false);
  const [newPayloads, setNewPayloads] = useState([]);
  const { doEdit } = useEdit(moduleName);
  const dispatch = useDispatch();
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);

  const [showCloneStatusModel, setShowCloneStatusModel] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState<any[]>([]);

  const [statusData, setStatusData] = useState([]);

  // Callback function to receive data from the child component
  const handleSelectedRegion = (data: any) => {
    setSelectedRegion(data);
    const payload = data.map((regionToClone: any) => {
      return {
        accessoryName: accessory?.accessoryName,
        attributes: [],
        accessoryImages: accessory?.accessoryImages.map((image: any) => image),
        description: accessory.description,
        subcategory: accessory.subcategory._id,
        regions: [regionToClone.id || regionToClone._id],
        category: accessory.category._id,
        brand: accessory.brand,
        model: accessory.model,
        modelNumber: accessory.modelNumber,
        sku: "",
        storeMappedProduct: accessory.storeMappedProduct,
      };
    });
    setNewPayloads(payload);
  };

  // let payload = {
  //   accessoryName: accessory?.accessoryName,
  //   attributes:[],
  //   accessoryImages: accessory?.accessoryImages.map((image:any)=> image),
  //   description: accessory.description,
  //   subcategory: accessory.subcategory._id,
  //   regions: selectedRegion.map((region:any) => region.id || region._id),
  //   category: accessory.category._id,
  //   brand:accessory.brand,
  //   model:accessory.model,
  //   modelNumber:accessory.modelNumber,
  //   sku:"",
  //   storeMappedProduct:accessory.storeMappedProduct
  // }

  const handleAfterDelete = useCallback(
    (flag: boolean, response: any) => {
      if (flag) {
        toast.success("record deleted");
        dispatch(setRerender(!rerender1));
        // do whatever you want with response
      } else {
        toast.error("error");
      }
    },
    [rerender1, dispatch]
  );
  const { canDo, currentActivity } = usePermissions();

  const handleAfterStatus = useCallback(
    (status: boolean, res: any) => {
      if (status) {
        toast.success("status updated successfully");
        dispatch(setRerender(!rerender1));
      } else {
        //TODO: make error when go live
        toast.error("something went wrong");
      }
    },
    [rerender1, dispatch]
  );

  const handleAfterClone = useCallback(
    (data: any) => {
      // if (status) {
      //   toast.success("Clone created successfully");
      dispatch(setRerender(!rerender1));
      setShowCloneStatusModel(true);
      setStatusData(data);

      // }
      //  else {
      //   //TODO: make error when go live
      //   toast.error(res?.data?.message);
      // }
      console.log("status", data);
    },
    [rerender1, dispatch]
  );

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteAccessory,
    true,
    handleAfterDelete
  );

  const {
    onStatus,
    showStatusPrompt,
    statusloading,
    statusToUpdate,
    onstatusCancel,
    ChangeStatus,
  } = useStatus(updateStatus, true, handleAfterStatus);

  const {
    onClone,
    showClonePrompt,
    setCloneShowPrompt,
    cloneLoading,
    recordToClone,
    onCancelClone,
    cloneRecord,
    newRegion,
  } = useClone(
    addAccessory,
    true,
    handleAfterClone,
    regions,
    selectedRegion,
    newPayloads
  );

  const actions = ["Edit", "Delete", "Change Status", "Preview", "Clone"];

  // const handlePreviewPrompt = useCallback(() => {
  //   const fn = () => {
  //     console.log("here")
  //     setPreviewPrompt(!showPreviewPrompt);
  //   };
  //   fn();
  // }, []);

  const handlePreviewPrompt = useCallback(() => {
    const fn = () => {
      setPreviewPrompt(!showPreviewPrompt);
    };
    fn();
  }, [showPreviewPrompt]);

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const onCloseCloneStatus = useCallback(() => {
    setShowCloneStatusModel(false);
  }, []);

  const onBackHandler = useCallback(()=>{
    setShowCloneStatusModel(false);
    setCloneShowPrompt(true);

  },[])

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          // if (canDo(moduleName, "Edit")) {
          doEdit(accessory._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          // if (canDo(moduleName, "Delete")) {
          onDelete(accessory._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Change Status":
          onStatus(accessory);
          break;
        case "Clone":
          onClone(accessory);
          break;
        case "Preview":
          handlePreviewPrompt();
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, accessory, onDelete, onClone, regions, onStatus]
  );

  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      {showPreviewPrompt && (
        <CarouselDemo
          Images={accessory?.accessoryImages}
          title={accessory?.accessoryName}
          categoryName={accessory?.category?.categoryName}
          subCategoryName={accessory?.subcategory?.subcategoryName}
          regionName={accessory?.regions[0]?.regionName}
          description={accessory?.description}
          sku={accessory?.sku}
          price={accessory?.price}
          onCancel={() => handlePreviewPrompt()}
        />
      )}

      {showStatusPrompt && (
        <ConfirmationPrompt
          title="Change Status"
          message="Are you sure you want to change the status ?"
          onCancel={onstatusCancel}
          onConfirm={ChangeStatus}
          isloading={statusloading}
        />
      )}

      {showClonePrompt && (
        <ClonePrompt
          title="Generate Clone"
          onCancel={onCancelClone}
          onConfirm={cloneRecord}
          isloading={cloneLoading}
          filterRegion={newRegion}
          sendSelectedRegionToParent={handleSelectedRegion}
        />
      )}

      {showCloneStatusModel && (
        <CloneRegionModel
          title={"Accessory Clone Status"}
          onCancel={onCloseCloneStatus}
          statusData={statusData}
          onBack= {onBackHandler}
          isAccessory={true}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default AccessoryTableAction;
