/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from "react";
import TableView from "../../../components/TableView";
import TableSkeleton from "../../../components/TableSkeleton";
import {
  setCurrentPage,
  setPerPage,
  setTotalPage,
  setRerender,
  setSearchTerm,
} from "../../../redux/slices/translationSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import { RootState } from "../../../redux/store";
import useSearchApi from "../../../customHooks/useSearchApi";
import TranslationFormModal from "./TranslationFormModal";
import TranslationActions from "./TranslationActions";
import { getTranslation } from "../../request/translationRequest";
import TableLoader from "../../../utils/Loaders/tableLoader";
import { toast } from "react-toastify";
import EmptyState from "../../../components/EmptyState";
import { TColumn, ThandleAfterSearchData } from "../../../types/Module/Translations/TranslationsList";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

function truncateText(text:any, maxLength:number) {
  if (text.length <= maxLength) {
      return text; 
  }
  let endIndex = text.lastIndexOf(' ', maxLength);
  if (endIndex === -1) {
      endIndex = maxLength;
  }
  let truncatedText = text.slice(0, endIndex) + '...';
  return truncatedText;
}

const columns:TColumn[] = [
  {
    name: "Label",
    renderHeader: () => (
      <>
        <b>Label</b>
      </>
    ),
    renderCell: (translation) => <span>{truncateText(translation?.labelText, 30)}</span>,
  },
  {
    name: "Created At",
    renderHeader: () => (
      <>
        <b>Created At</b>
      </>
    ),
    renderCell: (translation) => <span>{translation?.createdAt}</span>,
  },
  {
    name: "Status",
    renderHeader: () => (
      <>
        <b>Updated At</b>
      </>
    ),
    renderCell: (translation) => <span>{translation?.updatedAt}</span>,
  },
  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (translation) => (
      <TranslationActions translation={translation} />
    ),
  },
];

const TranslationsList = () => {
  const [translation, setTranslations] = useState([]);
  const { perPage, currentPage, totalPage, rerender1, searchTerm } =
    useSelector((state: RootState) => state.translation);
    const [loading, setLoading] = useState(false);
    const [initload,setInitload] = useState(true)
  const dispatch = useDispatch();


  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
      setLoading(true)
      try {
        const res = await getTranslation(searchQuery);
        if (res.data.status) {
          setTranslations(res.data.data.data);
          dispatch(setTotalPage(Math.ceil(res.data.data.totalCount / perPage)));
        }
        else {
          toast.error("Something Went Wrong");
        }   
      } catch (error) {
        toast.error("Network error")
      }
      finally{
        setLoading(false)
        setInitload(false)
      }
    
    };
    fn();
  }, [rerender1, dispatch]);


  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, []);

  function handleAfterSearch(isSuceess: boolean, data: ThandleAfterSearchData, value: number) {
    setTranslations(data?.data?.data?.data);
    dispatch(setTotalPage(Math.ceil(data?.data?.data?.totalCount / value)));
  }

  const { doSearch, searchLoading } = useSearchApi(
    getTranslation,
    handleAfterSearch
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch, dispatch]
  );
  if (loading && initload) {
    return <TableSkeleton rows={5} columns={6} />;
  }

  return (
    <>
      <TableView
        rows={translation}
        columns={columns}
        showPagination={true}
        showSearch={true}
        showFilter={false}
        showPerPage={true}
        paginationData={{ perPage, currentPage, totalPage }}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
        searchPlaceholder={"Search Translations"}
        handleSearchParams={handleSearchParams}
        searchComponent={<EmptyState                      
        title="Sorry"
        message="No Translations Found"
        buttonTitle="Reset"
        image="/media/misc/empty-State.svg"
      />}
      searchLoading={searchLoading}
      />
      
    </>
  );
};

const TranslationsPage: FC = () => {
  const { rerender1 } = useSelector((state: RootState) => state.translation);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);


  const handleAddTranslation = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(setRerender(!rerender1));
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
      <div className="d-flex align-items-center" style={{ gap: "5px" }}>
          <h2>Translations </h2>
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/icons/duotune/art/exclamation-icon.svg")}
            className="h-20px cursor-pointer"
            onClick={()=>navigate(`/helps?moduleName=Translations`)}

          />
        </div>
        <Button
          variant="primary"
          handleButtonClick={handleAddTranslation}
          size="medium"
        >
          Add Translation
        </Button>
      </div>

      <TranslationsList />

      {openModal && (
        <TranslationFormModal
          currentRecord={{
            id: "",
            labelText: "",
            
          }}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default TranslationsPage;
