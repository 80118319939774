
      import React from 'react'
      
      
  import InputField from '../components/Input'
  
  
      
      type TPermissionFormProp = {
        errors: any;
        isSubmitting: boolean;
        touched: any;
        getFieldProps: Function;
        setFieldValue:Function
        values:any
      }
      
      
      const PermissionForm=({ errors,isSubmitting, touched, getFieldProps, setFieldValue,
        values}: TPermissionFormProp)=> {
        return (
          <div className="card w-md-100">
                      
        
      <div>
      
  <InputField {...getFieldProps("id")}   
  placeholder="Enter id"
  error={errors.id}
  onChange={(value:string)=>setFieldValue("id",value)}
  value={values.id}
  />
  
    </div>   
  
      <div>
      
  <InputField {...getFieldProps("name")}   
  placeholder="Enter name"
  error={errors.name}
  onChange={(value:string)=>setFieldValue("name",value)}
  value={values.name}
  />
  
    </div>   
  
      
          </div>
        )
      }
      
      export default PermissionForm 
      