import React from "react";

const CustomCrossButton = () => {
  return (
    <button
      type="button"
      title="Click to delete tag"
      className="btn btn-primary btn-sm tag-remove-btn ms-2"
      style={{
        width: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        padding: "0px",
        height: "25px",
      }}
    >
      X
    </button>
  );
};

export default CustomCrossButton;
