import { Modal } from "react-bootstrap";
import { KTSVG } from "../../_metronic/helpers";
import Multiselect from "multiselect-react-dropdown";
import CustomArrow from "../../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../../utils/CustomStyleComponent/CrossButtonComponent";
import { useCallback, useEffect, useState } from "react";
import { getRegion } from "../../app/request/regionRequest";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import useSearchApi from "../../customHooks/useSearchApi";

type TModalProps = {
  title: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  isloading?: boolean;
  filterRegion?:any;
  sendSelectedRegionToParent?:Function;
};

const ClonePrompt = ({
  title,
  onCancel,
  onConfirm,
  isloading,
  filterRegion,
  sendSelectedRegionToParent
}: TModalProps) => {

  const handleRegionFilter = useCallback(
    (selectedRegion: any) => {
      typeof sendSelectedRegionToParent==="function" && sendSelectedRegionToParent(selectedRegion);
    },
    []
  );

  const style = {
    multiSelectContainer: {
      minheight: "44px",

      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontWeight: "500",
      minwidth: "40px",
      maxwidth: "40px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      width: "100%",

      // styles for the search wrapper
    },
    chips: {
      // To change css chips(Selected options)
      marginBottom: "0",
    },
  };


  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      show={true}
      dialogClassName="modal-md modal-dialog-centered"
      aria-hidden="true"
      onHide={onCancel}
    >
      <div className="modal-header">
        <h2 className="modal-title fw-bolder">{title}</h2>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onCancel}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body">
        <h6 className="mb-5">Please select any other region</h6>
        <Multiselect
          options={filterRegion}
          displayValue="regionName"
          placeholder="Select Region"
          style={style}
          showArrow={true}
          hidePlaceholder={true}
          selectionLimit={5}
          customArrow={<CustomArrow />}
          customCloseIcon={<CustomCrossButton />}
          avoidHighlightFirstOption={true}
          showCheckbox={true}
          onSelect={handleRegionFilter}
          onRemove={handleRegionFilter}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={onCancel}>
          Cancel
        </button>
        <button
          id="submit"
          type="button"
          className="btn btn-primary"
          onClick={onConfirm}
          disabled={isloading}
        >
          {!isloading && <span className="indicator-label">Confirm</span>}
          {isloading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </Modal>
  );
};

export default ClonePrompt;
