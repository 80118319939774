import QuizForm from "../generatedForms/Quiz";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import { addQuiz, editQuiz, getSingleQuiz } from "../generatedRequest/quiz";
import { QuizSchema } from "../generatedValidations/QuizSchema";
import { getSubCategory } from "../generatedRequest/subcategory";
import { getRegion } from "../app/request/regionRequest";

type TQuizModalProps = {
  isEdit: boolean;
};

const QuizFormModal = ({ isEdit }: TQuizModalProps) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [initload, setInitload] = useState(true);
  const quizid = queryParameters.get("q") || "";
  const [allRegions, setAllRegions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    subcategory: [],
    questions: [],
    region: "",
  });

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editQuiz(payload, quizid)
        : await addQuiz(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Quiz is edited Successfully")
          : toast.success("Quiz is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: QuizSchema,
    onSubmit: (values, { setSubmitting }) => {
      let payload = {
        subcategory: values?.subcategory?.[`0`],
        questions: values.questions,
        region: values.region,
      };
    
      handleSubmit(payload);
    },
  });

  useEffect(() => {
    const fetchQuizData = async () => {
      setLoading(true);
      try {
        const response = await getSingleQuiz(quizid); // Call your API to get user data
        if (response.data.status) {
          formik.setValues({
            ...response.data.data,
            subcategory: [response?.data?.data?.subcategory],
            region: response?.data?.data?.region || "",
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchQuizData();
    } else {
      setInitload(false);
    }
  }, [isEdit, quizid]);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getSubCategory();
        if (resp.data.status) {
          setAllSubCategories(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getRegion(`page=1&limit=100`);
        if (resp.data.status) {
          setAllRegions(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }




  return (
    <>
      <QuizForm
        allSubCategories={allSubCategories}
        allRegions={allRegions}
        {...formik}
        isloading={loading}
        isEdit={isEdit}
      />

      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default QuizFormModal;
