import Multiselect from "multiselect-react-dropdown";
import React from "react";
import InputField from "../components/Input";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";

type TSettingFormProp = {
  errors: any;
  allProducts: any;
  allCategories: any;
  allAccessories: any;
  isSubmitting: boolean;
  touched: any;
  getFieldProps: Function;
  setFieldValue: Function;
  values: any;
};

const SettingForm = ({
  errors,
  allProducts,
  allCategories,
  allAccessories,
  isSubmitting,
  touched,
  getFieldProps,
  setFieldValue,
  values,
}: TSettingFormProp) => {


  return (
    <div className="card w-md-50 p-5">
      <div>
        <Multiselect
          options={allProducts}
          // disable={isloading}
          customArrow={<CustomArrow />}
          customCloseIcon={<CustomCrossButton/>}
          selectionLimit={1}
          displayValue={"productName"}
          placeholder="Select Product"
          selectedValues={values.product}
          onSelect={async (e) => {
            await setFieldValue("product", e);
          }}
          onRemove={async (e) => {
            await setFieldValue("product", e);
            // await setFieldValue("subcategory", []);
          }}
          selectedValueDecorator={(value: string, option: any) => {
            return (
              <CustomTooltip value={value} option={option}/>
            );
          }}
        />
      </div>

      <div>
        <Multiselect
          options={allCategories}
          // disable={isloading}
          customArrow={<CustomArrow />}
          customCloseIcon={<CustomCrossButton/>}
          selectionLimit={1}
          selectedValues={values.category}
          displayValue={"categoryName"}
          placeholder="Select Category"
          onSelect={async (e) => {
            await setFieldValue("category", e);
          }}
          onRemove={async (e) => {
            await setFieldValue("category", e);
            // await setFieldValue("subcategory", []);
          }}
          selectedValueDecorator={(value: string, option: any) => {
            return (
              <CustomTooltip value={value} option={option}/>
            );
          }}
        />
      </div>

      <div>
        <Multiselect
          options={values?.category?.[0]?.subcategory}
          // disable={isloading}
          customArrow={<CustomArrow />}
          customCloseIcon={<CustomCrossButton/>}
          selectionLimit={1}
          selectedValues={values.subcategory}
          displayValue={"subcategoryName"}
          placeholder="Select Subcategory"
          onSelect={async (e) => {
            await setFieldValue("subcategory", e);
          }}
          onRemove={async (e) => {
            await setFieldValue("subcategory", e);
            // await setFieldValue("subcategory", []);
          }}
          selectedValueDecorator={(value: string, option: any) => {
            return (
              <CustomTooltip value={value} option={option}/>
            );
          }}
        />
      </div>

      <div>
        <Multiselect
          options={allAccessories}
          // disable={isloading}
          customArrow={<CustomArrow />}
          customCloseIcon={<CustomCrossButton/>}
          displayValue={"accessoryName"}
          placeholder="Select Accessory"
          onSelect={async (e) => {
            await setFieldValue("accessories", e);
          }}
          onRemove={async (e) => {
            await setFieldValue("accessories", e);
        
          }}
          selectedValueDecorator={(value: string, option: any) => {
            return (
              <CustomTooltip value={value} option={option}/>
            );
          }}
        />
      </div>
    </div>
  );
};

export default SettingForm;
