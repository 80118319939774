
      import React from 'react'
      import HelpFormModal from '../../generatedFormModal/HelpFormModal'

      const initialValues={name:"ngth3"}
      
      const EditHelp=({currentRecord}:{currentRecord?:any})=> {
        return (
          <div>
           <HelpFormModal isEdit={true}/> 
          </div>
        )
      }
      
      export default EditHelp

      