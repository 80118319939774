import allModules from "../../permission.json";
import { TUserPermissions } from "../../types/User/userPermissions";

/**
 * Check if the user has access to a module.
 * @param moduleName - The name of the module to check for access.
 * @param permissions - The user's permissions to check against.
 * @returns True if the user has access to the module, false otherwise.
 */
export const isAccessible = (
  moduleName: string,
  permissions: TUserPermissions
) => {
 
  // Find the module in the list of all modules.
  const module = allModules.modules.find(
    (module) => module.moduleName.toLowerCase() === moduleName.toLowerCase()
  );

  if (module && module.moduleName) {
    // Filter the user's permissions to only those that include the module name.
    // If any permissions match, the user has access.
    const currentLocationPermission =
      permissions &&
      permissions.filter(
        (permission) => permission.moduleName == module.moduleName
      );
    if (currentLocationPermission.length == 1) {
      const currentLocationViewPermission =
        currentLocationPermission?.[0]?.moduleActivities.filter(
          (currentLocationPermissionActivity: any) =>
            currentLocationPermissionActivity?.activityName?.toLocaleLowerCase() ==
            `view ${moduleName}`.toLocaleLowerCase()
        );
      return currentLocationViewPermission?.[0]?.isSelected;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * Check if the user has access to a specific activity within a module.
 * @param activity - The activity to check for access.
 * @param moduleName - The name of the module to check for access.
 * @param permissions - The user's permissions to check against.
 * @returns True if the user has access to the activity, false otherwise.
 */
export const can = (
  moduleName: string,
  activity: string,
  permissions: TUserPermissions
) => {
  // Find the module in the list of all modules.
  const module = allModules.modules.find(
    (module) => module.moduleName == moduleName
  );

  if (module && module.moduleName) {
    // Filter the user's permissions to only those that include the module name and activity.
    // If any permissions match, the user has access.
    return (
      permissions &&
      permissions.filter(
        (permission) =>
          permission.moduleName == module.moduleName &&
          permission?.moduleActivities?.filter(
            (activityName: any) => activityName?.activityFunction == activity
          )?.[`0`]?.isSelected
      ).length > 0
    );
  }

  return false;
};

/**
 * Check if the user has access to a module.
 * @param location - This points to the current module location and gets.
 */
export const getCurrentModule = (location: string) => {
  // Find the module in the list of all modules.
  const module = allModules.modules.find(
    (module) =>
      module.moduleName === location.replace(/\//g, "").toLocaleLowerCase()
  );
  if (module && module.moduleName) {
    return module;
  }
};
