import React from 'react'
import { TTableColumn, TTableRowProps } from '../../types/TableView';


const TableHeader = ({ columns, row}: TTableRowProps) => {
    return (
        <>
            {columns.map((col: TTableColumn,index:number) => {
                let className = " min-w-125px " + col.className;
                return <td key={index} className={col.name ==="name" ? className + " text-start" : className + " text-center"}>
                    {col.renderCell && typeof col.renderCell === "function" ? col.renderCell(row) : row[col.name]}
                </td>
            })}
        </>
    )
}

export default TableHeader