import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const RETAILER_GET_URL = BASE_URL + "/retailer";
const RETAILER_DELETE_URL = BASE_URL + "/retailer/";
const RETAILER_ADD_URL = BASE_URL + "/retailer";
const RETAILER_EDIT_URL = BASE_URL + "/retailer/";
const RETAILER_SEARCH_URL = BASE_URL + "/product/searchUrl";
const RETAILER_FILE_UPLOAD = BASE_URL + "/retailer/retailerFileHeadersCheck";
const RETAILER_MAPPEED_ACCESSORY_URL = BASE_URL + "/retailer/retailerMapping/";
const RETAILER_GET_MAPPEED_ACCESSORY_URL =
  BASE_URL + "/retailer/getAllRetailerData/";

const RETAILER__MAPPEED_ACCESSORY_UPDATE_STATUS_URL =
  BASE_URL + "/retailer/updateRetailerData";

const RETAILER__MAPPEED_ACCESSORY_DELETE_URL =
  BASE_URL + "/retailer/deleteRetailerData/";

export const getAllRetailerData = (retailerId: string) =>{
  console.log("called", retailerId);
  return axios.get(`${RETAILER_GET_URL}?page&limit&sort&search=&sortBy&retailerRegion=${retailerId}`)
}

export const getRetailer = (searchParams?: string) => {
  return axios.get(RETAILER_GET_URL + "?" + searchParams);
};

export const getSingleRetailer = (quizId: string) => {
  return axios.get(RETAILER_GET_URL + "/" + quizId);
};

export const deleteRetailer = (Id: string) => {
  return axios.delete(RETAILER_DELETE_URL + Id);
};

export const addRetailer = (payload: any) => {
  let data = new FormData();
  data.append("retailerName", payload?.retailerName);
  data.append("retailerRegion", payload?.retailerRegion);
  data.append("retailerFrontendUrl", payload?.retailerFrontendUrl);
  data.append("isElasticSearch", payload?.isElasticSearch);
  data.append("isFeed", payload?.isFeed);
  data.append("showRetailerPricing", payload?.price);
  data.append(
    "searchConfiguration[searchUrl]",
    payload?.searchConfiguration.searchUrl
  );
  data.append(
    "searchConfiguration[searchBy]",
    payload?.searchConfiguration.searchBy
  );
  data.append(
    "feedConfiguration[file]",
    payload?.feedConfiguration?.file?.[`0`]
  );
  data.append("feedConfiguration[mapBy]", payload?.feedConfiguration?.mapBy);
  data.append(
    "feedConfiguration[redirectFieldName]",
    payload?.feedConfiguration.redirectFieldName
  );

  return axios.post(RETAILER_ADD_URL, data);
};

export const editRetailer = (payload: any, Id: string) => {
  let data = new FormData();
  data.append("retailerName", payload?.retailerName);
  data.append("retailerRegion", payload?.retailerRegion);
  data.append("retailerFrontendUrl", payload?.retailerFrontendUrl);
  data.append("isElasticSearch", payload?.isElasticSearch);
  data.append("isFeed", payload?.isFeed);
  data.append("showRetailerPricing", payload?.price);
  data.append(
    "searchConfiguration[searchUrl]",
    payload?.searchConfiguration.searchUrl
  );
  data.append(
    "searchConfiguration[searchBy]",
    payload?.searchConfiguration.searchBy
  );
  data.append(
    "feedConfiguration[file]",
    payload?.feedConfiguration?.file?.[`0`]
  );
  data.append("feedConfiguration[mapBy]", payload?.feedConfiguration?.mapBy);
  data.append(
    "feedConfiguration[redirectFieldName]",
    payload?.feedConfiguration.redirectFieldName
  );
  return axios.patch(RETAILER_EDIT_URL + Id, data);
};

export const getRetailerSearchUrl = (payload: any) => {
  return axios.post(RETAILER_SEARCH_URL, payload);
};

export const addRetailerMappedAccessories = (payload: any) => {
  return axios.get(RETAILER_MAPPEED_ACCESSORY_URL + payload);
};

export const getRetailerMappedAccessories = (payload: any) => {
  return axios.get(RETAILER_GET_MAPPEED_ACCESSORY_URL + payload);
};

export const updateStatus = ({ statusForRetailer, _id }: any) => {
  const url = RETAILER__MAPPEED_ACCESSORY_UPDATE_STATUS_URL + "/" + _id;
  const newStatus = statusForRetailer === "active" ? "inactive" : "active";
  const payload = {
    statusForRetailer: newStatus,
  };

  if (_id) {
    return axios.patch(url, payload);
  }
};

export const deleteRetailerData = (id: string) => {
  return axios.patch(RETAILER__MAPPEED_ACCESSORY_DELETE_URL + id);
};


export const RetailerFileUpload = (payload: any) => {
  let data = new FormData();
  payload &&
  payload.forEach((item: any, i: number) => {
    data.append(`feedConfiguration[file]`, item);
  });
  return axios.post(RETAILER_FILE_UPLOAD, data);
};