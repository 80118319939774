import { useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setSubCategory,
  setSearchTerm,
  setTotalPage,
} from "../generatedSlice/subcategorySlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getSubCategory } from "../generatedRequest/subcategory";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import SubCategoryList from "../generatedTableList/SubCategoryList";
import { TTableProps } from "../types/TableView";
import { useNavigate } from "react-router-dom";
import SubCategoryTableAction from "../generatedTableActions/SubCategoryTableAction";
import { TColumn, ThandleAfterSearchData } from "../types/Module/SubCategory/SubCategoryPage";
import { NotesDisplay } from "../components/Reusable/NotesDisplay";
import { toAbsoluteUrl } from "../_metronic/helpers";

const columns:TColumn[] = [
  {
    name: "image",
    renderHeader: () => (
      <>
        <b>Image</b>
      </>
    ),
    renderCell: (row) => (
      <a
        href={
          `${process.env.REACT_APP_API_URL_FOR_IMAGE}` +
          `${row && row.subcategoryImageURL}`
        }
        target="_blank"
      >
        <img src={
          `${process.env.REACT_APP_API_URL_FOR_IMAGE}` +
          `${row && row.subcategoryImageURL}`
        } height="50px"/>
      </a>
    ),
  },
  {
    name: "name",
    renderHeader: () => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row) => <>{row.subcategoryName}</>,
  },
  {
    name: "Supported Attributes",
    renderHeader: () => (
      <>
        <b>Supported Attributes</b>
      </>
    ),
    renderCell: (row) => (
      <>
        {Array.prototype.map.call(
          row && row.supportedAttributes,
          function (item) {
            return (
              <span>
                <a
                  href={"/attribute/edit?q=" + item._id || item.id}
                  style={{ textDecoration: "underline" }}
                >
                  {item.attributeName}
                </a>
                {"  "}
              </span>
            );
          }
        )}
      </>
    ),
  },
  // {
  //   name: "notes",
  //   renderHeader: () => (
  //     <>
  //       <b>Notes</b>
  //     </>
  //   ),
  //   renderCell: (row) => {
  //    return (
  //       <span>
  //        <NotesDisplay notes={row.notes}/>
  //       </span>
  //     );
  //   },
  // },
  {
    name: "created_at",
    renderHeader: () => (
      <>
        <b>Created At</b>
      </>
    ),
    renderCell: (row) => <>{row.createdAt}</>,
  },
  {
    name: "updated_at",
    renderHeader: () => (
      <>
        <b>Modified At</b>
      </>
    ),
    renderCell: (row) => <>{row.updatedAt}</>,
  },
  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row) => <SubCategoryTableAction subcategory={row} />,
  },
];

const SubCategoryPage = () => {
  const dispatch = useDispatch();
  const [initload, setInitload] = useState(true);
  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    rerender1,
  } = useSelector((state: RootState) => state.subcategory);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
      dispatch(setLoading(true));
      try {
        const response = await getSubCategory(searchQuery);
        if (response.data.status) {
          dispatch(setSubCategory(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
      }
    };
    fn();
  }, [rerender1, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, []);

  function handleAfterSearch(isSuceess: boolean, data: ThandleAfterSearchData, value: number) {
    if (isSuceess) {
      dispatch(setSubCategory(data.data.data.data));
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }
  const { doSearch, searchLoading } = useSearchApi(
    getSubCategory,
    handleAfterSearch
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch]
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search SubCategories",
    searchLoading:searchLoading,
    showFilter:false
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
      <div className="d-flex align-items-center" style={{ gap: "5px" }}>
          <h2>SubCategory </h2>
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/icons/duotune/art/exclamation-icon.svg")}
            className="h-20px cursor-pointer"
            onClick={()=>navigate(`/helps?moduleName=SubCategory`)}

          />
      
        </div>
        <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add SubCategory
        </Button>
      </div>
      <SubCategoryList {...configuration}/>
   </>
  );
};

export default SubCategoryPage;
