import clsx from "clsx";
import { useCallback, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import {
  TTranslationFormProp,
  TTranslationItem,
} from "../../../types/Module/Translations/TranslationForm";

const TranslationForm = ({
  errors,
  isSubmitting,
  touched,
  getFieldProps,
  values,
  handleChange,
  handleAuto,
  autoloading,
  handleSingleLangaugeTranslations,
  singleAutoLoading,
}: TTranslationFormProp) => {
  const [current, setCurrent] = useState(-1);

  const handleMouseEnter = useCallback((index: number) => {
    setCurrent(index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setCurrent(-1);
  }, []);

  return (
    <>
      <div className="mb-10">
        <div className="d-flex align-items-start justify-content-center gap-10">
          <div
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            <input
              type="text"
              placeholder="Label"
              disabled={isSubmitting}
              {...getFieldProps("labelText")}
              className={clsx(
                "form-control bg-transparent w-100",
                { "is-invalid": touched.labelText && errors.labelText },
                {
                  "is-valid": touched.labelText && !errors.labelText,
                }
              )}
            />

            {errors.labelText && touched.labelText && (
              <div className="text-danger">{errors.labelText}</div>
            )}
          </div>

          <div>
            <button
              type="button"
              id="kt_password_reset_submit"
              className="btn btn-primary w-md-100 d-flex align-items-center"
              style={{ height: "42px" }}
              onClick={handleAuto}
            >
              {!autoloading && (
                <span className="indicator-label">
                  Generate
                  <img
                    alt="google translate"
                    src={toAbsoluteUrl(
                      "/media/icons/duotune/art/small-google-translate.svg"
                    )}
                    className="h-30px ms-1"
                  />
                </span>
              )}
              {autoloading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Loading
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="scroll-y" style={{ height: "400px" }}>
        <div className="d-flex flex-column flex-md-row flex-md-wrap w-100 w-md-100 pt-1 pe-3 ps-10">
          {values &&
            values.temp &&
            values.temp.map((translation: TTranslationItem, index: number) => {
              const translationvalue = `temp[${index}].value`;

              return (
                <div
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave()}
                  key={translation.languageCode}
                  className="border-bottom py-5 me-14 pe-2"
                  style={{ width: "300px" }}
                >
                  <div className="d-flex align-items-center">
                    <label
                      htmlFor="name"
                      className="w-25 form-label fs-7 fw-bold me-4"
                      style={{ width: "100px" }}
                    >
                      {translation.languageName}
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        placeholder=""
                        disabled={isSubmitting}
                        name={translationvalue}
                        style={{ height: "45px" }}
                        value={translation.value}
                        onChange={handleChange}
                        className={clsx(
                          "form-control bg-transparent w-75",
                          {
                            "is-invalid":
                              touched.langEnglish && errors.langEnglish,
                          },
                          {
                            "is-valid":
                              touched.langEnglish && !errors.langEnglish,
                          }
                        )}
                      />
                      {current == index && (
                        <span
                          className="input-group-text p-2"
                          id="basic-addon1"
                          style={{ cursor: "pointer",height:"45px" }}
                          onClick={() =>
                            handleSingleLangaugeTranslations(index, translation)
                          }
                        >
                          {!singleAutoLoading && (
                            <span>
                              <img
                                alt="google translate"
                                src={toAbsoluteUrl(
                                  "/media/icons/duotune/art/google-translate.svg"
                                )}
                                className="h-30px"
                              />
                            </span>
                          )}
                          {singleAutoLoading && (
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default TranslationForm;
