import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

const useClone = (
  makeRequest: Function,
  askConfirmation: boolean = true,
  afterClone?: Function,
  regions?: any,
  selectedRegion?: any,
  payloads: any[] = []
) => {
  const [recordToClone, setRecordToClone] = useState<any>({});
  const [showClonePrompt, setCloneShowPrompt] = useState<boolean>(false);
  const [cloneLoading, setCloneLoading] = useState<boolean>(false);
  const [newRegion, setNewRegion] = useState([]);


  const onClone = useCallback(
    (record: any) => {
      setRecordToClone(record);

      if (!askConfirmation) {
        // Call the cloneRecord function directly if askConfirmation is false
        cloneRecord();
      } else {
        setCloneShowPrompt(true);
        let remainingRegionNames = regions?.filter(
          (abc: any) => abc?.regionName != record?.regions?.["0"]?.regionName
        );
        setNewRegion(remainingRegionNames);
      }
    },
    [askConfirmation, regions, newRegion, setNewRegion]
  );

  const cloneRecord = useCallback(async () => {
    const totalResponse = [];
    // Iterate over each payload in the payloads array
    for (const payload of payloads) {
      let isSuccessful = false;
      let responsetosend = {};
      try {
        setCloneLoading(true);

        const response: any = await makeRequest(payload);

        if (response.data.status) {
          isSuccessful = true;
          responsetosend = response;
          totalResponse.push({status:isSuccessful,res:response,})
        } else {
          isSuccessful = false;
          responsetosend = response;
          totalResponse.push({status:isSuccessful,res:response,})
        }
      } catch (error) {
        toast.error("Network error");
      } finally {
        setCloneLoading(false);
        setRecordToClone({});
        setCloneShowPrompt(false);
        
      }
    }
    if (typeof afterClone === "function") {
      afterClone(totalResponse);
    }
  }, [afterClone, recordToClone, makeRequest, selectedRegion, payloads]);

  const onCancelClone = useCallback(() => {
    setRecordToClone({});
    setCloneShowPrompt(false);
  }, []);

  return {
    onClone,
    showClonePrompt,
    setCloneShowPrompt,
    cloneLoading,
    recordToClone,
    onCancelClone,
    cloneRecord,
    newRegion,
  };
};

export default useClone;
