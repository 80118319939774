import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export interface TranslationState {
    users: object[],
    perPage: number,
    currentPage: number,
    totalPage: number,
    searchTerm: string,
    loading: boolean,
    rerender1: boolean
}


const initialState: TranslationState = {
    users: [],
    perPage: 10,
    currentPage: 1,
    totalPage: 0,
    searchTerm: "",
    loading: false,
    rerender1:false
    
}

export const translationSlice = createSlice({
    name: 'translation',
    initialState,
    reducers: {
        setTranslations: (state:TranslationState, { payload }: PayloadAction<object[]>) => { },
        setPerPage: (state:TranslationState, {payload}: PayloadAction<number>) => {
            state.perPage = payload;
        },
        setCurrentPage: (state:TranslationState, {payload}: PayloadAction<number>) => { 
            state.currentPage = payload;
        },
        setSearchTerm: (state:TranslationState, {payload}: PayloadAction<string>) => { 
            state.searchTerm = payload;
        },
        setTotalPage: (state:TranslationState, {payload}: PayloadAction<number>) => { 
            state.totalPage = payload
        },
        setSearch: (state, {payload}: PayloadAction<string>) => { 
            state.searchTerm = payload;
        },
        setLoading: (state:TranslationState, {payload}: PayloadAction<boolean>) => { 
            state.loading=payload
        },
        setRerender: (state:TranslationState, {payload}: PayloadAction<boolean>) => {
            state.rerender1 = payload
         },
    },
})


export const { setTranslations, setPerPage, setCurrentPage, setTotalPage, setSearch, setLoading,setRerender,setSearchTerm} = translationSlice.actions
export default translationSlice.reducer