import React, { useEffect } from "react";
import ActiveInActiveFilter from "../components/Filters/ActiveInActiveFilter";
import LanguageFilter from "../components/Filters/LanguageFilter";
import RegionsFilter from "../components/Filters/RegionsFilter";
import RegionFilters from "./RegionFilters";

const UnitsFilters = ({
  onStatusFilterChange,
  currentStatusFilter,
  onRegionFilterChange,
  currentRegionFilter,
}: {
  onStatusFilterChange: Function;
  onRegionFilterChange: Function;
  currentRegionFilter: any;
  currentStatusFilter: any;
}) => {
  return (
    <>
      <RegionsFilter
        onRegionFilterChange={onRegionFilterChange}
        currentRegionFilter={currentRegionFilter}
      />
      <ActiveInActiveFilter
        onStatusFilterChange={onStatusFilterChange}
        currentStatusFilter={currentStatusFilter}
      />
    </>
  );
};

export default UnitsFilters;
