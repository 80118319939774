import React, { useEffect, useState } from "react";

type TFilterProps = {
  filterValues?: any;
  handleChange: any;
};

const Filters = ({ filterValues, handleChange }: TFilterProps) => {

  return (
    <div>
      {filterValues?.map((filter: any, filterDataIndex: number) => {
        return (
          <div className="filters">
            <h5 className="mb-4">{filter.attributeName}</h5>
            <div className="filter-values mb-5">
              {filter?.attributeValues.map(
                (val: any, attributeValuesIndex: number) => {
                  const addFilters = `filterData[${filterDataIndex}].attributeValues[${attributeValuesIndex}].isSelected`; // Use the original index
                  return (
                    <label
                      key={attributeValuesIndex}
                      className="form-check form-check-custom form-check-solid form-label fs-7 mb-3 cursor-pointer"
                    >
                      <input
                        className="form-check-input me-3"
                        type="checkbox"
                        //   disabled={isSubmitting}
                        name={addFilters}
                        checked={val.isSelected}
                        onChange={() =>
                          handleChange(filterDataIndex, attributeValuesIndex)
                        }
                      />
                      {val.value}
                    </label>
                  );
                }
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Filters;
