import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';



function TestDownload() {
  const location = useLocation();



  async function toDataURL(url:any) {
    try {
      
      const response = await axios.get(url);
      console.log(response,"fytdytddtd");
      const blob = response.data;
      console.log(blob);
      return URL.createObjectURL(blob);
    } catch (error) {
      // Handle error
      console.error(error);
      return null;
    }
  }
  

  useEffect(() => {
    const fileName = new URLSearchParams(location.search).get('fileName');
    if (fileName) {
      const downloadRoute = `${process.env.REACT_APP_API_URL_FOR_IMAGE}${fileName}`; // Replace with your download route
     
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = downloadRoute;
      link.target = '_blank';
      link.download = fileName; // Set the download attribute to the file name

      // Append the link to the document body
      document.body.appendChild(link);

      const wwe = toDataURL(downloadRoute)
      console.log(link)

      // Trigger the download
      link.click();

      // Clean up by removing the link from the document body
      document.body.removeChild(link);


     

    }
  }, [location]);

  return null;
}

export default TestDownload;
