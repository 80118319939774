import React from "react";
import QuizFormModal from "../../generatedFormModal/QuizFormModal";

const EditQuiz = () => {
  return (
    <div>
      <QuizFormModal isEdit={true} />
    </div>
  );
};

export default EditQuiz;
