
      import React from 'react'
      import PermissionFormModal from '../../generatedFormModal/PermissionFormModal'

      const initialValues={id:"8hzio",name:"gi6bq"}
      
      const EditPermission=({currentRecord}:{currentRecord?:any})=> {
        return (
          <div>
           <PermissionFormModal isEdit={true} currentRecord={
            initialValues }/> 
          </div>
        )
      }
      
      export default EditPermission

      