import { FormikProps, useFormikContext } from "formik";
import React from "react";

type TQuestionsComponentProps = {
  questions: any;
  //formik: FormikProps<any>;
  submitStep: any;
  stepNumber: any;
};

const QuestionsComponent = ({
  questions,
  //formik,
  submitStep,
  stepNumber,
}: TQuestionsComponentProps) => {
  const formik = useFormikContext(); // Access the Formik context
  const handleRadioButtonChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: any
  ) => {
    await formik.setFieldValue(fieldName, e.target.value);

    // Call submitStep to move to the next step
    submitStep(stepNumber);
  };

  return (
    <>
      <div data-kt-stepper-element="content">
        <div className="w-100 mt-15">
          <div className="pb-10 pb-lg-15">
            <h2
              className="fw-bolder text-dark d-flex justify-content-center"
              style={{ fontSize: "27px" }}
            >
              {questions?.title}
            </h2>
          </div>

          <div className="mb-10 fv-row">
            <div className="row mb-2" data-kt-buttons="true">
              {questions?.options?.map((option: any, index: number) => {
                return (
                  <div className="col-lg-4 mb-4" key={option?.value}>
                    <input
                      type="radio"
                      className="btn-check"
                      name={questions?.attributeName}
                      value={option?.value}
                      id={`kt_${option?.value.replace(" ", "_")}_${
                        questions.attributeName
                      }`}
                      onChange={(event: any) =>
                        handleRadioButtonChange(event, questions?.attributeName)
                      }
                    />
                    <label
                      className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                      htmlFor={`kt_${option?.value.replace(" ", "_")}_${
                        questions?.attributeName
                      }`}
                    >
                      <span className="d-flex justify-content-center flex-column w-100">
                        <span
                          style={{
                            width: "150px",
                            margin: "0 auto",
                          }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_API_URL_FOR_IMAGE +
                              option.image
                            }
                            alt="USB 3.0"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </span>
                        <span className="text-dark fw-bolder d-block fs-4 mt-5">
                          {option?.value}
                        </span>
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionsComponent;
