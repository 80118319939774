
    import { createSlice, PayloadAction } from '@reduxjs/toolkit'
    
    type TUnitState =  {
        data: [],
        perPage: number,
        currentPage: number,
        totalPage: number,
        searchTerm: string,
        loading: boolean,
        sortBy: string,
        sortColumn:string,
        rerender1: boolean
    }
    
    
    const initialUnitState: TUnitState = {
        data: [],
        perPage: 10,
        currentPage: 1,
        totalPage: 1,
        searchTerm: "",
        loading: false,
        sortBy: "desc",
        sortColumn:"",
        rerender1: false
        
    }
    
    export const unitSlice = createSlice({
        name: 'unit',
        initialState:initialUnitState,
        reducers: {
            setUnit: (state:TUnitState, { payload }: PayloadAction<[]>) => {
                state.data=payload
             },
            setPerPage: (state:TUnitState, {payload}: PayloadAction<number>) => {
                state.perPage = payload;
            },
            setCurrentPage: (state:TUnitState, {payload}: PayloadAction<number>) => { 
                state.currentPage = payload;
            },
            setTotalPage: (state:TUnitState, {payload}: PayloadAction<number>) => {
                state.totalPage = payload
             },
            setSearchTerm: (state:TUnitState, {payload}: PayloadAction<string>) => { 
                state.searchTerm = payload;
            },
            setSortBy: (state:TUnitState, {payload}: PayloadAction<string>) => { 
              state.sortBy = payload;
          },
          setSortColumn: (state:TUnitState, {payload}: PayloadAction<string>) => { 
            state.sortColumn = payload;
            state.sortBy = "desc";
        },
            setLoading: (state:TUnitState, {payload}: PayloadAction<boolean>) => { 
                state.loading=payload
            },
            setRerender: (state:TUnitState, {payload}: PayloadAction<boolean>) => {
                state.rerender1 = payload
             },
        },
    })
    
    
    export const { setUnit,setRerender, setPerPage, setCurrentPage, setSortColumn,setSortBy, setTotalPage, setSearchTerm, setLoading } = unitSlice.actions
    export default unitSlice.reducer
    