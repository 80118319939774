import React from "react";
import CategoryFormModal from "../../generatedFormModal/CategoryFormModal";

const AddCategory = () => {
  return (
    <div>
      <CategoryFormModal isEdit={false} />
    </div>
  );
};

export default AddCategory;
