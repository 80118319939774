import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const SCRAPPING_GET_URL = BASE_URL + "/product/document/scraperCheck";

const SCRAPPING_EDIT_URL = BASE_URL + "/Scrappings/";
const Scrapping_Status_URL = BASE_URL + "/product/document/save/product/check";
const Scrapping_StatusChange_URL =
  BASE_URL + "/product/document/update/scraperCheck";

export const getScrapping = () => {
  return axios.get(SCRAPPING_GET_URL);
};

export const updateStatus = (scrapping: any) => {
  const payload = {
    status: !scrapping?.isStopScraping,
    _id: scrapping?._id,
  };
  return axios.post(Scrapping_StatusChange_URL, payload);
};

export const getScrappingStatusForAll = () => {
  let payload = {
    singleScrapCheck: false,
  };
  return axios.post(Scrapping_Status_URL, payload);
};

export const getScrappingStatusForSingle = (scrapping: any) => {
  let payload = {
    brand: scrapping?.brand,
    singleScrapCheck: true,
  };
  return axios.post(Scrapping_Status_URL, payload);
};
