import Pagination from "../Pagination";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import { TTableProps } from "../../types/TableView";
import Search from "../Search";
import PerPage from "../PerPage";
import Card from "../Card";

const TableView = ({
  searchLoading,
  rows,
  columns,
  searchPlaceholder,
  showPagination,
  showSearch,
  showPerPage,
  onPageChange,
  handleSearchParams,
  onPerPageChange,
  isLoading,
  isMounted,
  loadingMessage,
  searchComponent,
  paginationData,
  filters,
  showFilter,
  cardHeaderStyle,
}: TTableProps) => {
  const { currentPage, totalPage, perPage } = paginationData || {
    currentPage: 0,
    totalPage: 0,
    perPage: 0,
  };

  return (
    <Card>
      <div className="card-body p-0">
        <div className="table-responsive">
          {showSearch && (
            <div
              className="card-header border-0 ps-0 pe-0 align-items-center"
              style={
                cardHeaderStyle
                  ? { paddingTop: cardHeaderStyle }
                  : { paddingTop: "0" }
              }
            >
              <div className="card-title">
                <div className="d-flex align-items-center justify-content-between position-relative my-1">
                  <Search
                    placeholder={searchPlaceholder || ""}
                    handleSearchParams={handleSearchParams as any}
                  />
                </div>
              </div>
              <div className="d-flex gap-2">{showFilter && filters}</div>
            </div>
          )}
          <table className="table align-middle table-striped table-hover gs-0 gy-5">
            <TableHeader columns={columns} />
            <TableBody
              searchLoading={searchLoading}
              rows={rows}
              columns={columns}
              isSearching={isLoading}
              isMounted={isMounted}
              searchComponent={searchComponent}
            />
          </table>
          <div className="card-footer py-5 px-0 d-flex justify-content-between">
            {showPerPage && (
              <div className="w-10">
                <PerPage perPage={perPage} onPerPageChange={onPerPageChange} />
              </div>
            )}
            {showPagination && (
              <Pagination
                currentPage={currentPage}
                totalPage={totalPage}
                onPageChange={onPageChange as Function}
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default TableView;
