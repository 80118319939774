
import PermissionForm from "../generatedForms/Permission";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import { addPermission } from "../generatedRequest/Permission";
import { PermissionSchema } from "../generatedValidations/PermissionSchema";

type TPermissionModalProps = {
  currentRecord: any;
  isEdit?: boolean;
};

const PermissionFormModal = ({
  currentRecord,
  isEdit,
}: TPermissionModalProps) => {
  const [loading, setLoading] = useState(false);
  let title = isEdit ? "Edit" : "Add";
  const formik = useFormik({
    initialValues: currentRecord,
    validationSchema: PermissionSchema,

    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      const fn = async () => {
        setSubmitting(true);
        try {
          const res = await addPermission(values);
          toast.success("Settings saved successfully");
        } catch (error) {
          //TODO: make error when go live
          toast.success("Settings saved successfully");
        }
        setSubmitting(false);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        formik.resetForm(); //to reset the form
      };
      fn();
    },
  });
  return (
    <>
      <div className="modal-header">
        <h2 className="modal-title fw-bolder">{title} Permission</h2>
      </div>
      <div className="card p-5 w-md-50">
        <PermissionForm {...formik} />
        <div className="d-flex justify-content-end">
          <button
            type="button"
            id="kt_password_reset_submit"
            disabled={formik.isSubmitting || !formik.isValid}
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
          >
            {!loading && <span className="indicator-label">Save</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default PermissionFormModal;

      