import { useEffect, useRef, useState } from "react";
import { Step1 } from "./steps/Step1";
import { Step5 } from "./steps/Step5";
import { Formik, Form, FormikValues, useFormik, Field } from "formik";
import { StepperComponent } from "../../_metronic/assets/ts/components";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { ICreateAccount, createAccountSchemas, inits } from "./StepperHelper";
import "./Stepper.css";
import { getSingleQuiz } from "../../generatedRequest/quiz";
import { toast } from "react-toastify";

const Stepper = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0]);
  const [initValues] = useState<any>(inits);
  const [showQuestionList, setShowQuestionList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const getQuestions = (accessoryType: string) => {
    const fn = async () => {
      setLoading(true);
      try {
        const response = await getSingleQuiz(accessoryType); // Call your API to get quiz data
        if (response.data.status) {
          setShowQuestionList(response?.data?.data?.questions);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
      }
    };
    fn();
  };

  const setQuestionListBasedOnAccessoryType = (accessoryType: string) => {
    switch (accessoryType) {
      case "docking station":
        getQuestions(accessoryType);
        break;
      case "charger":
        getQuestions(accessoryType);
        break;
      case "privacy screen":
        getQuestions(accessoryType);
        break;
      case "cable lock":
        getQuestions(accessoryType);
        break;
      default:
        // Handle default case if accessoryType doesn't match any of the above cases
        break;
    }
  };

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext();
    } else {
      stepper.current.goto(1);
      actions.resetForm();
    }

    setCurrentSchema(
      createAccountSchemas[stepper.current.currentStepIndex - 1]
    );
  };

  // Initialize formik using useFormik hook
  const formik = useFormik({
    validationSchema: currentSchema,
    initialValues: initValues,
    onSubmit: submitStep,
  });

  useEffect(() => {
    setQuestionListBasedOnAccessoryType(formik.values.accessoryType);
  }, [formik.values.accessoryType]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );

    if (stepper.current && stepper.current.currentStepIndex == 1) {
      stepper.current.goNext();
    }
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    // Get the current step index
    const currentStepIndex = stepper.current.currentStepIndex;

    stepper.current.goPrev();

    setCurrentSchema(createAccountSchemas[currentStepIndex - 1]);
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }
    showQuestionList?.length > 0 && loadStepper();
  }, [stepperRef, showQuestionList]);

  // Function to handle radio button selection
  const handleRadioButtonChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: any
  ) => {
    await formik.setFieldValue(fieldName, e.target.value);

    // Call submitStep to move to the next step
    submitStep(formik.values, formik);
  };

  return (
    <div
      ref={stepperRef}
      className="stepper stepper-pills stepper-column d-flex flex-column"
      id="kt_create_account_stepper"
    >
      <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100">
        <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
          <div className="stepper-nav">
            <div className="stepper-item current" data-kt-stepper-element="nav">
              <div className="stepper-wrapper">
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check fas fa-check"></i>
                  <span className="stepper-number">1</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">Device Type</h3>
                </div>
              </div>
            </div>

            {showQuestionList.map((step: any, index: number) => {
              return (
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">{index + 2}</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">{step?.attributeName}</h3>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="stepper-item" data-kt-stepper-element="nav">
              <div className="stepper-wrapper">
                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check fas fa-check"></i>
                  <span className="stepper-number">
                    {showQuestionList.length + 2}
                  </span>
                </div>
                <div className="stepper-label">
                  <h3 className="stepper-title">Results</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row-fluid flex-center bg-body rounded">
        <Formik
          validationSchema={currentSchema}
          initialValues={initValues}
          onSubmit={submitStep}
        >
          {() => (
            <Form
              className="py-20 w-100 px-9"
              noValidate
              id="kt_create_account_form"
            >
              <div className="current" data-kt-stepper-element="content">
                <Step1 isLoading={loading} formik={formik} submitStep={submitStep} />
              </div>

              {/* this is to generate number of steps based on question list */}
              {showQuestionList?.map((questions: any) => {
                return (
                  <div data-kt-stepper-element="content">
                    <div className="w-100">
                      <div className="pb-10 pb-lg-15">
                        <h2
                          className="fw-bolder text-dark d-flex justify-content-center"
                          style={{ fontSize: "27px" }}
                        >
                           {questions.title}
                        </h2>
                      </div>

                      <div className="mb-10 fv-row">
                        <div className="row mb-2" data-kt-buttons="true">
                          {questions.options.map(
                            (option: any, index: number) => {
                              return (
                                <div
                                  className="col-lg-4 mb-4"
                                  key={option.value}
                                >
                                  <Field
                                    type="radio"
                                    className="btn-check"
                                    name={questions.attributeName}
                                    value={option.value}
                                    id={`kt_${option.value.replace(" ", "_")}_${
                                      questions.attributeName
                                    }`}
                                    onChange={(event: any) =>
                                      handleRadioButtonChange(
                                        event,
                                        questions.attributeName
                                      )
                                    }
                                  />
                                  <label
                                    className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
                                    htmlFor={`kt_${option.value.replace(
                                      " ",
                                      "_"
                                    )}_${questions.attributeName}`}
                                  >
                                    <span className="d-flex justify-content-center flex-column w-100">
                                      <span
                                        style={{
                                          //height: "100px",
                                          width: "150px",
                                          margin: "0 auto",
                                        }}
                                      >
                                        <img
                                          src={
                                            process.env
                                              .REACT_APP_API_URL_FOR_IMAGE +
                                            option.image
                                          }
                                          alt="USB 3.0"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                          }}
                                        />
                                      </span>
                                      <span className="text-dark fw-bolder d-block fs-4 mt-5">
                                        {option.value}
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div data-kt-stepper-element="content">
                <Step5
                  formik={formik}
                  islastStep={
                    stepper?.current?.currentStepIndex !== undefined &&
                    stepper?.current?.totalStepsNumber !== undefined &&
                    stepper.current.currentStepIndex ===
                      stepper.current.totalStepsNumber
                  }
                />
              </div>

              <div className="d-flex flex-stack pt-10">
                <div className="mr-2">
                  <button
                    onClick={prevStep}
                    type="button"
                    className="btn btn-primary me-3"
                    data-kt-stepper-action="previous"
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr063.svg"
                      className="svg-icon-4 me-1"
                    />
                    Back
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { Stepper };
