import React, { useEffect } from "react";
import ActiveInActiveFilter from "../components/Filters/ActiveInActiveFilter";
import LanguageFilter from "../components/Filters/LanguageFilter";

const RegionFilters = ({
  onStatusFilterChange,
  onLanguageFilterChange,
  currentStatusFilter,
  currentLanguageFilter,
}: {
  onStatusFilterChange: Function;
  onLanguageFilterChange: Function;
  currentStatusFilter: any;
  currentLanguageFilter: any;
}) => {
  return (
    <>
      <LanguageFilter
        onLanguageFilterChange={onLanguageFilterChange}
        currentLanguageFilter={currentLanguageFilter}
      />
      <ActiveInActiveFilter
        onStatusFilterChange={onStatusFilterChange}
        currentStatusFilter={currentStatusFilter}
      />
    </>
  );
};

export default RegionFilters;
