
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import LeadPage from "../generatedTablePage/LeadPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  

  
  
  const LeadRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<LeadPage/>}
  />
  
       
         
      </Routes>
    );
  };
  export default LeadRoutes
