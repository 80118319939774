import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const QUIZ_GET_URL = BASE_URL + "/quiz/";
const QUIZ_DELETE_URL = BASE_URL + "/quiz/";
const QUIZ_ADD_URL = BASE_URL + "/quiz/";
const QUIZ_EDIT_URL = BASE_URL + "/quiz/";
const QUIZ_IMAGE_UPLOAD_URL = BASE_URL + "/quiz/upload";
const QUIZ_IMAGE_DELETE = BASE_URL + "/quiz/deleteImageAdd";
const QUIZ_EDIT_IMAGE_DELETE = BASE_URL + "/quiz/deleteImage";

export const getQuiz = (searchParams?: string) => {
  return axios.get(QUIZ_GET_URL + "?" + searchParams);
};

export const getSingleQuiz = (quizId: string) => {
  return axios.get(QUIZ_GET_URL + quizId);
};

export const deleteQuiz = (quizId: string) => {
  return axios.delete(QUIZ_DELETE_URL + quizId);
};

export const addQuiz = (payload:any) => {
  return axios.post(QUIZ_ADD_URL,payload);
};

export const editQuiz = (payload: any, quizId: string) => {
  return axios.patch(QUIZ_EDIT_URL + quizId, payload);
};


export const quizImageUpload = (payload: any) => {
  let data = new FormData();
  payload &&
    payload.forEach((item: any, i: number) => {
      console.log(item)
      data.append(`quizImage`, item);
    });
  return axios.post(QUIZ_IMAGE_UPLOAD_URL, data);
};


export const AddDeleteImage = (imageId: any) =>{
  return axios.delete(`${QUIZ_IMAGE_DELETE}?imageId=${imageId}`);
}
export const EditDeleteImage = (quizId: any, imageId:any) =>{
  return axios.delete( `${QUIZ_EDIT_IMAGE_DELETE}/${quizId}?imageId=${imageId}`);
}