
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import AttributePage from "../generatedTablePage/AttributePage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddAttribute from "../generatedAddEdit/attribute/AddAttribute";
  import EditAttribute from "../generatedAddEdit/attribute/EditAttribute";
  

  
  
  const AttributeRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<AttributePage/>}
  />
  <Route
    path="/edit"
    element={
      <EditAttribute/>
    }
  />
  <Route
    path="/add"
    element={
      <AddAttribute/>
    }
  />      
         
      </Routes>
    );
  };
  export default AttributeRoutes
