import * as Yup from "yup";

export const QuizSchema = Yup.object().shape({
  subcategory: Yup.array().min(1, "At least one subcategory is required."),
  // regions: Yup.array()
  // .min(1, "Minimum 1 region is Required")
  // .max(1, "Maximum 1 region is Required")
  // .required("region is required."),
  region: Yup.string().required("Region is required"),
  questions: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Question is required"),
        attributeName: Yup.string().required(
          "At least one Attribute is required."
        ),
        options: Yup.array().of(
          Yup.object().shape({
            image: Yup.string().required("Image is required"),
            value: Yup.string().required("Option value is required"),
          })
        ),
      })
    )
    .min(1, "At least one question is required."),
});
