import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import { MODULE_DELETE_URL } from "../app/request/userRequest";
import { TModuleDelete } from "../types/Network_ Request/moduleWiseApiType/deleteApi";
import { TTableAction } from "../types/TableActions";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { deleteQuiz } from "../generatedRequest/quiz";
import { setRerender } from "../generatedSlice/quizSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

const moduleName = "Quiz";

const QuizTableAction = ({ quiz }: any) => {
  const { rerender1 } = useSelector((state: RootState) => state.product);
  const { doEdit } = useEdit(moduleName);
  const dispatch = useDispatch();
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const handleAfterDelete = useCallback((flag: boolean, response: any) => {
    if (flag) {
      toast.success("record deleted");
      dispatch(setRerender(!rerender1));
      // do whatever you want with response
    } else {
      toast.error("error");
    }
  }, []);
  const { canDo, currentActivity } = usePermissions();

  const { onDelete, deleteRecord, showPrompt, onCancel } = useDelete(
    deleteQuiz,
    true,
    handleAfterDelete
  );
  const actions = ["Edit", "Delete"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          // if (canDo(moduleName, "Edit")) {
          doEdit(quiz?._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          // if (canDo(moduleName, "Delete")) {
          onDelete(quiz?._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, quiz, onDelete]
  );
  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default QuizTableAction;
