import { useCallback, useState } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useSearchApi = (makeRequest: Function, searchCallback: Function) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const doSearch = useCallback((searchQuery: string,value?:number) => {
    const fn = async () => {
      let success = false;
      let res = null
      setSearchLoading(true);
      try {
        res = await makeRequest(searchQuery);
        success = res.data.status;
      } catch (error) {
        success = false
        toast.error("Network error")
      }
      typeof searchCallback === "function" && searchCallback(success, res,value);
      setTimeout(()=>{
        setSearchLoading(false);
      },500)
      
    }
    fn();
  }, [searchCallback,makeRequest])

  return {
    searchLoading: searchLoading,
    doSearch: doSearch,
  }

}

export default useSearchApi