import React, { useState, useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import HelpPage from "../generatedTablePage/HelpPage";
import ModalComponent from "../components/Modal";
import { Outlet } from "react-router-dom";
import AddHelp from "../generatedAddEdit/help/AddHelp";
import EditHelp from "../generatedAddEdit/help/EditHelp";

const HelpRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HelpPage />} />
      <Route path="/edit" element={<EditHelp />} />
      <Route path="/add" element={<AddHelp />} />
    </Routes>
  );
};
export default HelpRoutes;
