import { useCallback, useEffect, useState } from "react";
import TableView from "../components/TableView";
import ScrappingTableAction from "../generatedTableActions/ScrappingTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setScrapping,
  setSearchTerm,
  setRerender,
} from "../generatedSlice/ScrappingSlice";
import useSearchApi from "../customHooks/useSearchApi";
import {
  getScrapping,
  getScrappingStatusForAll,
} from "../generatedRequest/Scrapping";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import ScrappingList from "../generatedTableList/ScrappingList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function formatLastChecked(lastChecked: any) {
  if (lastChecked == "" || null || undefined) {
    return null;
  }
  // Use moment to parse and format the date and time
  return moment(lastChecked).format("YYYY-MM-DD HH:MM:SS");
}

const columns = [
  {
    name: "Manufacturer",
    renderHeader: (rows: any) => (
      <>
        <b>Manufacturer</b>
      </>
    ),
    renderCell: (row: any) => <>{`${row?.brand}`?.toUpperCase()}</>,
  },
  {
    name: "status",
    renderHeader: (rows: any) => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (row: any) => (
      <Badge
        variant={row?.isStopScraping == true ? "danger" : "success"}
        className="fs-7 fw-bolder"
      >
        {row?.isStopScraping == true ? "Inactive" : "Active"}
      </Badge>
    ),
  },
  {
    name: "LastChecked",
    renderHeader: (rows: any) => (
      <>
        <b>Last Checked</b>
      </>
    ),
    renderCell: (row: any) => <>{formatLastChecked(row?.lastCheck)}</>,
  },
  {
    name: "Actions",
    renderHeader: (rows: any) => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row: any) => (
      <ScrappingTableAction scrappingManufacturer={row} />
    ),
  },
];

const ScrappingPage = () => {
  const { rerender1 } = useSelector((state: RootState) => state.Scrapping);
  const dispatch = useDispatch();
  const [loadingOfScrapping, setLoadingOfScrapping] = useState(false);

  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    sortBy,
    sortColumn,
  } = useSelector((state: RootState) => state.Scrapping);

  useEffect(() => {
    const fn = async () => {
      dispatch(setLoading(true));
      try {
        const response = await getScrapping();
        if (response.data.status) {
          dispatch(setScrapping(response.data.data));
        } else {
          toast.error("Error Fetching manufacturers");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setLoadingOfScrapping(false);
      }
    };
    fn();
  }, [rerender1]);

  const handleScrappingCheckAll = async () => {
    try {
      setLoadingOfScrapping(true);
      const response = await getScrappingStatusForAll();
      if (response.data.status) {
        dispatch(setRerender(!rerender1));
      } else {
        toast.error("Error Fetching Current Status");
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
    }
  };

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `${searchTerm}&currentpage=${currentPage}&perPage=${value}`;
      doSearch(searchQuery);
    },
    [dispatch, currentPage, perPage, searchTerm]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `${searchTerm}&currentpage=${value}&perPage=${perPage}`;
      doSearch(searchQuery);
    },
    [dispatch, currentPage, perPage, searchTerm]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `${value}&currentpage=${currentPage}&perPage=${perPage}`;
      doSearch(searchQuery);
    },
    [currentPage, perPage, dispatch]
  );

  const handleAfterSearch = useCallback((value: string) => {}, []);

  const { doSearch, searchLoading } = useSearchApi(
    getScrapping,
    handleAfterSearch
  );

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: false,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Scrapings",
  };

  const navigate = useNavigate();

  if (loading) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Scrapings</h2>
        {/* <Button
          variant="primary"
          size="medium"
          handleButtonClick={handleScrappingCheckAll}
        >
          Check Scapping Status
        </Button> */}
        <button
          type="button"
          className="btn btn-primary d-flex align-items-center"
          style={{ height: "42px" }}
          onClick={handleScrappingCheckAll}
        >
          {!loadingOfScrapping && (
            <span className="indicator-label">Check Scaping Status (ALL)</span>
          )}
          {loadingOfScrapping && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Checking Status...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      <ScrappingList {...configuration} />
      {searchLoading && (
        <TableLoader isLoading={searchLoading} loadingMessage={"loading"} />
      )}
    </>
  );
};

export default ScrappingPage;
