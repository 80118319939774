
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import JatinPage from "../generatedTablePage/JatinPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";

  
  
  const JatinRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<JatinPage/>}
  />
  <Route
    path="/edit"
    element={
      <ModalComponent/>
    }
  />
  <Route
    path="/add"
    element={
      <ModalComponent/>
    }
  />      
         
      </Routes>
    );
  };
  export default JatinRoutes
