
    import { TTableProps } from "../types/TableView";
    import TableView from "../components/TableView";
import EmptyState from "../components/EmptyState";

  const ProductList = ({ searchLoading,columns, rows, showPagination, showSearch, showPerPage, paginationData, onPageChange, onPerPageChange, handleSearchParams, searchPlaceholder,showFilter,filters }:TTableProps) => { 
   return (
    <div className='card'>
    <TableView
    columns={columns}
    rows={rows}
    showFilter={showFilter}
    filters={filters}
    showPagination={showPagination}
    showSearch={showSearch}
    showPerPage={showPerPage}
    paginationData={paginationData}
    onPageChange={onPageChange}
    onPerPageChange={onPerPageChange}
    handleSearchParams={handleSearchParams}
    searchPlaceholder={searchPlaceholder}
    searchLoading={searchLoading}
    cardHeaderStyle ={"30px"}
    searchComponent={<EmptyState                      
      title="Sorry"
      message="No Product Found"
      buttonTitle="Reset"
      image="/media/misc/empty-State.svg"
    />}
  />
    </div>
  );
};

export default ProductList;
    