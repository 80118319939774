import { useState, useCallback } from "react";

// Define the hook function with three parameters
export const useDelete = (
  makeRequest: Function,
  askConfirmation: boolean = true,
  afterDelete?: Function
) => {
  // Define three states using the useState hook
  const [recordIdToDelete, setRecordIdToDelete] = useState<string>("");
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // Define the onDelete function, which is called when the user clicks the delete button
  const onDelete = useCallback(
    (id: string) => {
      // Set the recordIdToDelete state to the ID of the record to be deleted
      setRecordIdToDelete(id);
      // If askConfirmation is false, call the deleteRecord function directly
      if (!askConfirmation) {
        deleteRecord();
      } else {
        // Otherwise, set the showPrompt state to true to show the confirmation dialog
        setShowPrompt(true);
      }
    },
    [askConfirmation]
  );

  // Define the deleteRecord function using the useCallback hook to memoize the function
  const deleteRecord = useCallback(async () => {
    // Initialize a flag to indicate whether the delete operation was successful
    let isSuccessful = false;
    let response: any = {};

    try {
      // Set the loading state to true to indicate that the delete operation is in progress
      setLoading(true);

      // Define the payload object with the ID of the record to be deleted
      const payload = recordIdToDelete;
      // Call the makeRequest function with the payload object to delete the record
      response = await makeRequest(payload);
      // If the server returns a successful response status, set the isSuccessful flag to true
      if (response.data.status) {
        isSuccessful = true;
      } else {
      }
    } catch (error) {
    } finally {
      // Set the loading state to false to indicate that the delete operation has completed
      setLoading(false);
      // Reset the recordIdToDelete state to an empty string
      setRecordIdToDelete("");
      setShowPrompt(false);
      // Call the afterDelete callback function with the isSuccessful flag as an argument
      // (if the afterDelete function is provided as a parameter)
      if (typeof makeRequest === "function") {
        afterDelete && afterDelete(isSuccessful, response);
      }
    }
  }, [afterDelete, recordIdToDelete, makeRequest]);

  // Define the onCancel function, which is called when the user clicks the cancel button
  const onCancel = useCallback(() => {
    // Reset the recordIdToDelete state to an empty string
    setRecordIdToDelete("");
    // Set the showPrompt state to false to hide the confirmation dialog
    setShowPrompt(false);
  }, []);

  // Return an object containing the onDelete, showPrompt, loading, deleteRecord, and onCancel functions
  return { onDelete, showPrompt, loading, deleteRecord, onCancel };
};
