import React, { useCallback, useState } from "react";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { deleteAttribute } from "../generatedRequest/attribute";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/attributeSlice";
import { useDispatch, useSelector } from "react-redux";
import { TAttributeProps, TRowData } from "../types/Module/Attribute/AttributePage";

const moduleName = "Attribute";

const AttributeTableAction = ({ attribute }: TAttributeProps) => {
  const { rerender1 } = useSelector((state: RootState) => state.attribute);
  const dispatch = useDispatch();
  const { doEdit } = useEdit(moduleName);
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const handleAfterDelete = useCallback(
    (flag: boolean, response: TRowData) => {
      if (flag) {
        toast.success("record deleted");
        dispatch(setRerender(!rerender1));
        // do whatever you want with response
      } else {
        toast.error("error");
      }
    },
    [rerender1, dispatch]
  );

  const { currentActivity } = usePermissions();

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteAttribute,
    true,
    handleAfterDelete
  );
  const actions = ["Edit", "Delete"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          doEdit(attribute.id);

          break;
        case "Delete":
          onDelete(attribute.id);

          break;
        default:
          break;
      }
    },
    [doEdit, attribute, onDelete]
  );
  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default AttributeTableAction;
