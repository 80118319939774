import axios from 'axios'
import usersresponse from "../../mock-api/users.json"
import { TModuleDelete } from '../../types/Network_ Request/moduleWiseApiType/deleteApi';


type TResponse = any;
 
const BASE_URL = process.env.REACT_APP_API_URL;

// const USER_API_URL = process.env.REACT_APP_API_URL + "/users";
export const USER_API_URL = `${BASE_URL}/users`;
export const  DELETE_USER_URL = `${BASE_URL}/user`;
export const MODULE_DELETE_URL = `/static-response/users/delete.json`;
const CHANGE_STATUS_URL = BASE_URL+"/update-status/"


// export function getUserList(params:string) {
//   return axios.get<TResponse>(USER_API_URL + params)
// }

export function getUserList(params: string) {
  return axios.get<TResponse>(USER_API_URL+ "?"+ params)
}
export const changeStatus = (user:any)=>{
  const url = CHANGE_STATUS_URL + user.id;
  const newStatus= user.overview.status == "active" ? "inactive" :"active"
  const payload = {
      status:newStatus
  }
  if(user.id){
    
      return axios.patch(url, payload);
  }

}

export function deleteUser(params: string) {

  return axios.delete<TResponse>(DELETE_USER_URL+ "/"+ params)
}
