import { FormikProps, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { getMappedAccessory } from "../../../generatedRequest/accessory";
import { toast } from "react-toastify";
import StepperSkeleton from "../../Stepper/Skeleton";
import StepperccessoryFilters from "../Filters/StepperAccessoryFilters";

type TCompatibleProductsProps = {
  formik: FormikProps<any>;
};

const CompatibleProducts = () => {
  const [currentFilterValues, setCurrentFilterValues] = useState<any>([]);
  const [productData, setProductData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormikContext(); // Access the Formik context
  console.log("compatabile formik.values", formik.values);
  const [formikValues, setFormikValues] = useState<any>(formik.values);

  console.log("formikValues", formikValues);

  //console.log("formik.values", formik.values);
  let payload = {};
  const fn = useCallback(
    async (payload: any, shouldUpdateData: boolean = true) => {
      console.log("payload", payload);
      setLoading(true);
      try {
        const res = await getMappedAccessory(payload);
        if (shouldUpdateData) {
          if (res.data.status) {
            setProductData(res?.data?.data?.["0"]?.accessories);
            setFilterData(
              res?.data?.data?.["0"]?.subcategory?.filteredAttribute
            );
          } else {
            setProductData([]);
          }
        } else {
          if (res.data.status) {
            setProductData(res?.data?.data?.["0"]?.accessories);
          } else {
            setProductData([]);
          }
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
      }
    },
    [setProductData, setFilterData, productData]
  );

  console.log("productData", productData);

  useEffect(() => {
    // Dynamically generate attributeArray based on form values
    const attributeArray = Object.keys(formikValues)
      .filter((key) => key !== "accessoryType") // Exclude accessoryType
      .map((key) => ({
        attributes: {
          $elemMatch: {
            attributeName: key,
            value: {
              $regex: formikValues[key],
            },
          },
        },
      }));

    const attributeArraySort = Object.keys(formikValues)
      .filter((key) => key !== "accessoryType") // Exclude accessoryType
      .map((key) => ({
        attributeName: key,
        value: formikValues[key],
      }));

    payload = {
      subcategoryName: formikValues.accessoryType,
      attributeArray,
      attributeArraySort
    };
    console.log(payload,"dsasdsadad")

    fn(payload);
  }, [formik.values, formikValues]);

  useEffect(() => {
    // Initialize filter values based on filterData
    const newFilterValues = filterData?.map((filter: any) => {
      const { attributeName, value } = filter;
      const newAttributeValues = value.map((val: any) => {
        return { value: val, isSelected: false };
      });

      return {
        attributeName,
        attributeValues: newAttributeValues,
      };
    });

    setCurrentFilterValues(newFilterValues);

    // Loop through each key in formik.values
    Object.keys(formikValues).forEach((key) => {
      const attributeName = key.toLowerCase();
      const attributeValue = formikValues[key];

      // Find the attribute with attributeName in filterValues
      const attribute = newFilterValues?.find(
        (attribute: any) =>
          attribute.attributeName.toLowerCase() === attributeName
      );

      if (typeof attributeValue === "string" && attribute !== undefined) {
        // Set all other attribute values' isSelected property to false
        attribute.attributeValues.forEach((val: any) => {
          val.isSelected = false;
        });

        const existingValueIndex = attribute.attributeValues?.findIndex(
          (val: any) => val.value === attributeValue
        );

        if (existingValueIndex !== -1) {
          // If the value is present, update isSelected to true
          attribute.attributeValues[existingValueIndex].isSelected = true;
        } else {
          // If the value is not present, push a new value into the array
          attribute.attributeValues.push({
            value: attributeValue,
            isSelected: true,
          });
        }
      }
      console.log("attribute", attribute);
    });
  }, [filterData]);

  const handleChange = (
    filterDataIndex: number,
    attributeValuesIndex: number
  ) => {
    // Create a copy of currentFilterValues
    const updatedFilterValues = [...currentFilterValues];

    // Toggle the isSelected property
    const selectedValue =
      updatedFilterValues[filterDataIndex].attributeValues[
        attributeValuesIndex
      ];
    selectedValue.isSelected = !selectedValue.isSelected;

    //Filter the updatedValue array to include only selected attribute values
    const selectedAttributeValues = updatedFilterValues.flatMap((attribute) =>
      attribute.attributeValues
        .filter((value: any) => value.isSelected)
        .map((value: any) => ({
          attributeName: attribute.attributeName,
          value: value.value,
        }))
    );

    // Create the attributeArray by mapping selected attribute values
    const attributeArray = selectedAttributeValues.map((value) => ({
      attributes: {
        $elemMatch: {
          attributeName: value.attributeName,
          value: {
            $regex: value.value,
          },
        },
      },
    }));

    const attributeArraySort = selectedAttributeValues.map((value) => ({
      attributeName: value?.attributeName,
      value:value?.value,
    }));

    // Create the payload object
    payload = {
      subcategoryName: formikValues.accessoryType,
      attributeArray,
      attributeArraySort
    };

    fn(payload, false);

    // Update the state to trigger re-render
    setCurrentFilterValues(updatedFilterValues);
  };

  return (
    <div className="w-100 mt-15">
      <div className="pb-8 pb-lg-10">
        <h2
          className="fw-bolder text-dark d-flex justify-content-center"
          style={{ fontSize: "27px" }}
        >
          These are the products more suitable for you...
        </h2>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <StepperccessoryFilters
            handleChange={handleChange}
            filterValues={currentFilterValues}
          />
        </div>
        <div className="col-lg-9">
          <div className="row">
            {loading ? (
              <StepperSkeleton numberOfInput={5} />
            ) : (
              productData?.map((product: any, index: number) => {
                return (
                  <div key={index} className="col-lg-6 mb-10">
                    <div
                      className="card p-5"
                      style={{ border: "1px solid #e1e3ea" }}
                    >
                      <div style={{ width: "100%", height: "300px" }}>
                        <img
                          className="img-fluid"
                          alt="Product image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          src={
                            product?.acccessoryImages?.["0"]
                              ? process.env.REACT_APP_API_URL_FOR_IMAGE +
                                product?.acccessoryImages?.["0"]
                              : "https://aus.hauper.info/img/product_images/no_img.png"
                          }
                        />
                      </div>
                      <div className="text-center">
                        <h3
                          style={{ fontSize: "28px" }}
                          className="mt-7 fw-bolder"
                        >
                          {product?.accessoryName}
                        </h3>
                        {product?.attributes?.map((attribute: any) => (
                          <p className="mt-7" style={{ fontSize: "20px" }}>
                            <strong>{attribute?.attributeName}: </strong>
                            {attribute?.value}
                          </p>
                        ))}

                        <h5 style={{ fontSize: "18px" }} className="mt-5">
                          <strong>Product Type: </strong>
                          {product?.subcategory?.subcategoryName}
                        </h5>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompatibleProducts;
