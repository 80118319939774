import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { TOptionType } from "../../../types/CustomSelect";
import Multiselect from "multiselect-react-dropdown";
import CustomArrow from "../../../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../../../utils/CustomStyleComponent/CrossButtonComponent";
import { toast } from "react-toastify";
import { TUnitFilterProps } from "../../../types/Filters/UnitFilter";
import { getUnit } from "../../../app/request/unitRequest";
import "../../../app/pages/Units/UnitsForm.css";
import CustomTooltip from "../../../utils/CustomStyleComponent/TooltipComponent";

const UnitFilter = ({onUnitFilterChange,currentUnitFilter}: TUnitFilterProps) => {
  const [allUnits, setAllUnits] = useState([]);

  const style = {
    multiSelectContainer: {
      minheight: "44px",

      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontWeight: "500",
      minwidth: "40px",
      maxwidth: "40px",
      height: "44px",
      display: "flex",
      alignItems: "center",
      width: "350px",

      // styles for the search wrapper
    },
    chips: {
      // To change css chips(Selected options)
      marginBottom: "0",
    },
  };

  useEffect(() => {
    const fn = async () => {
      try {
        const response = await getUnit();
        if (response.data.status) {
          setAllUnits(response.data.data.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
      }
    };
    fn();
  }, []);

  const handleUnitFilter = useCallback(
    (data: any) => {
     onUnitFilterChange(data)
    },
    []
  );

  return (
    <Multiselect
      options={allUnits}
      displayValue="name"
      placeholder="Select Units"
      hidePlaceholder={true}
      selectionLimit={3}
      style={style}
      showArrow={true}
      customArrow={<CustomArrow />}
      customCloseIcon={<CustomCrossButton />}
      avoidHighlightFirstOption={true}
      showCheckbox={true}
      onSelect={handleUnitFilter}
      onRemove={handleUnitFilter}
      selectedValueDecorator={(value: string, option: any) => {
        return <CustomTooltip value={value} option={option} />;
      }}
    />
  );
};

export default React.memo(UnitFilter);