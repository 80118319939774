import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TCategoryState = {
  data: [];
  perPage: number;
  currentPage: number;
  totalPage: number;
  searchTerm: string;
  loading: boolean;
  sortBy: string;
  sortColumn: string;
  rerender1:boolean
};

const initialCategoryState: TCategoryState = {
  data: [],
  perPage: 10,
  currentPage: 1,
  totalPage: 1,
  searchTerm: "",
  loading: false,
  sortBy: "desc",
  sortColumn: "",
  rerender1: false
};

export const categorySlice = createSlice({
  name: "category",
  initialState: initialCategoryState,
  reducers: {
    setCategory: (state: TCategoryState, { payload }: PayloadAction<[]>) => {
      state.data = payload;
    },
    setPerPage: (state: TCategoryState, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
    setCurrentPage: (
      state: TCategoryState,
      { payload }: PayloadAction<number>
    ) => {
      state.currentPage = payload;
    },
    setTotalPage: (
      state: TCategoryState,
      { payload }: PayloadAction<number>
    ) => {
        state.totalPage = payload
    },
    setSearchTerm: (
      state: TCategoryState,
      { payload }: PayloadAction<string>
    ) => {
      state.searchTerm = payload;
    },
    setSortBy: (state: TCategoryState, { payload }: PayloadAction<string>) => {
      state.sortBy = payload;
    },
    setSortColumn: (
      state: TCategoryState,
      { payload }: PayloadAction<string>
    ) => {
      state.sortColumn = payload;
      state.sortBy = "desc";
    },
    setLoading: (
      state: TCategoryState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.loading = payload;
    },
    setRerender: (state:TCategoryState, {payload}: PayloadAction<boolean>) => {
        state.rerender1 = payload
     },
  },
});

export const {
  setCategory,
  setPerPage,
  setCurrentPage,
  setSortColumn,
  setSortBy,
  setTotalPage,
  setSearchTerm,
  setLoading,
  setRerender,
} = categorySlice.actions;
export default categorySlice.reducer;
