import { useCallback, useEffect, useState } from "react";
import TableView from "../components/TableView";
import HelpTableAction from "../generatedTableActions/HelpTableAction";
import Button from "../components/Button";
import Badge from "../components/Badge";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Multiselect from "multiselect-react-dropdown";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setHelp,
  setSearchTerm,
} from "../generatedSlice/helpSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { deleteHelp, getAllHelp } from "../generatedRequest/help";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import HelpList from "../generatedTableList/HelpList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import AllModules from "../permission.json";
import { useAuth } from "../app/modules/auth";
import Card from "../components/Card";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toAbsoluteUrl } from "../_metronic/helpers";
import { useDelete } from "../customHooks/useDelete/useDelete";
import ConfirmationPrompt from "../components/ConfirmationPrompt";

const HelpPage = () => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const [currentModule, setCurrentModule] = useState<any>({});
  const [queryParameters] = useSearchParams();
  const moduleQuery = queryParameters.get("moduleName") || "";

  const handleAfterDelete = (isSuccessful: boolean, response: any) => {
    if (isSuccessful) {
      toast.success("Record Deleted");
      window.location.reload();
    }
  };

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteHelp,
    true,
    handleAfterDelete
  );

  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };

  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    helploading,
    sortBy,
    sortColumn,
  } = useSelector((state: RootState) => state.help);

  useEffect(() => {
    const fn = async () => {
      dispatch(setLoading(true));
      try {
        const response = await getAllHelp();
        if (response.data.status) {
          dispatch(setHelp(response.data.data.data));
          if (moduleQuery) {
            setCurrentModule(
              response?.data?.data?.data?.filter(
                (module: any) => module?.moduleName == moduleQuery
              )?.[`0`]
            );
          }
        } else {
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
      }
    };
    fn();
  }, []);

  const navigate = useNavigate();

  if (loading) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  console.log(currentModule, data, moduleQuery);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Help</h2>
        {currentUser?.isAdmin ? (
          <Button
            variant="primary"
            size="medium"
            handleButtonClick={() => navigate("add")}
          >
            Add Help
          </Button>
        ) : null}
      </div>
      <div>
        <Card>
          <div className="card-body p-0">
            <div className="d-flex flex-column justify-content-center"></div>
            <div className="col-lg-4 p-3">
              <label className="col-lg-6 col-form-label required fw-bold fs-7">
                Select Module
              </label>
              <div>
                <Multiselect
                  options={data}
                  style={style}
                  // disable={true}
                  selectionLimit={1}
                  selectedValues={data.filter(
                    (module: any) => module?.moduleName == moduleQuery
                  )}
                  customArrow={<CustomArrow />}
                  customCloseIcon={<CustomCrossButton />}
                  displayValue="moduleName"
                  placeholder="Select Module You Need Help"
                  // selectedValues={}
                  onSelect={async (e) => {
                    if (e?.[0]) {
                      setCurrentModule(e?.[0]);
                    }
                  }}
                  onRemove={async (e) => {
                    setCurrentModule({});
                  }}
                  selectedValueDecorator={(value: string, option: any) => {
                    return <CustomTooltip value={value} option={option} />;
                  }}
                />
              </div>
            </div>
            <div className="col-lg-12 p-3">
              <h2>
                {currentModule?.moduleName || "Global Accessory Finder"}

                {currentModule && Object.keys(currentModule).length > 0 && (
                  <>
                    <span className="cursor-pointer ms-3">
                      <img
                        alt="Edit"
                        src={toAbsoluteUrl(
                          "/media/icons/duotune/general/gen055.svg"
                        )}
                        onClick={() =>
                          navigate(`/helps/edit?q=${currentModule?._id}`)
                        }
                      />
                    </span>
                    <span className="cursor-pointer ms-3">
                      <img
                        alt="Delete"
                        src={toAbsoluteUrl(
                          "/media/icons/duotune/general/gen064.svg"
                        )}
                        onClick={() => onDelete(currentModule?._id)}
                      />
                    </span>
                  </>
                )}
              </h2>
            </div>

            <div className="col-lg-12 p-3">
              <p>
                {currentModule?.descriptions ||
                  "  Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quas nihil magni dolorum voluptas. Dolorum deserunt quasi nihil consectetur neque natus illo incidunt praesentium ipsa fugit vel veritatis, optio ea doloremque, ducimus consequatur cumque. Repellendus at numquam exercitationem laboriosam suscipit. Corporis autem facilis ducimus unde sit cum eligendi incidunt quos."}
              </p>
            </div>
            <div className="col-lg-12 p-3 d-flex flex-column">
              {currentModule?.faqs?.map((faq: any) => {
                const Images = faq.relevantImages;
                const Videos = faq.relevantVideos;
                const Docs = faq.relevantDocs;
                return (
                  <>
                    <div className=" d-flex flex-column"></div>
                    <h3>{faq?.question}</h3>
                    <p>{faq?.answer}</p>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="col-lg-3">
                        <h3>{"Images"}</h3>
                        <Slider
                          dots={true}
                          dotsClass="slick-dots slick-thumb"
                          infinite={false}
                          speed={500}
                          rows={1}
                          slidesToShow={2}
                          slidesToScroll={1}
                        >
                          {Images?.map((Image: any, index: number) => (
                            <a href={Image?.link} key={index}>
                              <img
                                src={Image?.link}
                                style={{ height: "200px", objectFit: "cover" }}
                              />
                            </a>
                          ))}
                        </Slider>
                      </div>
                      <div className="col-lg-3">
                        <h3>{"Videos"}</h3>
                        <Slider
                          dots={true}
                          infinite={false}
                          speed={500}
                          rows={1}
                          slidesToShow={2}
                          slidesToScroll={1}
                        >
                          {Videos?.map((Video: any, index: number) => (
                            <a target="_blank" href={Video?.link} key={index}>
                              <img
                                src={
                                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4uWeO85YYWotlEwyybTBfENySVpgsAC15PQ&s"
                                }
                              />
                            </a>
                          ))}
                        </Slider>
                      </div>
                      <div className="col-lg-3">
                        <h3>{"Docs"}</h3>
                        <Slider
                          dots={true}
                          infinite={false}
                          speed={500}
                          rows={1}
                          slidesToShow={2}
                          slidesToScroll={1}
                        >
                          {Docs?.map((Doc: any, index: number) => (
                            <a target="_blank" href={Doc?.link} key={index}>
                              <img
                                src={
                                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJx-swg6ALAfwNKv2W8L5CUxzMzoysNUOBZg&s"
                                }
                              />
                            </a>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Card>
        {showPrompt && (
          <ConfirmationPrompt
            title={"Delete Role"}
            message={"Are you sure to delete this section of help"}
            onConfirm={deleteRecord}
            onCancel={onCancel}
            isloading={loading}
          />
        )}
      </div>
    </>
  );
};

export default HelpPage;
