/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import clsx from "clsx";
import { useNavigate, useRoutes, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { toast } from "react-toastify";
import { Verify2FA, resetPassword } from "../core/_requests";
import { setCodeSchema } from "../../../../utils/validationSchema/codeSchema";
import { useAuth } from "../core/Auth";

const initialValues = {
  code: '',
};

export function VerifyCode() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("Enter Code");
  const { saveAuth } = useAuth();
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: setCodeSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      setLoading(true);
      try {
        const payload = {
          code: values.code,
        };
        const queryParams = {
          userid: queryParameters.get("userid"),
        };

        const response = await Verify2FA(
          `${payload.code}`,
          queryParams.userid as any
        );
        if (response.data.status) {
          toast.success(response.data.message);
          console.log(response.data);
          saveAuth(response.data)

        } else {
          toast.error(response.data.message);
        }
        setHasErrors(!response.data.status);
        setMessage(response.data.message);
        !response.data.status &&
          response.data.error &&
          setErrors(response.data.error);
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);


  console.log(formik)

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="text-center mb-11">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">Verify Code</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {hasErrors === true ? (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{message}</div>
        </div>
      ) : (
        <div className="mb-10 bg-light-info p-8 rounded">
          <div className="text-info">{message}</div>
        </div>
      )}
      {/* start::Form group */}
      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">Code</label>
          <div className="position-relative mb-3">
            <input
              type="text"
              placeholder="Enter code"
              autoComplete="off"
              disabled={formik.isSubmitting}
              {...formik.getFieldProps("code")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid": formik.touched.code && formik.errors.code,
                },
                {
                  "is-valid": formik.touched.code && formik.errors.code,
                }
              )}
            />
            {formik.touched.code && formik.errors.code && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.code}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
             <p className="fw-semibold text-info">Check your email for the verification code</p>
        
          </div>
          {/* end::Meter */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-danger w-100 mb-5"
          >
            Cancel
          </button>
        </Link>
      </div>

      {/* end::Form group */}
    </form>
  );
}
