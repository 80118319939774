/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import TableView from "../../../components/TableView";
import Badge from "../../../components/Badge";
import TableSkeleton from "../../../components/TableSkeleton";
import { DELETE_USER_URL, getUserList } from "../../request/userRequest";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import UserTableActions from "../../../components/ActionCells/UserTableActions";
import {
  setCurrentPage,
  setPerPage,
  setTotalPage,
  setRerender,
  setSearchTerm,
} from "../../../redux/slices/userSlice";
import EmptyState from "../../../components/EmptyState";
import useSearchApi from "../../../customHooks/useSearchApi";
import { toast } from "react-toastify";
import { TColumn, ThandleAfterSearchData } from "../../../types/Module/Users & Permissons/UserManagement";

const columns:TColumn[] = [
  {
    name: "name",
    renderHeader: () => (
      <>
        <b>Full Name</b>
      </>
    ),
    renderCell: (person) => (
      <span>
        <span>{person.overview.firstName}</span>{" "}
        <span>{person.overview.lastName}</span>
      </span>
    ),
  },
  {
    name: "email",
    renderHeader: () => (
      <>
        <b>Email</b>
      </>
    ),
    renderCell: (person) => <span>{person.overview.email}</span>,
  },
  {
    name: "Status",
    renderHeader: () => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (person) => (
      <Badge
        variant={person.overview.status == "active" ? "success" : "danger"}
        className="fs-7 fw-bolder"
      >
        {person.overview.status}
      </Badge>
    ),
  },
  {
    name: "Last Login",
    renderHeader: () => (
      <>
        <b>Last Login</b>
      </>
    ),
    renderCell: (person) => <span>{person.lastLoginDate || "-"}</span>,
  },
  {
    name: "Updated At",
    renderHeader: () => (
      <>
        <b>Updated At</b>
      </>
    ),
    renderCell: (person) => <span>{person.updatedAt}</span>,
  },
  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (person) => <UserTableActions person={person} />,
  },
];

const UserManagementPage = () => {
  const { perPage, currentPage, totalPage, rerender1, searchTerm } =
    useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [personData, setPersonData] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [initload,setInitload] = useState(true)

  //it will call search dummy api when user types anything in search
  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}`;
      setLoading(true)
      try {
        const res = await getUserList(searchQuery);
        if (res.data.status) {
          setPersonData(res.data.data.data);
          dispatch(setTotalPage(Math.ceil(res.data.data.totalCount / perPage)));
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error("Network error");
      }
      finally{
        setLoading(false)
        setInitload(false)
      }
    };
    fn();
  }, [rerender1, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(''));
    };
  }, []);


  function handleAfterSearch(isSuceess: boolean, data: ThandleAfterSearchData, value: number) {
    if (isSuceess) {
      setPersonData(data.data.data.data);
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }

  const { doSearch, searchLoading } = useSearchApi(
    getUserList,
    handleAfterSearch
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}`;
      doSearch(searchQuery, value);
    },
    [dispatch, currentPage, searchTerm, doSearch]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch]
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch, dispatch]
  );

  if (loading && initload) {
    return <TableSkeleton rows={5} columns={6} />;
  }

  return (
    <div className="card">
      <div>
        <TableView
          rows={personData}
          columns={columns}
          showPagination={true}
          showSearch={true}
          showPerPage={true}
          showFilter={false}
          paginationData={{ perPage, currentPage, totalPage }}
          onPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          searchPlaceholder={"Search Users"}
          handleSearchParams={handleSearchParams}
          searchComponent={<EmptyState                      
            title="Sorry"
            message="No Users Found"
            buttonTitle="Reset"
            image="/media/misc/empty-State.svg"
          />}
          searchLoading={searchLoading}
        />
   </div>
    </div>
  );
};

const UserList: FC = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(`/adduser`);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>Users & Permissions</h2>
        <Button
          variant="primary"
          handleButtonClick={handleButtonClick}
          size="medium"
        >
          Add User
        </Button>
      </div>

      <UserManagementPage />
    </>
  );
};

export default UserList;
