import React from 'react'
import { TButtonProps } from '../../types/Button';


const Button = ({variant="primary", className, size,handleButtonClick, ...props}: TButtonProps) => {
    let _className = "";
    switch(variant){
        case"primary": _className = " btn-primary "
        break;
        case"secondary": _className = " btn-body "
        break;
        case"danger": _className = " btn-danger "
        break;
        case"success": _className = " btn-success "
        break;
        case"light-primary": _className = " btn-light-primary "
        break;
        case"light": _className = " btn-light "
        break;

    }
    switch(size){
        case"small": _className+= " btn-sm "
        break;
        case"medium": _className+= " btn-md "
        break;
        case"large": _className+= " btn-lg "
        break;

    }
    return (
            <button
               {...props}
                className={'btn cursor-pointer' + _className + className}
                onClick={handleButtonClick}
            >
                {props.children}
            </button>
        
    )
}

export default Button