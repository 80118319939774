import Multiselect from "multiselect-react-dropdown";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import DropBox from "../components/DropBox";
import ImageGrid from "../components/ImageGrid";
import {
  AddDeleteImage,
  EditDeleteImage,
  quizImageUpload,
} from "../generatedRequest/quiz";
import { toast } from "react-toastify";
import "./Help.css";
import { useState } from "react";
import { link } from "fs/promises";
import { helpImageUpload } from "../generatedRequest/help";

type TQuizFormProp = {
  errors: any;
  isSubmitting: boolean;
  touched: any;
  setFieldTouched: any;
  getFieldProps: Function;
  setFieldValue: Function;
  values: any;
  isEdit: boolean;
  isloading: boolean;
  allModules: any[];
};

const HelpForm = ({
  errors,
  isEdit,
  isloading,
  isSubmitting,
  touched,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  allModules,
}: TQuizFormProp) => {
  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };
  const navigate = useNavigate();

  const [scrollPosition, setScrollPosition] = useState(0);

  console.log(values);

  // Scroll the container to the left or right based on the direction
  const scrollCarousel = (direction: any, index: number) => {
    const container = document.querySelector(`#auto-scroll-container-${index}`);
    if (container) {
      const cardWidth = 210; // Adjust the card width based on your design
      const containerWidth = container.clientWidth;
      const maxScroll = container.scrollWidth - containerWidth;
      let newPosition = scrollPosition;
      if (direction === "left") {
        newPosition = Math.max(0, scrollPosition - cardWidth);
      } else if (direction === "right") {
        const numCards = container.querySelectorAll(".card")?.length;
        const numVisibleCards = Math.floor(containerWidth / cardWidth);
        if (numCards <= numVisibleCards) {
          return; // Don't scroll right if there are less than or equal to visible cards
        }
        newPosition = Math.min(maxScroll, scrollPosition + cardWidth);
      }
      container.scroll({
        left: newPosition,
        behavior: "smooth",
      });
      setScrollPosition(newPosition);
    }
  };

  const handleDelete = async (
    faqIndex: number,
    mediaIndex: number,
    mediaType: string,
    type: string
  ) => {
    if (type === "upload") {
      try {
        const { _id, faqs } = values;
        const res = isEdit
          ? await EditDeleteImage(
              _id,
              faqs[faqIndex]?.[`${mediaType}`][mediaIndex]?.link
            )
          : await AddDeleteImage(
              faqs[faqIndex]?.[`${mediaType}`][mediaIndex]?.link
            );

        if (res.data.status) {
          const updatedfaqs = [...faqs];
          const updatedMedia = [...updatedfaqs[faqIndex][`${mediaType}`]];
          updatedMedia[mediaIndex] = {
            ...updatedMedia[mediaIndex],
            link: "",
            type: "",
          };
          updatedfaqs[faqIndex][`${mediaType}`] = updatedMedia;
          setFieldValue("faqs", updatedfaqs);
          toast.success(
            `${`${type}`.replace("relevant", "")} deleted succcessfully`
          );
        } else {
          toast.error(res.data.message);
        }
      } catch (error: any) {
        console.error("Error during delete operation:", error);
        toast.error(
          error.response?.data?.message || error.message || "Unknown error"
        );
      } finally {
      }
    } else if (type === "link") {
      try {
        const { _id, faqs } = values;
        const updatedfaqs = [...faqs];
        const updatedMedia = [...updatedfaqs[faqIndex][`${mediaType}`]];
        updatedMedia[mediaIndex] = {
          ...updatedMedia[mediaIndex],
          link: "",
          type: "",
        };
        updatedfaqs[faqIndex][`${mediaType}`] = updatedMedia;
        setFieldValue("faqs", updatedfaqs);
        toast.success(
          `${`${type}`.replace("relevant", "")} deleted succcessfully`
        );
      } catch (error: any) {
        console.error("Error during delete operation:", error);
        toast.error(
          error.response?.data?.message || error.message || "Unknown error"
        );
      } finally {
      }
    }
  };

  return (
    <div className="container ms-1">
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-1 cursor-pointer">
              <div className="card-title m-0">
                <div
                  role={"button"}
                  className="btn btn-sm btn-light me-5"
                  onClick={() => navigate("/helps")}
                >
                  <i className="fa-solid fa-arrow-left fs-2"></i>
                </div>
                <h3 className="fw-bolder m-0">
                  {isEdit ? "Edit" : "Add"} Help
                </h3>
              </div>
            </div>
            <div className="card-body p-9">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Module Name
                  </label>
                  <div>
                    <Multiselect
                      options={allModules}
                      style={style}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      selectionLimit={1}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="moduleName"
                      placeholder="Select Module"
                      selectedValues={allModules.filter(
                        (module: any) =>
                          module?.moduleName == values?.moduleName
                      )}
                      onSelect={async (e) => {
                        await setFieldValue("moduleName", e?.[0]?.moduleName);
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("moduleName", "");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {touched?.moduleName && errors?.moduleName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.moduleName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body p-9">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Description
                  </label>
                  <div>
                    <div className="col-lg-12 mb-4">
                      <textarea
                        rows={3}
                        disabled={isloading}
                        autoComplete="off"
                        value={values.descriptions}
                        placeholder="Please Enter Description"
                        onChange={async (e: any) => {
                          await setFieldValue("descriptions", e?.target.value);
                        }}
                        className="form-control bg-transparent"
                      />
                      {touched?.descriptions && errors?.descriptions && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert">{errors?.descriptions}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {touched?.descriptions && errors?.moduleName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.moduleName}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex mb-10  justify-content-between align-items-center">
        <div>
          <h4>Total Faqs : {values?.faqs?.length}</h4>
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            let data = {
              question: "",
              answer: "",
              relevantImages: [],
              relevantVideos: [],
              relevantDocs: [],
            };
            let newArray = values?.faqs;
            newArray.push(data);
            setFieldValue("faqs", newArray);
          }}
        >
          {"Add New Faq"}
        </button>
      </div>

      {values?.faqs?.map((faq: any, index: number) => {
        const questionvariable = `faqs[${index}].question`;
        const answervariable = `faqs[${index}].answer`;

        return (
          <div className="card mb-5 mb-xl-10">
            <div className="row p-2">
              <div className="col-lg-4">
                <label className="col-lg-6 col-form-label required fw-bold fs-7">
                  Question
                </label>
                <div className="col-lg-12 mb-4">
                  <textarea
                    rows={3}
                    disabled={isloading}
                    autoComplete="off"
                    placeholder="Please Enter your question"
                    {...getFieldProps(`${questionvariable}`)}
                    className="form-control bg-transparent"
                  />
                  {touched?.faqs?.[index]?.question &&
                    errors?.faqs?.[index]?.question && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {errors?.faq?.[index]?.question}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <label className="col-lg-6 col-form-label required fw-bold fs-7">
                  Answer
                </label>
                <div className="col-lg-12 mb-4">
                  <textarea
                    rows={3}
                    disabled={isloading}
                    autoComplete="off"
                    placeholder="Please Enter the Answer for question"
                    {...getFieldProps(`${answervariable}`)}
                    className="form-control bg-transparent"
                  />
                  {touched?.faqs?.[index]?.answer &&
                    errors?.faqs?.[index]?.answer && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {errors?.questions?.[index]?.answer}
                          </span>
                        </div>
                      </div>
                    )}
                </div>

                <div className="d-flex mt-5 justify-content-between align-items-center">
                  <button
                    className="btn btn-sm btn-secondary m-2"
                    onClick={() => {
                      let prevarray = values?.faqs;
                      let elementdelted = prevarray?.splice(index, 1);
                      setFieldValue("faqs", prevarray);
                    }}
                  >
                    {"Delete FAQ"}
                  </button>
                  <button
                    className="btn btn-sm btn-primary m-2"
                    onClick={() => {
                      let data = {
                        type: "",
                        link: "",
                        mediaType: "relevantImages",
                      };
                      let newArray = values?.faqs;
                      newArray[index]?.relevantImages?.push(data);
                      setFieldValue("faqs", newArray);
                    }}
                  >
                    {"Add Image"}
                  </button>
                  <button
                    className="btn btn-sm btn-primary m-2"
                    onClick={() => {
                      let data = {
                        type: "",
                        link: "",
                        mediaType: "relevantDocs",
                      };
                      let newArray = values?.faqs;
                      newArray[index]?.relevantDocs?.push(data);
                      setFieldValue("faqs", newArray);
                    }}
                  >
                    {"Add Doc"}
                  </button>
                  <button
                    className="btn btn-sm btn-primary m-2"
                    onClick={() => {
                      let data = {
                        type: "link",
                        link: "",
                        mediaType: "relevantVideos",
                      };
                      let newArray = values?.faqs;
                      newArray[index]?.relevantVideos?.push(data);
                      setFieldValue("faqs", newArray);
                    }}
                  >
                    {"Add Video"}
                  </button>
                </div>
              </div>

              <div className="col-lg-8 mt-6 border p-8">
                <div className="d-flex flex-column">
                  {/* Left arrow button */}
                  <div
                    className={clsx("arrow-button left", {
                      disabled: faq?.relevantImages?.length <= 4,
                    })}
                    onClick={() => scrollCarousel("left", index)}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div
                    id={`auto-scroll-container-${index}`}
                    className="d-flex overflow-auto border auto-scroll-container"
                  >
                    {faq?.relevantImages?.map((data: any, subIndex: number) => {
                      const questionvariableimage = `faqs[${index}].relevantImages[${subIndex}]`;

                      const image =
                        values?.faqs?.[index]?.relevantImages?.[subIndex]?.link;

                      const errorsImage =
                        touched?.faqs?.[index]?.relevantImages?.[subIndex]
                          ?.link &&
                        errors?.faqs?.[index]?.relevantImages?.[subIndex]?.link;

                      return (
                        <div className="card p-5 w-200px bg-white h-300px shadow me-2">
                          <div className="d-flex justify-content-end">
                            <div
                              onClick={() => {
                                let prevarray = values?.faqs;
                                let elementdelted = prevarray[
                                  index
                                ].relevantImages.splice(subIndex, 1);
                                setFieldValue("faqs", prevarray);
                              }}
                            >
                              <i className="fa fa-trash cursor-pointer"></i>
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              setFieldTouched(
                                `faqs.[${index}].relevantImages[${subIndex}].link`
                              )
                            }
                          >
                            <DropBox
                              label="Upload Image File"
                              onChange={async (e: any[]) => {
                                const res = await helpImageUpload(e);

                                if (res.data.status) {
                                  const currentUploadedImage = {
                                    ...res.data?.data?.[0],
                                    mediaType: data.mediaType,
                                  };

                                  setFieldValue(
                                    `${questionvariableimage}`,
                                    currentUploadedImage
                                  );
                                } else {
                                  toast.error("Something went wrong");
                                }
                              }}
                            ></DropBox>

                            <ImageGrid
                              images={[image]}
                              handleDelete={() =>
                                handleDelete(
                                  index,
                                  subIndex,
                                  data.mediaType,
                                  data.type
                                )
                              }
                            ></ImageGrid>
                            {errorsImage &&
                              touched?.faqs?.[index]?.relevantImages?.[subIndex]
                                ?.image && (
                                <div className="fv-plugins-message-container text-center">
                                  <div
                                    className="fv-help-block"
                                    style={{ marginTop: "-15px" }}
                                  >
                                    <span role="alert">{errorsImage}</span>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div>
                            <input
                              className="form-control bg-transparent"
                              value={image}
                              placeholder="Paste Image Link"
                              onChange={async (e: any) => {
                                let ImageViaLink = {
                                  link: e.target.value,
                                  type: "link",
                                  mediaType: data.mediaType,
                                };
                                await setFieldValue(
                                  `${questionvariableimage}`,
                                  ImageViaLink
                                );
                              }}
                            />
                            {/* {errorsTitle &&
                            touched?.questions?.[index]?.options?.[subIndex]
                              ?.value && (
                              <div className="fv-plugins-message-container text-center">
                                <div className="fv-help-block">
                                  <span role="alert">{errorsTitle}</span>
                                </div>
                              </div>
                            )} */}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* Right arrow button */}
                  <div
                    className={clsx("arrow-button right", {
                      disabled: faq?.relevantImages?.length <= 4,
                    })}
                    onClick={() => scrollCarousel("right", index)}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </div>

                  <div
                    className={clsx("arrow-button left", {
                      disabled: faq?.relevantImages?.length <= 4,
                    })}
                    onClick={() => scrollCarousel("left", index)}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div
                    id={`auto-scroll-container-${index}`}
                    className="d-flex overflow-auto border auto-scroll-container"
                  >
                    {faq?.relevantVideos?.map((data: any, subIndex: number) => {
                      const questionvariablevideo = `faqs[${index}].relevantVideos[${subIndex}].link`;
                      return (
                        <div className="card p-5 w-200px bg-white h-300px shadow me-2">
                          <div className="d-flex justify-content-end align-items-center">
                            <div
                              onClick={() => {
                                let prevarray = values?.faqs;
                                let elementdelted = prevarray[
                                  index
                                ].relevantVideos.splice(subIndex, 1);
                                setFieldValue("faqs", prevarray);
                              }}
                            >
                              {/* <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon-1.2x cursor-pointer"
                            /> */}
                              <i className="fa fa-trash cursor-pointer"></i>
                            </div>
                          </div>

                          <div>
                            <input
                              className="form-control bg-transparent"
                              {...getFieldProps(`${questionvariablevideo}`)}
                              // onChange={async (e: any) => {
                              //   let VideoViaLink = {
                              //     link: e.target.value,
                              //     type: "link",
                              //     mediaType: data.mediaType,
                              //   };
                              //   await setFieldValue(
                              //     `${questionvariablevideo}`,
                              //     VideoViaLink
                              //   );
                              // }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* Right arrow button */}
                  <div
                    className={clsx("arrow-button right", {
                      disabled: faq?.relevantVideos?.length <= 4,
                    })}
                    onClick={() => scrollCarousel("right", index)}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </div>

                  <div
                    className={clsx("arrow-button left", {
                      disabled: faq?.relevantDocs?.length <= 4,
                    })}
                    onClick={() => scrollCarousel("left", index)}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </div>
                  <div
                    id={`auto-scroll-container-${index}`}
                    className="d-flex overflow-auto border auto-scroll-container"
                  >
                    {faq?.relevantDocs?.map((data: any, subIndex: number) => {
                      const questionvariabledocs = `faqs[${index}].relevantDocs[${subIndex}]`;

                      const iconForDoc = values?.faqs?.[index]?.relevantDocs?.[
                        subIndex
                      ]?.link
                        ? "/media/icons/duotune/general/docs.png"
                        : "";

                      const doc =
                        values?.faqs?.[index]?.relevantDocs?.[subIndex]?.link;

                      const errorsdoc =
                        touched?.faqs?.[index]?.relevantDocs?.[subIndex]
                          ?.link &&
                        errors?.faqs?.[index]?.relevantDocs?.[subIndex]?.link;

                      return (
                        <div className="card p-5 w-200px bg-white h-300px shadow me-2">
                          <div className="d-flex justify-content-end">
                            <div
                              onClick={() => {
                                let prevarray = values?.faqs;
                                let elementdelted = prevarray[
                                  index
                                ].relevantDocs.splice(subIndex, 1);
                                setFieldValue("faqs", prevarray);
                              }}
                            >
                              {/* <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon-1.2x cursor-pointer"
                            /> */}
                              <i className="fa fa-trash cursor-pointer"></i>
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              setFieldTouched(
                                `faqs.[${index}].relevantDoc[${subIndex}].link`
                              )
                            }
                          >
                            <DropBox
                              label="Upload Doc file"
                              onChange={async (e: any[]) => {
                                const res = await helpImageUpload(e);

                                if (res.data.status) {
                                  const currentUploadedDoc = {
                                    ...res.data?.data?.[0],
                                    mediaType: data.mediaType,
                                  };

                                  setFieldValue(
                                    `${questionvariabledocs}`,
                                    currentUploadedDoc
                                  );
                                } else {
                                  toast.error("Something went wrong");
                                }
                              }}
                            ></DropBox>

                            <ImageGrid
                              images={[iconForDoc]}
                              handleDelete={() =>
                                handleDelete(
                                  index,
                                  subIndex,
                                  data.mediaType,
                                  data.type
                                )
                              }
                            ></ImageGrid>
                            {errorsdoc &&
                              touched?.faqs?.[index]?.relevantDocs?.[subIndex]
                                ?.link && (
                                <div className="fv-plugins-message-container text-center">
                                  <div
                                    className="fv-help-block"
                                    style={{ marginTop: "-15px" }}
                                  >
                                    <span role="alert">{errorsdoc}</span>
                                  </div>
                                </div>
                              )}
                          </div>

                          <div>
                            <input
                              className="form-control bg-transparent"
                              value={doc}
                              placeholder="Paste Doc Link"
                              onChange={async (e: any) => {
                                let DocViaLink = {
                                  link: e.target.value,
                                  type: "link",
                                  mediaType: data.mediaType,
                                };
                                await setFieldValue(
                                  `${questionvariabledocs}`,
                                  DocViaLink
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* Right arrow button */}
                  <div
                    className={clsx("arrow-button right", {
                      disabled: faq?.relevantDocs?.length <= 4,
                    })}
                    onClick={() => scrollCarousel("right", index)}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HelpForm;
