import React from "react";
import ProductFormModal from "../../generatedFormModal/ProductFormModal";

const AddProduct = () => {
  return (
    <div>
      <ProductFormModal isEdit={false} />
    </div>
  );
};

export default AddProduct;
