import React, { useState } from "react";
import * as XLSX from "xlsx";
import { toAbsoluteUrl } from "../../_metronic/helpers";

const ExcelDownloadComponent = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const generateExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsData = [
      ["modelNumber", "Link", "Price" , "Sku"],
      [
        "ACP076US",
        "https://www.jbhifi.com.au/products/targus-4-port-usb-3-0-hub?queryID=ddd3439094e5104f30f201672a590182&objectID=648144",
        "100",
        "DSU100US"
      ],
      [
        "ACP110US",
        "https://www.jbhifi.com.au/products/targus-dual-travel-usb-3-0-usb-c-dock?queryID=e5819fa7fb74fb0f49a0935c21d41a17&objectID=441217",
        "",
        "",
      ],
      [
        "ACP119US",
        "https://www.jbhifi.com.au/products/targus-chill-mat-with-4-port-hub?queryID=7e5a676578ec1aaeec1700985176bd78&objectID=343703",
        "",
        "",
      ],
    ];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    // Adjust the column width
    ws['!cols'] = [
        { wch: 20 },
        { wch: 120 },
        { wch: 20 },
        { wch: 20 },
      ];
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Configuration_upload_template.xlsx";
    link.click();
    URL.revokeObjectURL(url);
  };
  const tooltipStyle: React.CSSProperties = {
    background: "#000",
    padding: "2px 10px",
    position: "absolute",
    top: "-25px",
    color: "#fff",
    borderRadius: "5px",
  };

  return (
    <div
      onClick={generateExcel}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <img
        alt="Logo"
        src={toAbsoluteUrl("/media/icons/duotune/art/exclamation-icon.svg")}
        className="h-15px cursor-pointer"
      />
      {showTooltip && (
        <div style={tooltipStyle}>Download upload template from here!</div>
      )}
    </div>
  );
};

export default ExcelDownloadComponent;
