import axios from "axios";
import { TAddTranslationPayload, TGetSingleLanguageTranslationPayload, TGetSingleTranslationByGooglePayload, TSaveTranslationPayload } from "../../types/Module/Translations/translationRequest";


const BASE_URL = process.env.REACT_APP_API_URL;
const DELETE_TRANSLATION_URL = BASE_URL + "/translations/";
const SAVE_TRANSLATION_URL =   BASE_URL + "/translations/";
const ADD_TRANSLATION_URL =   BASE_URL + "/translations";
const GET_TRANSLATION_URL = BASE_URL +"/translations"
const GET_GOOGLE_TRANSLATION_URL = BASE_URL +"/translations/getTranslationByGoogle"

export const getTranslation = (searchParams?:string) =>{
    return axios.get(GET_TRANSLATION_URL+"?"+searchParams);
}
export const getSingleTranslation = (id?:string) =>{
    return axios.get(GET_TRANSLATION_URL+"/"+id);
}

export const getSingleTranslationByGoogle = (payload:TGetSingleTranslationByGooglePayload) =>{
    return axios.post(GET_GOOGLE_TRANSLATION_URL,payload);
}

export const deleteTranslation = (translation:string) =>{
    return axios.delete(DELETE_TRANSLATION_URL + translation);
}

export const saveTranslation = (translation:TSaveTranslationPayload)=>{
    const url = SAVE_TRANSLATION_URL + translation.id;
    if(translation.id !== ''){
        return axios.patch(url, translation);
    }
    return axios.post(url,translation);
}


export const addTranslation = (translation:TAddTranslationPayload)=>{
    const url = SAVE_TRANSLATION_URL + translation.id;
   
    const payload = {
        "labelText":translation.labelText,
        "translations":translation.temp
    }
    
    if(translation.id){
      
        return axios.patch(url, payload);
    }
    return axios.post(ADD_TRANSLATION_URL,payload);
}



export const getSingleLanguageTranslation = (payload:TGetSingleLanguageTranslationPayload) =>{
    return axios.post(GET_GOOGLE_TRANSLATION_URL,payload);
}