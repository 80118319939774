import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { addProduct, deleteProduct } from "../generatedRequest/product";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/productSlice";
import { updateStatus } from "../generatedRequest/product";
import { useStatus } from "../customHooks/useStatus/useStatus";
import CarouselDemo from "../app/pages/Carousel";
import useClone from "../customHooks/useClone/useClone";
import ClonePrompt from "../components/ClonePrompt";
import CloneRegionModel from "../components/CloneRegionModel";

const moduleName = "Product";

const ProductTableAction = ({ product, regions }: any) => {
  const { rerender1 } = useSelector((state: RootState) => state.product);
  const [showPreviewPrompt, setPreviewPrompt] = useState(false);
  const { doEdit } = useEdit(moduleName);
  const dispatch = useDispatch();
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const [newPayloads, setNewPayloads] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState<any[]>([]);
  const [showCloneStatusModel, setShowCloneStatusModel] = useState(false);
  const [statusData, setStatusData] = useState([]);

  // let payload = {
  //   productName: product.productName,
  //   attributes:[],
  //   productImages: product.productImages.map((image:any)=> image),
  //   description: product.description,
  //   subcategory: product.subcategory._id,
  //   regions:selectedRegion.map((region:any) => region.id || region._id),
  //   category: product.category._id,
  //   brand:product.brand,
  //   model:product.model,
  //   modelNumber:product.modelNumber,
  //   sku:product.sku
  // };

  // Callback function to receive data from the child component
  const handleSelectedRegion = (data: any) => {
    setSelectedRegion(data);
    const payload = data.map((regionToClone: any) => {
      return {
        productName: product.productName,
        attributes: [],
        productImages: product.productImages.map((image: any) => image),
        description: product.description,
        subcategory: product.subcategory._id,
        regions: [regionToClone.id || regionToClone._id],
        category: product.category._id,
        brand: product.brand,
        model: product.model,
        modelNumber: product.modelNumber,
        sku: product.sku,
      };
    });
    setNewPayloads(payload);
  };

  const handleAfterDelete = useCallback(
    (flag: boolean, response: any) => {
      if (flag) {
        toast.success("record deleted");
        dispatch(setRerender(!rerender1));
        // do whatever you want with response
      } else {
        toast.error("error");
      }
    },
    [rerender1, dispatch]
  );
  const { canDo, currentActivity } = usePermissions();

  const handleAfterStatus = useCallback(
    (status: boolean, res: any) => {
      if (status) {
        toast.success("status updated successfully");
        dispatch(setRerender(!rerender1));
      } else {
        //TODO: make error when go live
        toast.error("something went wrong");
      }
    },
    [rerender1, dispatch]
  );

  const handleAfterClone = useCallback(
    (data: any) => {
      // if (status) {
      //   toast.success("Clone created successfully");
      dispatch(setRerender(!rerender1));
      setShowCloneStatusModel(true);
      setStatusData(data);
      // } else {
      //   //TODO: make error when go live
      //   toast.error(res?.data?.message);
      // }
    },
    [rerender1, dispatch]
  );

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteProduct,
    true,
    handleAfterDelete
  );

  const {
    onStatus,
    showStatusPrompt,
    statusloading,
    statusToUpdate,
    onstatusCancel,
    ChangeStatus,
  } = useStatus(updateStatus, true, handleAfterStatus);

  const {
    onClone,
    showClonePrompt,
    setCloneShowPrompt,
    cloneLoading,
    recordToClone,
    onCancelClone,
    cloneRecord,
    newRegion,
  } = useClone(
    addProduct,
    true,
    handleAfterClone,
    regions,
    selectedRegion,
    newPayloads
  );

  const actions = ["Edit", "Delete", "Change Status", "Preview", "Clone"];

  // const handlePreviewPrompt = useCallback(() => {
  //   const fn = () => {
  //     console.log("here")
  //     setPreviewPrompt(!showPreviewPrompt);
  //   };
  //   fn();
  // }, []);

  const handlePreviewPrompt = useCallback(() => {
    const fn = () => {
      setPreviewPrompt(!showPreviewPrompt);
    };
    fn();
  }, [showPreviewPrompt]);

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const onCloseCloneStatus = useCallback(() => {
    setShowCloneStatusModel(false);
  }, []);

  const onBackHandler = useCallback(() => {
    setShowCloneStatusModel(false);
    setCloneShowPrompt(true);
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          // if (canDo(moduleName, "Edit")) {
          doEdit(product._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          // if (canDo(moduleName, "Delete")) {
          onDelete(product._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Clone":
          onClone(product);
          break;
        case "Change Status":
          onStatus(product);
          break;
        case "Preview":
          handlePreviewPrompt();
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, product, onDelete, onClone, regions]
  );

  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      {showPreviewPrompt && (
        <CarouselDemo
          Images={product?.productImages}
          title={product?.productName}
          categoryName={product?.category?.categoryName}
          subCategoryName={product?.subcategory?.subcategoryName}
          regionName={product?.regions[0]?.regionName}
          description={product?.description}
          sku={product?.sku}
          price={product?.price}
          onCancel={() => handlePreviewPrompt()}
        />
      )}

      {showStatusPrompt && (
        <ConfirmationPrompt
          title="Change Status"
          message="Are you sure you want to change the status ?"
          onCancel={onstatusCancel}
          onConfirm={ChangeStatus}
          isloading={statusloading}
        />
      )}

      {showClonePrompt && (
        <ClonePrompt
          title="Generate Clone"
          onCancel={onCancelClone}
          onConfirm={cloneRecord}
          isloading={cloneLoading}
          filterRegion={newRegion}
          sendSelectedRegionToParent={handleSelectedRegion}
        />
      )}

      {showCloneStatusModel && (
        <CloneRegionModel
          title={"Product Clone Status"}
          onCancel={onCloseCloneStatus}
          statusData={statusData}
          onBack={onBackHandler}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default ProductTableAction;
