import { Route, Routes } from "react-router-dom";
import RegionsPage from "../generatedTableList/RegionsPage";
import AddRegion from "../app/pages/RegionAdd/AddRegion";
import EditRegion from "../app/pages/RegionsEdit/EditRegion";

const RegionsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<RegionsPage />} />
      <Route path="/edit" element={<EditRegion />} />
      <Route path="/add" element={<AddRegion />} />
    </Routes>
  );
};
export default RegionsRoutes;
