import * as Yup from 'yup'

export const setCodeSchema = Yup.object().shape({
    code: Yup.number()
    .typeError('code must be a number.')
    .min(100000, 'Minimum 6 digits.')
    .max(999999, 'Maximum 6 digits.')
    .required('code number is required.'),
    
  
  })