
    import { createSlice, PayloadAction } from '@reduxjs/toolkit'
    
    type TNameState =  {
        data: [],
        perPage: number,
        currentPage: number,
        totalPage: number,
        searchTerm: string,
        loading: boolean,
        sortBy: string,
        sortColumn:string,
    }
    
    
    const initialNameState: TNameState = {
        data: [],
        perPage: 10,
        currentPage: 1,
        totalPage: 5,
        searchTerm: "",
        loading: false,
        sortBy: "desc",
        sortColumn:""
    
        
    }
    
    export const nameSlice = createSlice({
        name: 'name',
        initialState:initialNameState,
        reducers: {
            setName: (state:TNameState, { payload }: PayloadAction<[]>) => {
              state.data=payload
             },
            setPerPage: (state:TNameState, {payload}: PayloadAction<number>) => {
                state.perPage = payload;
            },
            setCurrentPage: (state:TNameState, {payload}: PayloadAction<number>) => { 
                state.currentPage = payload;
            },
            setTotalPage: (state:TNameState, {payload}: PayloadAction<number>) => { },
            setSearchTerm: (state:TNameState, {payload}: PayloadAction<string>) => { 
                state.searchTerm = payload;
            },
            setSortBy: (state:TNameState, {payload}: PayloadAction<string>) => { 
              state.sortBy = payload;
          },
          setSortColumn: (state:TNameState, {payload}: PayloadAction<string>) => { 
            state.sortColumn = payload;
            state.sortBy = "desc";
        },
            setLoading: (state:TNameState, {payload}: PayloadAction<boolean>) => { 
              state.loading = payload
            },
        },
    })
    
    
    export const { setName, setPerPage, setCurrentPage, setSortColumn,setSortBy, setTotalPage, setSearchTerm, setLoading } = nameSlice.actions
    export default nameSlice.reducer
    