import { useCallback } from "react";
import {useNavigate} from "react-router-dom"


// Define the hook function with two parameters
const useEdit =(moduleName:string,handleNavigate?:Function)=> {
  const navigate  = useNavigate()

  // This function performs the edit action when called.
  const doEdit = useCallback((editRecordID:string) => {
    // If a handleNavigate function was provided, use it to navigate.
    if (handleNavigate) {
      handleNavigate(editRecordID);
    } else {
      // Otherwise, navigate to the edit page using the react-router-dom's useNavigate hook.
      navigate(`/${moduleName.toLocaleLowerCase()}/edit?q=${editRecordID}`)
    }
  },[handleNavigate,moduleName,navigate])

  // Return the doEdit function for external use.
  return { doEdit };
}

export default useEdit