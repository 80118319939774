import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import { MODULE_DELETE_URL } from "../app/request/userRequest";
import { TModuleDelete } from "../types/Network_ Request/moduleWiseApiType/deleteApi";
import { TLeadAction, TTableAction } from "../types/TableActions";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { deleteLead } from "../generatedRequest/lead";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/leadSlice";
import DetailsPrompt from "../components/DetailsPrompt";

const moduleName = "Lead";

const LeadTableAction = ({ lead }: TLeadAction) => {
  const { rerender1 } = useSelector((state: RootState) => state.lead);
  const dispatch = useDispatch();
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const [showDetailsPrompt, setShowDetailsPrompt] = useState(false);
  const handleAfterDelete = useCallback((flag: boolean, response: any) => {
    if (flag) {
      toast.success("record deleted");
      dispatch(setRerender(!rerender1));
      // do whatever you want with response
    } else {
      toast.error("error");
    }
  }, [rerender1, dispatch]);

  const { canDo, currentActivity } = usePermissions();

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteLead,
    true,
    handleAfterDelete
  );
  const actions = ["View Details", "Delete"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleClose = useCallback(() => {
    const fn = () => {
      setShowDetailsPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          // if (canDo(moduleName, "Edit")) {
          //doEdit(id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          // if (canDo(moduleName, "Delete")) {
          onDelete(lead._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "View Details":
          setShowDetailsPrompt(true);
          break;
        default:
          break;
      }
    },
    [onDelete, lead]
  );
  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      {showDetailsPrompt && (
        <DetailsPrompt onCancel={handleClose} data={lead} title="View Details" />
      )}
      <ActionCells actions={actions}  onSelect={handleSelect} />
    </>
  );
};
export default LeadTableAction;
