import { TTableProps } from "../types/TableView";
import TableView from "../components/TableView";
import EmptyState from "../components/EmptyState";

const QuizList = ({
  columns,
  rows,
  showPagination,
  showSearch,
  showPerPage,
  paginationData,
  onPageChange,
  onPerPageChange,
  handleSearchParams,
  searchPlaceholder,
  showFilter,
  searchLoading
}: TTableProps) => {
  return (
    <div className="card">
      <TableView
        columns={columns}
        rows={rows}
        showPagination={showPagination}
        showSearch={showSearch}
        showPerPage={showPerPage}
        paginationData={paginationData}
        onPageChange={onPageChange}
        onPerPageChange={onPerPageChange}
        handleSearchParams={handleSearchParams}
        searchPlaceholder={searchPlaceholder}
        searchLoading={searchLoading}
        showFilter={showFilter}
        searchComponent={<EmptyState                      
          title="Sorry"
          message="No Quiz Found"
          buttonTitle="Reset"
          image="/media/misc/empty-State.svg"
        />}
      />
    </div>
  );
};

export default QuizList;
