import React from 'react'
import { Stepper } from '../../../components/Stepper/Stepper'

const FrontendPage = () => {
  return (
    <div className='card p-5'>
        <Stepper />
    </div>
  )
}

export default FrontendPage