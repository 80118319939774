import React, { useState } from "react";
import { TOptionType } from "../types/CustomSelect";
import { TagBox, TagBoxItem } from "../components/TagBox";
import SelectComponent from "../components/CustomSearchSelect";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import Multiselect from "multiselect-react-dropdown";
import clsx from "clsx";
import Select from "../components/CustomSelect";
import {
  TStatusOption,
  TUnitProp,
  TselectedOptionType,
} from "../types/Module/Unit/Unit";
import "../styles/multiselectStyles.css";

const UnitForm = ({
  errors,
  touched,
  isloading,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  activeRegions,
  predefinedUnit,
}: TUnitProp) => {
  const OptionsStatus: TOptionType<string>[] = [
    { value: "active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const [optionsStatus, setOptionStatus] = useState<TOptionType<string>>({
    value: "active",
    label: "Active",
  });

  const handleSNewTag = (tagName: string) => {
    const trimmedTagName = tagName.trim();
    if (trimmedTagName) {
      // Filter out any existing empty tags from the supportedValues array
      const filteredSupportedValues = values.supportedValues.filter(
        (tag) => tag.name.trim() !== ""
      );

      const newTag: TagBoxItem = {
        name: trimmedTagName,
        id: Date.now().toString(),
      };

      // Add the new tag to the filtered array
      setFieldValue("supportedValues", [...filteredSupportedValues, newTag]);
    }
  };

  const handleSDeleteTag = (tag: TagBoxItem, index: number) => {
    const newTags = [...values.supportedValues];
    newTags.splice(index, 1);
    setFieldValue("supportedValues", newTags); // remove the tag at the specified index
  };
  const convertStatusToOptionType = (
    status: TStatusOption | string
  ): TOptionType<string> | undefined => {
    if (status) {
      if (typeof status === "string") {
        return { value: status, label: status };
      } else {
        return status;
      }
    }
    return undefined;
  };
  return (
    <div className=" w-100 w-md-100 pt-5" id="kt_login_signin_form">
      <div className="d-flex flex-column flex-md-row  mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Name
          </label>

          <SelectComponent
            options={predefinedUnit}
            selectedKey={"name"}
            onSelect={async (selectedOption: TselectedOptionType | null) => {
              if (selectedOption) {
                await setFieldValue("name", selectedOption.name);
                await setFieldValue("symbol", selectedOption.symbol);
                await setFieldValue("unitType", selectedOption.unitType);
                await setFieldTouched("name");
              }
            }}
            onInputChange={async (value) => {
              await setFieldValue("name", value);
              await setFieldTouched("name");
            }}
            selectedOption={values.name || ""} // Provide a default value if values.name is null
            onFocusChange={async (value) => {
              if (value) {
                await setFieldTouched("name");
              }
            }}
          />

          {touched.name && errors.name && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.name}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Symbol
          </label>
          <input
            placeholder="Enter Unit Symbol"
            disabled={isloading}
            {...getFieldProps("symbol")}
            className={clsx(
              "form-control bg-transparent ",
              {
                "is-invalid": touched.symbol && errors.symbol,
              },
              {
                "is-valid": touched.symbol && !errors.symbol,
              }
            )}
            type="text"
          />
          {touched.symbol && errors.symbol && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.symbol}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row  mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-50 w-100 me-md-10">
          <label className="form-label fs-6 fw-bolder text-dark required">
            Type
          </label>
          <input
            placeholder="Enter Unit Type"
            disabled={isloading}
            {...getFieldProps("unitType")}
            className={clsx(
              "form-control bg-transparent ",
              {
                "is-invalid": touched.unitType && errors.unitType,
              },
              {
                "is-valid": touched.unitType && !errors.unitType,
              }
            )}
            type="text"
          />
          {touched.unitType && errors.unitType && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.unitType}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-column flex-md-column w-md-50 w-100">
          <label className="form-label fs-6 fw-bolder text-dark">Status</label>
          <Select
            value={convertStatusToOptionType(values.status) || optionsStatus}
            name="Select Status"
            onChange={(selectedValue) => {
              if (selectedValue) {
                setOptionStatus(selectedValue);
                setFieldValue("status", selectedValue);
              }
            }}
            options={OptionsStatus}
          />
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row w-md-100 mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-100 w-100 ">
          <label className="form-label fs-6 fw-bolder text-dark">Regions</label>
          <div onClick={() => setFieldTouched("regions")}>
            <Multiselect
              className="multiselect-container multiselect-search-box"
              options={activeRegions}
              avoidHighlightFirstOption={true}
              displayValue="regionName"
              showArrow={true}
              customArrow={<CustomArrow />}
              customCloseIcon={<CustomCrossButton />}
              selectedValues={values.regions}
              placeholder="Select Regions"
              onSelect={async (e) => {
                await setFieldValue("regions", e);
                setFieldTouched("regions");
              }}
              onRemove={async (e) => {
                await setFieldValue("regions", e);
                setFieldTouched("regions");
              }}
              keepSearchTerm={true}
            />
          </div>
          {touched?.regions && errors?.regions && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{errors.regions}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row w-md-100 mb-3 w-100">
        <div className="d-flex flex-column flex-md-column w-md-100 w-100 ">
          <label className="form-label fs-6 fw-bolder text-dark">
            Supported Values
          </label>
          <TagBox
            tags={values.supportedValues || []}
            handleNew={handleSNewTag}
            handleDelete={handleSDeleteTag}
            placeholder={"Supported Values"}
            disabled={isloading}
          />
        </div>
      </div>
    </div>
  );
};

export default UnitForm;
