import React from 'react';
import { useCallback } from 'react';
import { TPaginationProps } from '../../types/Pagination';
import PaginationButton from './PaginationButton';



const Pagination = ({ onPageChange, currentPage, totalPage, ...props }: TPaginationProps) => {

  const handleGotoFirstPage = useCallback(() => { onPageChange(1) }, [onPageChange]);
  const handleGotolastPage = useCallback(() => { onPageChange(totalPage) }, [onPageChange, totalPage]);
  const handlePrevPage = useCallback(() => { onPageChange(currentPage - 1) }, [onPageChange, currentPage]);
  const handleNextPage = useCallback(() => {onPageChange(currentPage + 1)}, [onPageChange, currentPage]);

  return (
    <ul className='pagination'>
      <PaginationButton disabled={currentPage === 1} onClick={handleGotoFirstPage}><i className="bi bi-chevron-bar-left"></i></PaginationButton>
      <PaginationButton disabled={currentPage === 1} onClick={handlePrevPage}><i className='previous'></i></PaginationButton>
      <PaginationButton disabled={true}>{currentPage}/{totalPage}</PaginationButton>
      <PaginationButton disabled={currentPage === totalPage} onClick={handleNextPage}><i className='next'></i></PaginationButton>
      <PaginationButton disabled={currentPage === totalPage} onClick={handleGotolastPage}><i className="bi bi-chevron-bar-right"></i></PaginationButton>
    </ul>
  )
}

export default React.memo(Pagination)