import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TAttributeState = {
  data: [];
  perPage: number;
  currentPage: number;
  totalPage: number;
  searchTerm: string;
  loading: boolean;
  sortBy: string;
  sortColumn: string;
  rerender1: boolean;
};

const initialAttributeState: TAttributeState = {
  data: [],
  perPage: 10,
  currentPage: 1,
  totalPage: 1,
  searchTerm: "",
  loading: false,
  sortBy: "desc",
  sortColumn: "",
  rerender1: false,
};

export const attributeSlice = createSlice({
  name: "attribute",
  initialState: initialAttributeState,
  reducers: {
    setAttribute: (state: TAttributeState, { payload }: PayloadAction<[]>) => {
      state.data = payload;
    },
    setPerPage: (
      state: TAttributeState,
      { payload }: PayloadAction<number>
    ) => {
      state.perPage = payload;
    },
    setCurrentPage: (
      state: TAttributeState,
      { payload }: PayloadAction<number>
    ) => {
      state.currentPage = payload;
    },
    setTotalPage: (
      state: TAttributeState,
      { payload }: PayloadAction<number>
    ) => {
      state.totalPage = payload;
    },
    setSearchTerm: (
      state: TAttributeState,
      { payload }: PayloadAction<string>
    ) => {
      state.searchTerm = payload;
    },
    setSortBy: (state: TAttributeState, { payload }: PayloadAction<string>) => {
      state.sortBy = payload;
    },
    setSortColumn: (
      state: TAttributeState,
      { payload }: PayloadAction<string>
    ) => {
      state.sortColumn = payload;
      state.sortBy = "desc";
    },
    setLoading: (
      state: TAttributeState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.loading = payload;
    },
    setRerender: (
      state: TAttributeState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.rerender1 = payload;
    },
  },
});

export const {
  setAttribute,
  setPerPage,
  setCurrentPage,
  setSortColumn,
  setSortBy,
  setRerender,
  setTotalPage,
  setSearchTerm,
  setLoading,
} = attributeSlice.actions;
export default attributeSlice.reducer;
