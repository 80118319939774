
  import React, { useState,useCallback } from "react";
  import {Route, Routes,useNavigate} from 'react-router-dom'
  import RetailerPage from "../generatedTablePage/RetailerPage";
  import ModalComponent from "../components/Modal";
  import { Outlet } from "react-router-dom";
  import AddRetailer from "../generatedAddEdit/retailer/AddRetailer";
  import EditRetailer from "../generatedAddEdit/retailer/EditRetailer";
  

  
  
  const RetailerRoutes = () => {


    return (
      <Routes> 
      
    <Route
    path="/"
    element={<RetailerPage/>}
  />
  <Route
    path="/edit"
    element={
      <EditRetailer/>
    }
  />
  <Route
    path="/add"
    element={
      <AddRetailer/>
    }
  />      
         
      </Routes>
    );
  };
  export default RetailerRoutes
