import * as Yup from "yup";

export const RetailerSchema = Yup.object().shape({
  retailerName: Yup.string().required("Retailer Name is required"),
  retailerFrontendUrl: Yup.string().required("Retailer Url is required"),
  retailerRegion: Yup.array()
    .required("Retailer Region is required")
    .min(1, "Minimum 1 Region is Required")
    .max(1, "Maximum 1 Region is Required"),
  searchConfiguration: Yup.object().when('isElasticSearch', {
    is: true,
    then: Yup.object().shape({
      searchUrl: Yup.string().required("Search Url is required"),
      searchBy: Yup.object().test(function (value) {
        if (!value || Object.keys(value).length === 0) {
          return this.createError({
            message: "Search By is required",
            path: "searchConfiguration.searchBy",
          });
        }
        return true;
      }),
    }),
  }),
  feedConfiguration: Yup.object().when('isFeed', {
    is: true,
    then: Yup.object().shape({
      file: Yup.array()
        .required("File is required")
        .min(1, "Minimum 1 File is Required")
        .max(1, "Maximum 1 File is Required"),
      // mapBy: Yup.object().test(function (value) {
      //   if (!value || Object.keys(value).length === 0) {
      //     return this.createError({
      //       message: "Map By is required",
      //       path: "feedConfiguration.mapBy",
      //     });
      //   }
      //   return true;
      // }),
      // redirectFieldName: Yup.string().required("Redirect Field Name is required"),
    }),
  }),
});
