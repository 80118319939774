import React, { useCallback, useState } from "react";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { RootState } from "../redux/store";
import { setRerender } from "../generatedSlice/regionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useStatus } from "../customHooks/useStatus/useStatus";
import {
  changeDefaultSelection,
  updateStatus,
} from "../app/request/regionRequest";
import { deleteRegion } from "../app/request/regionRequest";
import { TRegionProps, TRowData } from "../types/Module/Region/RegionsPage";
import { useMakeDefault } from "../customHooks/useMakeDefault/useMakeDefault";

const moduleName = "Region";

const RegionsTableAction = ({ region }: TRegionProps) => {
  const { rerender1 } = useSelector((state: RootState) => state.region);
  const dispatch = useDispatch();
  const { doEdit } = useEdit(moduleName);
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);
  const handleAfterDelete = useCallback(
    (flag: boolean, response?: TRowData) => {
      if (!response?.data?.status) {
        toast.error(response?.data?.error?.message);
        return;
      } else if (flag) {
        toast.success("record deleted");
        dispatch(setRerender(!rerender1));
        // do whatever you want with response
      } else {
        toast.error("error");
      }
    },
    [rerender1, dispatch]
  );

  const handleAfterStatus = useCallback(
    (status: boolean, res: TRowData) => {
      if (status) {
        toast.success("status updated successfully");
        dispatch(setRerender(!rerender1));
      } else {
        //TODO: make error when go live
        toast.error(res?.data?.message || "something went wrong" );
      }
    },
    [rerender1, dispatch]
  );

  const handleAfterMakeDefault = useCallback(
    (isDefault: boolean, res: TRowData) => {
      if (isDefault) {
        toast.success("Made Default Successfully");
        dispatch(setRerender(!rerender1));
      } else {
        //TODO: make error when go live
        toast.error(res?.data?.message);
      }
    },
    [rerender1, dispatch]
  );

  const { canDo, currentActivity } = usePermissions();

  const {
    onStatus,
    showStatusPrompt,
    statusloading,
    onstatusCancel,
    ChangeStatus,
  } = useStatus(updateStatus, true, handleAfterStatus);

  const {
    onSetDefaultRegion,
    showRegionPrompt,
    regionLoading,
    defaultRegion,
    onRegionCancel,
    changeDefaultRegion,
  } = useMakeDefault(changeDefaultSelection, true, handleAfterMakeDefault);

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteRegion,
    true,
    handleAfterDelete
  );

  const actions = ["Edit", "Delete", "Change Status", "Make Default"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Edit":
          //if (canDo(moduleName, "Edit")) {
            doEdit(region.id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Delete":
          //if (canDo(moduleName, "Delete")) {
            onDelete(region.id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        case "Change Status":
          onStatus(region);
          break;
        case "Make Default":
          onSetDefaultRegion(region);
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, region, onDelete, onStatus, onSetDefaultRegion]
  );
  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}
      {showStatusPrompt && (
        <ConfirmationPrompt
          title="Change Status"
          message="Are you sure you want to change the status ?"
          onCancel={onstatusCancel}
          onConfirm={ChangeStatus}
          isloading={statusloading}
        />
      )}
      {showRegionPrompt && (
        <ConfirmationPrompt
          title="Change Default"
          message="Do you want to make it default ?"
          onCancel={onRegionCancel}
          onConfirm={changeDefaultRegion}
          isloading={regionLoading}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default RegionsTableAction;
