export const RolePermissions = {
  roleName: "",
  permissions: [
    {
      moduleName: "Region",
      moduleUrl: "/region",
      moduleActivities: [
        {
          activityName: "Create Region",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Region",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Region",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Region",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Unit",
      moduleUrl: "/unit",
      moduleActivities: [
        {
          activityName: "Create Unit",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Unit",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Unit",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Unit",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Auth",
      moduleUrl: "/auth",
      moduleActivities: [
        {
          activityName: "Create Auth",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Auth",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Auth",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Auth",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Users",
      moduleUrl: "/users",
      moduleActivities: [
        {
          activityName: "Create Users",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Users",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Users",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Users",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Adduser",
      moduleUrl: "/adduser",
      moduleActivities: [
        {
          activityName: "Create Adduser",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Adduser",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Adduser",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Users",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Edituser",
      moduleUrl: "/edituser",
      moduleActivities: [
        {
          activityName: "Create Edituser",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Edituser",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Edituser",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Edituser",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Product",
      moduleUrl: "/product",
      moduleActivities: [
        {
          activityName: "Create Product",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Product",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Product",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Product",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Category",
      moduleUrl: "/category",
      moduleActivities: [
        {
          activityName: "Create Category",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Category",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Category",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Category",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "SubCategory",
      moduleUrl: "/subcategory",
      moduleActivities: [
        {
          activityName: "Create SubCategory",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit SubCategory",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View SubCategory",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete SubCategory",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Accessory",
      moduleUrl: "/accessory",
      moduleActivities: [
        {
          activityName: "Create Accessory",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Accessory",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Accessory",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Accessory",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Languages",
      moduleUrl: "/languages",
      moduleActivities: [
        {
          activityName: "Create Languages",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Languages",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Languages",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Languages",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Translations",
      moduleUrl: "/translations",
      moduleActivities: [
        {
          activityName: "Create Translations",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Translations",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Translations",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Translations",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Attribute",
      moduleUrl: "/attribute",
      moduleActivities: [
        {
          activityName: "Create Attribute",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Attribute",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Attribute",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Attribute",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Retailer",
      moduleUrl: "/retailer",
      moduleActivities: [
        {
          activityName: "Create Retailer",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Retailer",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Retailer",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Retailer",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Dashboard",
      moduleUrl: "/dashboard",
      moduleActivities: [
        {
          activityName: "Create Dashboard",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Dashboard",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Dashboard",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Dashboard",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Demo",
      moduleUrl: "/demo",
      moduleActivities: [
        {
          activityName: "Create Demo",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Demo",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Demo",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Demo",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Quiz",
      moduleUrl: "/quiz",
      moduleActivities: [
        {
          activityName: "Create Quiz",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Quiz",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Quiz",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Quiz",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Lead",
      moduleUrl: "/lead",
      moduleActivities: [
        {
          activityName: "View Lead",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Lead",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Scrappings",
      moduleUrl: "/scrappings",
      moduleActivities: [
        {
          activityName: "Edit Scrappings",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Scrappings",
          activityFunction: "view",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Mappings",
      moduleUrl: "/mappings",
      moduleActivities: [
        {
          activityName: "Create Mappings",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Mappings",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Mappings",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Mapping",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
    {
      moduleName: "Helps",
      moduleUrl: "/helps",
      moduleActivities: [
        {
          activityName: "Create Helps",
          activityFunction: "add",
          isSelected: false,
        },
        {
          activityName: "Edit Helps",
          activityFunction: "edit",
          isSelected: false,
        },
        {
          activityName: "View Helps",
          activityFunction: "view",
          isSelected: false,
        },
        {
          activityName: "Delete Helps",
          activityFunction: "delete",
          isSelected: false,
        },
      ],
    },
  ],
};
