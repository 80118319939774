
  import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const RETATILERDATA_GET_URL = "/static-response/retatilerData/retatilerData.json";
const RETATILERDATA_DELETE_URL = BASE_URL +"/retatilerDatas/";
const RETATILERDATA_ADD_URL = BASE_URL +"/retatilerDatas/";
const RETATILERDATA_EDIT_URL = BASE_URL +"/retatilerDatas/";


export const getRetatilerdata = (searchParams?: string) => {
  return axios.get(RETATILERDATA_GET_URL + "?q" + searchParams);
};

export const deleteRetatilerdata = (Id: string) => {
  return axios.delete(RETATILERDATA_DELETE_URL +Id);
};

export const addRetatilerdata = (Id: string) => {
    return axios.post(RETATILERDATA_ADD_URL + Id);
  };

  export const editRetatilerdata = (payload:any,Id: string) => {
    return axios.patch(RETATILERDATA_EDIT_URL + Id,payload);
  };

  

  