import AttributeTableAction from "../generatedTableActions/AttributeTableAction";
import Badge from "../components/Badge";
import { TColumn } from "../types/Module/Attribute/AttributePage";
import { NotesDisplay } from "../components/Reusable/NotesDisplay";

export const columns: TColumn[] = [
  {
    name: "name",
    renderHeader: () => (
      <>
        <b>Name</b>
      </>
    ),
    renderCell: (row) => <>{`${row && row.attributeName}`.toUpperCase()}</>,
  },
  {
    name: "Supported_Units",
    renderHeader: () => (
      <>
        <b>Supported Units</b>
      </>
    ),
    renderCell: (row) => (
      <>
        {Array.prototype.map.call(row && row.supportedUnits, function (item) {
          return (
            <span>
              <a
                href={"/unit/edit?q=" + item.id}
                style={{ textDecoration: "underline" }}
              >
                {item.name}
              </a>
              {"  "}
            </span>
          );
        })}
      </>
    ),
  },
  {
    name: "status",
    renderHeader: () => (
      <>
        <b>Status</b>
      </>
    ),
    renderCell: (row) => (
      <Badge
        variant={row.status === "active" ? "success" : "danger"}
        className="fs-7 fw-bolder"
      >
        {row?.status}
      </Badge>
    ),
  },
  // {
  //   name: "notes",
  //   renderHeader: () => (
  //     <>
  //       <b>Notes</b>
  //     </>
  //   ),
  //   renderCell: (row) => {
  //    return (
  //       <span>
  //        <NotesDisplay notes={row.notes}/>
  //       </span>
  //     );
  //   },
  // },
  {
    name: "Created_At",
    renderHeader: () => (
      <>
        <b>Created At</b>
      </>
    ),
    renderCell: (row) => <>{row.createdAt}</>,
  },
  {
    name: "Modified_At",
    renderHeader: () => (
      <>
        <b>Modified At</b>
      </>
    ),
    renderCell: (row) => <>{row.updatedAt}</>,
  },
  {
    name: "Actions",
    renderHeader: () => (
      <>
        <b>Actions</b>
      </>
    ),
    renderCell: (row) => <AttributeTableAction attribute={row} />,
  },
];
