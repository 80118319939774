import React, { useCallback, useEffect, useState } from "react";
import InputField from "../components/Input";
import Multiselect from "multiselect-react-dropdown";
import Select from "../components/CustomSelect";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import DropBox, { fileImage, fileListToBase64 } from "../components/DropBox";
import ImageGrid from "../components/ImageGrid";
import { TagBox, TagBoxItem } from "../components/TagBox/index";
import { Formik } from "formik";
import SelectComponent from "../components/CustomSearchSelect";
import {
  AccessoryImageUpload,
  AddDeleteImage,
  EditDeleteImage,
  getConvertedAttributeValue,
} from "../generatedRequest/accessory";
import { toast } from "react-toastify";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import MappedAccessoryFormModal from "../generatedFormModal/MappedAccessoryFormModal";
import { useDispatch } from "react-redux";
import { setMappedAccessorySearchTerm } from "../generatedSlice/accessorySlice";
import { toAbsoluteUrl } from "../_metronic/helpers";

type TAccessoryFormProp = {
  errors: any;
  isSubmitting: boolean;
  touched: any;
  setFieldTouched: any;
  getFieldProps: Function;
  setFieldValue: Function;
  values: any;
  allRegions: any;
  allCategory: any;
  isEdit: boolean;
  isloading: boolean;
  allStoreProducts: any;
};

const AccessoryForm = ({
  errors,
  isEdit,
  isSubmitting,
  touched,
  isloading,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  allRegions,
  allCategory,
  allStoreProducts,
}: TAccessoryFormProp) => {

  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let defaultImages = [];

  if (values?.images) {
    defaultImages = values?.images.map((t: any) => {
      return {
        id: t.id,
        url: t.src,
        type: "uploaded",
        file: null,
      };
    });
  }

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [selectedFilesURL, setSelectedFilesURL] =
    useState<any[]>(defaultImages);

  const [selectedValues, setSelectedValues] = useState<any>({});
  const [MapAccessoryModalOpen, setMapAccessoryModalOpen] = useState(false);
  const [AttributeToTranslated, setAttributeToTranslated] = useState<any>(null);

  // useEffect(() => {
  //   const updatedSelectedValues:any = {};
  //   for (const attribute of values.attributes) {
  //     const unitId = attribute.unit._id;
  //     updatedSelectedValues[unitId] = attribute.unit;
  //   }
  //   setSelectedValues(updatedSelectedValues);

  //   console.log(values?.subcategory?.[0]?.supportedAttributes)
  // }, []);

  useEffect(() => {
    const fn = () => {
      const updatedSelectedValues = { ...selectedValues };
      const supportedAttributes = values?.subcategory?.[0]?.supportedAttributes;
      const attributes = values?.attributes;
      if (
        supportedAttributes &&
        supportedAttributes.length > 0 &&
        attributes &&
        attributes.length > 0
      ) {
        for (const supportedAttribute of supportedAttributes) {
          const supportedAttributeName = supportedAttribute.attributeName;

          for (const attribute of attributes) {
            const attributeName = attribute.attributeName;
            if (supportedAttributeName === attributeName) {
              updatedSelectedValues[supportedAttribute._id] =
                supportedAttribute.supportedUnits.filter(
                  (data: any) => data?.name === attribute?.unit?.name
                )[0];
              break;
            }
          }
        }
      }

      setSelectedValues(updatedSelectedValues);
    };

    isEdit && fn();
  }, [values.attributes, values.subcategory]);

  console.log("values", values);

  const handleImageUrlDelete = useCallback(
    (index: number) => {
      const fn = async function () {
        // let curRecord: any = selectedFilesURL[index];
        let curRecord: any = values.images[index];
        if (curRecord?.type === "uploaded") {
          // let res: any = await DeleteProductImage(resData._id, [curRecord.id])
          // if (res.data.status === false) {
          // setStatus(res.data.error)
          // } else {
          // let images = formik.values.images.filter((image: any, _index: number) => _index !== index)
          // formik.setFieldValue("images", images);
          // setSelectedFilesURL(selectedFilesURL.filter((image, _index) => _index !== index))
          // }
        } else {
          // let images = formik.values.images.filter((image: any, _index: number) => _index !== index)
          // formik.setFieldValue("images", images);
          setSelectedFilesURL(
            selectedFilesURL.filter((image, _index) => _index !== index)
          );
        }
      };
      fn();
    },
    [selectedFilesURL, setSelectedFilesURL, values]
  );

  const handleDropFiles = useCallback(
    (files: any[]) => {
      const fn = async function () {
        const res = await AccessoryImageUpload(files);
        if (res.data.status) {
          let images = res.data.data.accessoryImagesURL;
          const newImages = [...values.accessoryImages];
          newImages.push(...images);
          console.log("temp", newImages);
          setFieldValue("accessoryImages", newImages);
          
        } else {
          toast.error("Something went wrong");
        }
      };
      fn();
    },
    [selectedFilesURL, selectedFiles, values]
  );

  const handleNewTag = (tagName: string) => {
    if (tagName) {
      const newTag: TagBoxItem = {
        name: tagName,
        id: Date.now(), // generate a unique ID for the new tag
      };
      setFieldValue("subcategory", [...values.subcategory, newTag]);
    }
    // add the new tag to the list of tags
  };

  const handleMapAccessoryModalOpen = () => {
    setMapAccessoryModalOpen(!MapAccessoryModalOpen);
    dispatch(setMappedAccessorySearchTerm(""));
  };

  const handleDeleteTag = (tag: TagBoxItem, index: number) => {
    const newTags = [...values.subcategory];
    newTags.splice(index, 1);
    setFieldValue("subcategory", newTags); // remove the tag at the specified index
  };

  const handleFirstSelectChange =
    (attributeId: any, unitIndex: any) => async (e: any) => {
      const selectedUnit = values.subcategory[0].supportedAttributes.find(
        (attribute: any) => attribute._id === attributeId
      ).supportedUnits[unitIndex];

      setSelectedValues((prevValues: any) => ({
        ...prevValues,
        [attributeId]: selectedUnit,
      }));

      const selectedAttribute =
        values?.subcategory[0]?.supportedAttributes?.find(
          (attribute: any) => attribute._id === attributeId
        );

      const attributeValueExist = values?.attributes.filter(
        (attribute: any) =>
          attribute.attributeName == selectedAttribute.attributeName
      );

      if (attributeValueExist?.length > 0) {
        if (attributeValueExist[0]?.unit?._id !== selectedUnit?._id) {
          let payload = {
            previousUnit: {
              _id: attributeValueExist[0]?.unit?._id,
              name: attributeValueExist[0]?.unit?.name,
            },
            previousValue: attributeValueExist[0]?.value,
            currentUnit: {
              _id: selectedUnit?._id,
              name: selectedUnit?.name,
            },
          };
          try {
            setAttributeToTranslated(selectedAttribute?.attributeName);
            const convertedValueFromGpt = await getConvertedAttributeValue(
              payload
            );
            handleSecondSelectTranslatedValue(
              selectedAttribute?.attributeName,
              selectedUnit,
              convertedValueFromGpt.data.data.currentValue
            );
          } catch (error) {
          } finally {
            setAttributeToTranslated(null)
          }
        }
      }
    };

  const handleSecondSelectTranslatedValue = (
    attributeId: any,
    unit: any,
    e: any
  ) => {
    console.log(attributeId, unit, e);
    let attributeObject: any = {};
    if (typeof e === "object") {
      attributeObject = e;
    } else if (typeof e === "string") {
      attributeObject = {
        value: e,
        _id: Date.now(),
      };
    }
    const updatedAttributes = values.attributes.map((attribute: any) => {
      if (attribute.attributeName === attributeId) {
        return {
          ...attribute,
          value: attributeObject?.value,
          unit: {
            _id: unit._id,
            name: unit.name,
          },
        };
      }
      return attribute;
    });
    const attributeExists = updatedAttributes.some(
      (attribute: any) => attribute.attributeName === attributeId
    );

    if (!attributeExists) {
      updatedAttributes.push({
        attributeName: attributeId,
        value: attributeObject?.value,
        unit: {
          _id: unit._id,
          name: unit.name,
        },
      });
    }

    setFieldValue("attributes", updatedAttributes);
  };

  const handleSecondSelectChange =
    (attributeId: any, unit: any) => (e: any) => {
      let attributeObject: any = {};
      if (typeof e === "object") {
        attributeObject = e;
      } else if (typeof e === "string") {
        attributeObject = {
          value: e,
          _id: Date.now(),
        };
      }
      const updatedAttributes = values.attributes.map((attribute: any) => {
        if (attribute.attributeName === attributeId) {
          return {
            ...attribute,
            value: attributeObject?.value,
            unit: {
              _id: unit._id,
              name: unit.name,
            },
          };
        }
        return attribute;
      });

      const attributeExists = updatedAttributes.some(
        (attribute: any) => attribute.attributeName === attributeId
      );

      if (!attributeExists) {
        updatedAttributes.push({
          attributeName: attributeId,
          value: attributeObject?.value,
          unit: {
            _id: unit._id,
            name: unit.name,
          },
        });
      }

      setFieldValue("attributes", updatedAttributes);
    };

  const handleMappingAccessory = async (value: any) => {
    await setFieldValue("storeMappedProduct", [value]);
    setFieldTouched("storeMappedProduct");
  };

  const handleDelete = async (index: number) => {
    console.log("index", index);
    try {
        const { _id, accessoryImages } = values;
        const res = isEdit
            ? await EditDeleteImage(_id, accessoryImages[index])
            : await AddDeleteImage(accessoryImages[index]);

        if (res.data.status) {
           const temp = accessoryImages;
           temp.splice(index, 1);
           setFieldValue("accessoryImages", temp);
            toast.success("Image deleted succcessfully");
        } else {
            toast.error(res.data.message);
        }
    } catch (error:any) {
        console.error("Error during delete operation:", error);
        toast.error((error.response?.data?.message || error.message || "Unknown error"));
    } finally {
        // You can handle any cleanup here if needed, e.g., setLoading(false);
    }
};


  return (
    <>
      <div className="container ms-1">
        <div className="row">
          <div className="col-md-8">
            <div className="card mb-5 mb-xl-10">
              <div className="card-header border-1 cursor-pointer">
                <div className="card-title m-0">
                  <div
                    role={"button"}
                    className="btn btn-sm btn-light me-5"
                    onClick={() => navigate("/accessory")}
                  >
                    <i className="fa-solid fa-arrow-left fs-2"></i>
                  </div>
                  <h3 className="fw-bolder m-0">
                    {isEdit ? "Edit" : "Add"} Accessory
                  </h3>
                </div>
              </div>
              <div className="card-body p-9">
                <div className="row mb-6">
                  <label className="col-lg-2 col-form-label required fw-bold fs-6">
                    Name
                  </label>
                  <div className="col-lg-10">
                    <input
                      disabled={isloading}
                      placeholder="Accessory Name"
                      {...getFieldProps("accessoryName")}
                      className={clsx("form-control bg-transparent", {
                        "is-invalid":
                          touched?.accessoryName && errors?.accessoryName,
                      })}
                      type="text"
                    />
                    {touched?.accessoryName && errors?.accessoryName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.accessoryName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-2 col-form-label required fw-bold fs-6">
                    Brand
                  </label>
                  <div className="col-lg-4">
                    <input
                      placeholder="Enter Brand"
                      disabled={isloading}
                      {...getFieldProps("brand")}
                      className={clsx("form-control bg-transparent", {
                        "is-invalid": touched?.brand && errors?.brand,
                      })}
                      type="text"
                    />
                    {touched?.brand && errors?.brand && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.brand}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <label className="col-lg-2 col-form-label required fw-bold fs-6">
                    Model
                  </label>
                  <div className="col-lg-4">
                    <input
                      placeholder="Enter model"
                      disabled={isloading}
                      {...getFieldProps("model")}
                      className={clsx("form-control bg-transparent", {
                        "is-invalid": touched?.model && errors?.model,
                      })}
                      type="text"
                    />
                    {touched?.model && errors?.model && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.model}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-2 col-form-label required fw-bold fs-6">
                    Model Number
                  </label>
                  <div className="col-lg-4">
                    <input
                      placeholder="Enter Model Number"
                      disabled={isloading}
                      {...getFieldProps("modelNumber")}
                      className={clsx("form-control bg-transparent", {
                        "is-invalid":
                          touched?.modelNumber && errors?.modelNumber,
                      })}
                      type="text"
                    />
                    {touched?.modelNumber && errors?.modelNumber && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.modelNumber}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <label className="col-lg-2 col-form-label required fw-bold fs-6">
                    SKU
                  </label>
                  <div className="col-lg-4">
                    <input
                      placeholder="Enter SKU"
                      disabled={isloading}
                      {...getFieldProps("sku")}
                      className={clsx("form-control bg-transparent", {
                        "is-invalid": touched?.sku && errors?.sku,
                      })}
                      type="text"
                    />
                    {touched?.sku && errors?.sku && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.sku}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-2 col-form-label required fw-bold fs-7">
                    Description
                  </label>
                  <div className="col-lg-10">
                    <textarea
                      rows={4}
                      disabled={isloading}
                      autoComplete="off"
                      placeholder="Description"
                      {...getFieldProps("description")}
                      className="form-control bg-transparent"
                    />
                    {touched?.description && errors?.description && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.description}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="d-flex flex-column flex-md-row w-md-50 w-100 pe-md-9">
              <div className="d-flex flex-column flex-md-column w-md-100  ">
                <label className="form-label fs-6 fw-bolder text-dark">
                  Notes
                </label>
                <textarea
                  autoComplete="off"
                  placeholder="Notes"
                  {...getFieldProps("notes")}
                  className="form-control bg-transparent"
                />
              </div>
            
          </div> */}
            <div className="card mb-5 mb-xl-10">
              <div className="card-body">
                <div className="rounded border border-dotted border-primary border-4 p-4">
                  <h5 className="fw-bolder mb-5">Images</h5>
                  <DropBox
                    onChange={handleDropFiles}
                    multiple={true}
                    maxFiles={10}
                  />
                  <ImageGrid
                    images={values.accessoryImages}
                    handleDelete={handleDelete}
                    name="somname"
                  />
                  {touched?.accessoryImages && errors?.accessoryImages && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.accessoryImages}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card mb-5 mb-xl-10">
              <div className="card-body">
                <h5 className="fw-bolder mb-5">Specifications</h5>
                {values &&
                  values.subcategory &&
                  values.regions &&
                  values.regions[0] &&
                  values.subcategory[0] &&
                  values.subcategory[0].supportedAttributes.map(
                    (attribute: any) => {
                      const selectedUnit = selectedValues[attribute._id];
                      const supportedValues = selectedUnit
                        ? selectedUnit.supportedValues
                        : [];
                      // const selectedValue = supportedValues && supportedValues.filter((data1:any) => {
                      //   const filteredresult = values?.attributes?.filter((data:any)=>data.attributeName == attribute.attributeName)
                      //   return data1.value == filteredresult?.[0]?.value
                      // })
                      let filteredResult = values?.attributes?.filter(
                        ({ attributeName }: { attributeName: any }) =>
                          attributeName === attribute.attributeName
                      );

                      if (filteredResult.length > 0) {
                        const valueToAdd = filteredResult?.[0]?.value;

                        // Check if the value already exists in supportedValues
                        const valueExists = supportedValues.some(
                          (supportedValue: any) =>
                            supportedValue.value === valueToAdd
                        );

                        if (!valueExists) {
                          supportedValues.push({
                            value: valueToAdd,
                            _id: Date.now(),
                          });
                        }
                      }

                      const selectedValue = supportedValues.filter(
                        (supportedValue: any) =>
                          supportedValue.value === filteredResult?.[0]?.value
                      );

                      return (
                        <div key={attribute._id} className="row mb-6">
                          <label className="col-lg-2 col-form-label required fw-bold fs-6">
                            {attribute && attribute.attributeName}
                          </label>
                          <div className="col-lg-4">
                            <select
                              value={JSON.stringify(selectedUnit)}
                              disabled={isloading}
                              className="form-select form-select-white"
                              onChange={(e) => {
                                handleFirstSelectChange(
                                  attribute._id,
                                  attribute.supportedUnits.findIndex(
                                    (unit: any) =>
                                      unit.name ===
                                      JSON.parse(e.target.value).name
                                  )
                                )(e);
                              }}
                            >
                              <option>Please choose one option</option>
                              {attribute &&
                                attribute.supportedUnits &&
                                attribute.supportedUnits
                                  .filter((unit: any) =>
                                    unit.regions.includes(
                                      values?.regions?.[0]?.id ||
                                        values?.regions?.[0]?._id ||
                                        ""
                                    )
                                  )
                                  .map((option: any, index: any) => {
                                    return (
                                      <option
                                        key={index}
                                        value={JSON.stringify(option)}
                                      >
                                        {option.name}
                                      </option>
                                    );
                                  })}
                            </select>
                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex gap-2 align-items-center">
                              <SelectComponent
                                options={supportedValues}
                                selectedOption={selectedValue?.[0]?.value}
                                selectedKey={"value"}
                                onSelect={handleSecondSelectChange(
                                  attribute.attributeName,
                                  selectedUnit
                                )}
                                onInputChange={handleSecondSelectChange(
                                  attribute.attributeName,
                                  selectedUnit
                                )}
                              />
                              {AttributeToTranslated ==
                              attribute.attributeName ? (
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card p-10 mb-5">
              <div className="container p-0 m-0">
                <div className="row mb-5">
                  <div className="col-md-12">
                    <label className="form-label fs-6 fw-bolder text-dark required ">
                      Category
                    </label>
                    <div onClick={() => setFieldTouched("category")}>
                      <Multiselect
                        options={allCategory}
                        style={style}
                        disable={isloading}
                        customArrow={<CustomArrow />}
                        customCloseIcon={<CustomCrossButton />}
                        selectionLimit={1}
                        selectedValues={values.category}
                        displayValue={"categoryName"}
                        placeholder="Select Category"
                        onSelect={async (e) => {
                          await setFieldValue("category", e);
                          setFieldTouched("category");
                        }}
                        onRemove={async (e) => {
                          await setFieldValue("category", e);
                          // await setFieldValue("subcategory", []);
                          setFieldTouched("category");
                        }}
                        selectedValueDecorator={(
                          value: string,
                          option: any
                        ) => {
                          return (
                            <CustomTooltip value={value} option={option} />
                          );
                        }}
                      />
                    </div>
                    {touched?.category && errors?.category && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.category}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Sub Category
                  </label>
                  <div onClick={() => setFieldTouched("subcategory")}>
                    <Multiselect
                      options={values?.category?.[0]?.subcategory ?? []}
                      style={style}
                      displayValue={"subcategoryName"}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      selectionLimit={1}
                      disable={isloading}
                      selectedValues={values.subcategory}
                      placeholder="Select Subcategory"
                      onSelect={async (e) => {
                        await setFieldValue("subcategory", e);
                        setFieldTouched("subcategory");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                      // onRemove={async (e) => {
                      //   await setFieldValue("regions", e);
                      //   setFieldTouched("regions");
                      // }}
                    />
                  </div>
                  {touched?.subcategory && errors?.subcategory && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.subcategory}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card p-10 mb-5">
              <div className="container p-0 m-0">
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label fs-6 fw-bolder text-dark required ">
                      Regions
                    </label>
                    <div onClick={() => setFieldTouched("regions")}>
                      <Multiselect
                        options={allRegions}
                        style={style}
                        disable={isloading}
                        customArrow={<CustomArrow />}
                        customCloseIcon={<CustomCrossButton />}
                        selectedValues={values.regions}
                        selectionLimit={1}
                        displayValue={"regionName"}
                        placeholder="Select Regions"
                        onSelect={async (e) => {
                          await setFieldValue("regions", e);
                          setFieldTouched("regions");
                        }}
                        onRemove={async (e) => {
                          await setFieldValue("regions", e);
                          setFieldTouched("regions");
                        }}
                        selectedValueDecorator={(
                          value: string,
                          option: any
                        ) => {
                          return (
                            <CustomTooltip value={value} option={option} />
                          );
                        }}
                      />
                    </div>
                    {touched?.regions && errors?.regions && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.regions}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="card p-10 mb-5">
              <div className="container p-0 m-0">
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label fs-6 fw-bolder text-dark required ">
                      Mapped Store-Accessory
                    </label>
                    {/* <div onClick={() => handleMapAccessoryModalOpen()}>
                      <input
                        disabled={true}
                        placeholder="Store-Product"
                        value={values.storeMappedProduct?.["0"]?.title}
                        className={clsx("form-control bg-transparent", {
                          "is-invalid":
                            touched?.storeMappedProduct &&
                            errors?.storeMappedProduct,
                        })}
                        type="text"
                      />
                 
                    </div> */}
                    <div className="input-group mb-3">
                      <input
                        disabled={true}
                        placeholder="Store-Product"
                        value={values.storeMappedProduct?.["0"]?.title}
                        className={clsx("form-control bg-transparent", {
                          "is-invalid":
                            touched?.storeMappedProduct &&
                            errors?.storeMappedProduct,
                        })}
                        type="text"
                      />
                      <span
                        className="input-group-text p-2"
                        id="basic-addon1"
                        aria-disabled={isloading}
                        style={{ cursor: "pointer", height: "45px" }}
                        onClick={() => handleMapAccessoryModalOpen()}
                      >
                        <span>
                          <img
                            alt="google translate"
                            src={toAbsoluteUrl(
                              "/media/icons/duotune/art/select.svg"
                            )}
                            className="h-30px"
                          />
                        </span>
                        {/* {singleAutoLoading && (
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          )} */}
                      </span>
                    </div>
                    {touched?.storeMappedProduct && errors?.storeMappedProduct && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{errors?.storeMappedProduct}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {MapAccessoryModalOpen && (
        <MappedAccessoryFormModal
          currentRecord={undefined}
          currentMappedAccessory={values.storeMappedProduct}
          onClose={handleMapAccessoryModalOpen}
          onChange={handleMappingAccessory}
          currentregion={
            values?.regions?.["0"]?.id || values?.regions?.[`0`]?._id
          }
        />
      )}
    </>
  );
};

export default AccessoryForm;
