import { FC } from "react";
import UnitFormModal from "../../../generatedFormModal/UnitFormModal";

const EditUnit: FC = () => {
  return (
    <>
      <UnitFormModal isEdit={true} />
    </>
  );
};

export default EditUnit;
