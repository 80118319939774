import React from "react";
import { useCallback } from "react";
import Select from "../../CustomSelect";
import { TOptionType } from "../../../types/CustomSelect";
import { TActiveInActiveFilterProps } from "../../../types/Filters/ActiveInActiveFilter";
import Multiselect from "multiselect-react-dropdown";
import CustomArrow from "../../../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../../../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip1 from "../../../utils/CustomStyleComponent/TooltipNewComponent";
import "../../../app/pages/Units/UnitsForm.css";

const ActiveInActiveFilter = ({
  onStatusFilterChange,
  currentStatusFilter,
}: TActiveInActiveFilterProps) => {
  const OptionsStatus: TOptionType<string>[] = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const style = {
    multiSelectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontWeight: "500",
      minwidth: "40px",
      maxwidth: "40px",
      width: "130px",
      height: "44px",
      display: "flex",
      alignItems: "center",

      // styles for the search wrapper
    },
    chips: {
      // To change css chips(Selected options)
      marginRight: "25px",
      marginBottom: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center",
      width: "100%",
    },
  };

  const handleStatusChange = useCallback(
    (v: any) => {
      if (v && v.length > 0) {
        onStatusFilterChange(v?.[0]);
      } else if (v && v.length <= 0) {
        onStatusFilterChange({ value: "", label: "" });
      }
    },
    [onStatusFilterChange]
  );

  return (
    <Multiselect
      options={OptionsStatus}
      displayValue="label"
      placeholder="Status"
      hidePlaceholder={true}
      style={style}
      showArrow={true}
      customArrow={<CustomArrow />}
      customCloseIcon={<CustomCrossButton />}
      singleSelect={true}
      selectionLimit={1}
      avoidHighlightFirstOption={true}
      showCheckbox={true}
      onSelect={handleStatusChange}
      onRemove={handleStatusChange}
    />
  );
};

export default React.memo(ActiveInActiveFilter);
