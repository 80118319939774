import { Modal } from "react-bootstrap";
import { useCallback } from "react";
import { PemissionDenied } from "../Error/PemissionDenied";
import { TPermissionPromptProps } from "../../types/PermissionPrompt";
import { usePermissions } from "../../utils/Customhooks/permissionHook/usePermissionHook";
import { KTSVG} from "../../_metronic/helpers";

const PermissionPrompt = ({
  title,
  message,
  onCancel,
  onConfirm,
  image,
  moduleName,
}: TPermissionPromptProps) => {
  const { currentActivity, requestAccess } = usePermissions();

  const handleConfirm = useCallback(() => {
    const fn = () => {
      const module = {
        name: moduleName && moduleName.toLowerCase(),
        url:`/${moduleName && moduleName.toLowerCase()}`,
        activities: [currentActivity && currentActivity.toLowerCase()],
      };
      requestAccess(module);
      typeof onCancel === "function" && onCancel();
    };
    fn();
  }, [currentActivity, requestAccess, moduleName, onCancel]);

  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      tabIndex={-1}
      role="dialog"
      show={true}
      dialogClassName="modal-dialog-centered"
      aria-hidden="true"
      onHide={onCancel}
    >
      <div
        className="d-flex justify-content-end w-100"
        onClick={() => {
          typeof onCancel === "function" && onCancel();
        }}
      >
        <KTSVG
          path="/media/icons/duotune/arrows/arr061.svg"
          className="svg-icon-2x cursor-pointer"
        />
      </div>

      <PemissionDenied
        title={title}
        text={message}
        closeIcon={true}
        buttonText={"Request Permission"}
        handleButtonClick={handleConfirm}
        image={image}
      />
    </Modal>
  );
};

export default PermissionPrompt;
