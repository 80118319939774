import React, { useState } from "react";
import "../../../components/TagBox/tooltip.css";

type TTolltipProp = {
  value: string;
  option?: any;
  truncated?:boolean;
};

const CustomTooltip = ({ value, option,truncated }: TTolltipProp) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [highlightId, setHighlightId] = useState("");
  const handleMouseEnter = (id: string) => {
    setShowTooltip(true);
    setHighlightId(id);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const displayValue = truncated ? value.split(" ")[0] : value; // Conditionally split the value or keep it unchanged

  return (
    <div>
      <p
        style={{
          marginBottom: "0",
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          
        }}
        onMouseEnter={() => handleMouseEnter(value)}
        onMouseLeave={handleMouseLeave}
      >
        {displayValue}
      </p>
      {showTooltip && highlightId === value && (
        <div className="tooltip">{value ? value : ""}</div>
      )}

      {/* <div className="tooltip">{value ? value : ""}</div> */}
    </div>
  );
};

export default CustomTooltip;
