import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ScrappingPage from "../generatedTablePage/ScrappingPage";

const ScrappingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ScrappingPage />} />
    </Routes>
  );
};
export default ScrappingRoutes;
