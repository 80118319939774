import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LanguageState {
  users: object[];
  perPage: number;
  currentPage: number;
  totalPage: number;
  searchTerm: string;
  searchTermFormGoogleLang:string
  loading: boolean;
  rerender1: boolean;
}

const initialState: LanguageState = {
  users: [],
  perPage: 10,
  currentPage: 1,
  totalPage: 0,
  searchTerm: "",
  searchTermFormGoogleLang:"",
  loading: false,
  rerender1: false,
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setUsers: (
      state: LanguageState,
      { payload }: PayloadAction<object[]>
    ) => {},
    setPerPage: (state: LanguageState, { payload }: PayloadAction<number>) => {
      state.perPage = payload;
    },
    setCurrentPage: (
      state: LanguageState,
      { payload }: PayloadAction<number>
    ) => {
      state.currentPage = payload;
    },
    setSearchTerm: (
      state: LanguageState,
      { payload }: PayloadAction<string>
    ) => {
      state.searchTerm = payload;
    },
    setSearchTermForGoogleLang: (
      state: LanguageState,
      { payload }: PayloadAction<string>
    ) => {
      state.searchTermFormGoogleLang = payload;
    },
    setTotalPage: (
      state: LanguageState,
      { payload }: PayloadAction<number>
    ) => {
      state.totalPage = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setLoading: (state: LanguageState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setRerender: (
      state: LanguageState,
      { payload }: PayloadAction<boolean>
    ) => {
      state.rerender1 = payload;
    },
  },
});

export const {
  setUsers,
  setPerPage,
  setCurrentPage,
  setTotalPage,
  setSearch,
  setLoading,
  setRerender,
  setSearchTerm,
  setSearchTermForGoogleLang
} = languageSlice.actions;
export default languageSlice.reducer;
