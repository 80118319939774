import React from "react";

const CustomArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="none"
        stroke="#7e8299"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m2 5 6 6 6-6"
      />
    </svg>
  );
};

export default CustomArrow;
