import Multiselect from "multiselect-react-dropdown";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import CustomArrow from "../utils/CustomStyleComponent/ArrowComponent";
import CustomCrossButton from "../utils/CustomStyleComponent/CrossButtonComponent";
import CustomTooltip from "../utils/CustomStyleComponent/TooltipComponent";
import DropBox from "../components/DropBox";
import ImageGrid from "../components/ImageGrid";
import {
  AddDeleteImage,
  EditDeleteImage,
  quizImageUpload,
} from "../generatedRequest/quiz";
import { toast } from "react-toastify";
import { KTSVG } from "../_metronic/helpers";
import "./Quiz.css";
import { useState } from "react";

type TQuizFormProp = {
  errors: any;
  isSubmitting: boolean;
  touched: any;
  setFieldTouched: any;
  getFieldProps: Function;
  setFieldValue: Function;
  values: any;
  allSubCategories: any;
  isEdit: boolean;
  isloading: boolean;
  allRegions: any;
};

const QuizForm = ({
  errors,
  isEdit,
  isloading,
  isSubmitting,
  touched,
  getFieldProps,
  setFieldValue,
  values,
  setFieldTouched,
  allSubCategories,
  allRegions,
}: TQuizFormProp) => {
  const style = {
    multiselectContainer: {
      minheight: "44px",
      border: "1px solid var(--bs-gray-100)",

      // styles for the multiselect container
    },
    searchBox: {
      minheight: "44px",
      fontSize: "1.1rem",
      fontWeight: "500",

      // styles for the search wrapper
    },
  };
  const navigate = useNavigate();

  const [scrollPosition, setScrollPosition] = useState(0);

  // Scroll the container to the left or right based on the direction
  const scrollCarousel = (direction: any, index: number) => {
    const container = document.querySelector(`#auto-scroll-container-${index}`);
    if (container) {
      const cardWidth = 210; // Adjust the card width based on your design
      const containerWidth = container.clientWidth;
      const maxScroll = container.scrollWidth - containerWidth;
      let newPosition = scrollPosition;
      if (direction === "left") {
        newPosition = Math.max(0, scrollPosition - cardWidth);
      } else if (direction === "right") {
        const numCards = container.querySelectorAll(".card").length;
        const numVisibleCards = Math.floor(containerWidth / cardWidth);
        if (numCards <= numVisibleCards) {
          return; // Don't scroll right if there are less than or equal to visible cards
        }
        newPosition = Math.min(maxScroll, scrollPosition + cardWidth);
      }
      container.scroll({
        left: newPosition,
        behavior: "smooth",
      });
      setScrollPosition(newPosition);
    }
  };

  const handleDelete = async (questionIndex: any, optionIndex: any) => {
    try {
      const { _id, questions } = values;
      console.log(questions[questionIndex]?.options[optionIndex]?.image);
      const res = isEdit
        ? await EditDeleteImage(
            _id,
            questions[questionIndex]?.options[optionIndex]?.image
          )
        : await AddDeleteImage(
            questions[questionIndex]?.options[optionIndex]?.image
          );

      if (res.data.status) {
        const updatedQuestions = [...questions];
        const updatedOptions = [...updatedQuestions[questionIndex].options];
        updatedOptions[optionIndex] = {
          ...updatedOptions[optionIndex],
          image: "",
        };
        updatedQuestions[questionIndex].options = updatedOptions;
        setFieldValue("questions", updatedQuestions);
        toast.success("Image deleted succcessfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      console.error("Error during delete operation:", error);
      toast.error(
        error.response?.data?.message || error.message || "Unknown error"
      );
    } finally {
    }
  };

  return (
    <div className="container ms-1">
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-5 mb-xl-10">
            <div className="card-header border-1 cursor-pointer">
              <div className="card-title m-0">
                <div
                  role={"button"}
                  className="btn btn-sm btn-light me-5"
                  onClick={() => navigate("/quiz")}
                >
                  <i className="fa-solid fa-arrow-left fs-2"></i>
                </div>
                <h3 className="fw-bolder m-0">
                  {isEdit ? "Edit" : "Add"} Quiz
                </h3>
              </div>
            </div>
            <div className="card-body p-9">
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    SubCategory
                  </label>
                  <div onClick={() => setFieldTouched("subcategory")}>
                    <Multiselect
                      options={allSubCategories}
                      style={style}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      selectionLimit={1}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="subcategoryName"
                      placeholder="Select SubCategory"
                      selectedValues={values.subcategory}
                      onSelect={async (e) => {
                        await setFieldValue("subcategory", e);
                        setFieldTouched("subcategory");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("subcategory", e);
                        setFieldTouched("subcategory");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {touched?.subcategory && errors?.subcategory && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.subcategory}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="form-label fs-6 fw-bolder text-dark required ">
                    Region
                  </label>
                  {/* <div>
                    <Multiselect
                      options={allRegions}
                      style={style}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="regionName"
                      placeholder="Select Region"
                      selectionLimit={1}
                      selectedValues={values.subcategory}
                      onSelect={async (e) => {
                        await setFieldValue("regions", e);
                        setFieldTouched("regions");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("regions", e);
                        setFieldTouched("regions");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div> */}
                  <div onClick={() => setFieldTouched("regions")}>
                    <Multiselect
                      options={allRegions}
                      style={style}
                      disable={isloading}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      selectedValues={allRegions?.filter(
                        (region: any) => region?.id == values?.region
                      )}
                      selectionLimit={1}
                      displayValue={"regionName"}
                      placeholder="Select Region"
                      onSelect={async (e) => {
                        await setFieldValue("region", e?.[`0`]?.id);
                        setFieldTouched("region");
                      }}
                      onRemove={async (e) => {
                        await setFieldValue("region", e);
                        setFieldTouched("region");
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                  {touched?.region && errors?.region && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{errors?.region}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex mb-10  justify-content-between align-items-center">
        <div>
          <h4>Total Questions : {values?.questions.length}</h4>
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            let data = { title: "", options: [{ image: "", value: "" }] };
            let newArray = values?.questions;
            newArray.push(data);
            setFieldValue("questions", newArray);
          }}
        >
          {"Add New Question"}
        </button>
      </div>

      {values?.questions?.map((question: any, index: number) => {
        const questionvariable = `questions[${index}].title`;
        const attributevariabele = `questions[${index}].attributeName`;
        return (
          <div className="card mb-5 mb-xl-10">
            <div className="row p-2">
              <div className="col-lg-4">
                <label className="col-lg-6 col-form-label required fw-bold fs-7">
                  Question
                </label>
                <div className="col-lg-12 mb-4">
                  <textarea
                    rows={3}
                    disabled={isloading}
                    autoComplete="off"
                    placeholder="Please Enter your question"
                    {...getFieldProps(`${questionvariable}`)}
                    className="form-control bg-transparent"
                  />
                  {touched?.questions?.[index]?.title &&
                    errors?.questions?.[index]?.title && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {errors?.questions?.[index]?.title}
                          </span>
                        </div>
                      </div>
                    )}
                </div>

                <div className="col-lg-12">
                  <label className="col-lg-6 col-form-label required fw-bold fs-7">
                    Attribute
                  </label>
                  <div
                    onClick={() =>
                      setFieldTouched(`questions[${index}].attributeName`)
                    }
                  >
                    <Multiselect
                      options={
                        allSubCategories?.filter(
                          (subcategory: any) =>
                            subcategory?._id == values?.subcategory?.[`0`]?._id
                        )?.[`0`]?.supportedAttributes
                      }
                      style={style}
                      // disable={true}
                      selectionLimit={1}
                      customArrow={<CustomArrow />}
                      customCloseIcon={<CustomCrossButton />}
                      displayValue="attributeName"
                      placeholder="Select Attribute"
                      selectedValues={allSubCategories?.filter(
                        (subcategory: any) =>
                          subcategory?._id == values?.subcategory?.[`0`]?._id
                      )?.[`0`]?.supportedAttributes?.filter(
                        (data: any) =>
                          data?.attributeName == question?.attributeName
                      )}
                      onSelect={async (e) => {
                        let temp = e?.[`0`]?.attributeName;
                        await setFieldValue(attributevariabele, temp);
                        setFieldTouched(
                          `questions[${index}].attributeName`,
                          true
                        );
                      }}
                      onRemove={async (e) => {
                        let temp = e?.[`0`]?.attributeName;
                        await setFieldValue(attributevariabele, temp);
                        setFieldTouched(
                          `questions[${index}].attributeName`,
                          true
                        );
                      }}
                      selectedValueDecorator={(value: string, option: any) => {
                        return <CustomTooltip value={value} option={option} />;
                      }}
                    />
                  </div>
                </div>
                {touched?.questions?.[index]?.attributeName &&
                  errors?.questions?.[index]?.attributeName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {errors?.questions?.[index]?.attributeName}
                        </span>
                      </div>
                    </div>
                  )}
                <div className="d-flex mt-5 justify-content-between align-items-center">
                  <button
                    className="btn btn-sm btn-secondary m-2"
                    onClick={() => {
                      console.log(index);
                      let prevarray = values?.questions;
                      let elementdelted = prevarray?.splice(index, 1);
                      setFieldValue("questions", prevarray);
                    }}
                  >
                    {"Delete Question"}
                  </button>
                  <button
                    className="btn btn-sm btn-primary m-2"
                    onClick={() => {
                      let data = { image: "", value: "" };
                      let newArray = values?.questions;
                      newArray[index]?.options?.push(data);
                      setFieldValue("questions", newArray);
                    }}
                  >
                    {"Add New Option"}
                  </button>
                </div>
              </div>

              <div className="col-lg-8 mt-6 border p-8">
                {/* Left arrow button */}
                <div
                  className={clsx("arrow-button left", {
                    disabled: question?.options?.length <= 4,
                  })}
                  onClick={() => scrollCarousel("left", index)}
                >
                  <i className="fa fa-chevron-left"></i>
                </div>
                <div
                  id={`auto-scroll-container-${index}`}
                  className="d-flex overflow-auto border auto-scroll-container"
                >
                  {question?.options?.map((data: any, subIndex: number) => {
                    const questionvariableimage = `questions[${index}].options[${subIndex}].image`;
                    const questionvariabletitle = `questions[${index}].options[${subIndex}].value`;
                    const image =
                      values?.questions[index]?.options[subIndex]?.image;
                    const errorsImage =
                      touched?.questions?.[index]?.options?.[subIndex]?.image &&
                      errors?.questions?.[index]?.options?.[subIndex]?.image;
                    const errorsTitle =
                      touched?.questions?.[index]?.options?.[subIndex]?.value &&
                      errors?.questions?.[index]?.options?.[subIndex]?.value;
                    return (
                      <div className="card p-5 w-200px bg-white h-300px shadow me-2">
                        <div className="d-flex justify-content-end">
                          <div
                            onClick={() => {
                              let prevarray = values?.questions;
                              let elementdelted = prevarray[
                                index
                              ].options.splice(subIndex, 1);
                              setFieldValue("questions", prevarray);
                            }}
                          >
                            {/* <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon-1.2x cursor-pointer"
                            /> */}
                            <i className="fa fa-trash cursor-pointer"></i>
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            setFieldTouched(
                              `questions[${index}].options[${subIndex}].image`
                            )
                          }
                        >
                          <DropBox
                            onChange={async (e: any[]) => {
                              const res = await quizImageUpload(e);
                              if (res.data.status) {
                                let images = res.data.data.quizImageURL?.[0];
                                setFieldValue(
                                  `${questionvariableimage}`,
                                  images
                                );
                              } else {
                                toast.error("Something went wrong");
                              }
                            }}
                          ></DropBox>

                          <ImageGrid
                            images={[image]}
                            handleDelete={() => handleDelete(index, subIndex)}
                          ></ImageGrid>
                          {errorsImage &&
                            touched?.questions?.[index]?.options?.[subIndex]
                              ?.image && (
                              <div className="fv-plugins-message-container text-center">
                                <div
                                  className="fv-help-block"
                                  style={{ marginTop: "-15px" }}
                                >
                                  <span role="alert">{errorsImage}</span>
                                </div>
                              </div>
                            )}
                        </div>

                        <div>
                          <input
                            className="form-control bg-transparent"
                            {...getFieldProps(`${questionvariabletitle}`)}
                          />
                          {errorsTitle &&
                            touched?.questions?.[index]?.options?.[subIndex]
                              ?.value && (
                              <div className="fv-plugins-message-container text-center">
                                <div className="fv-help-block">
                                  <span role="alert">{errorsTitle}</span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* Right arrow button */}
                <div
                  className={clsx("arrow-button right", {
                    disabled: question?.options?.length <= 4,
                  })}
                  onClick={() => scrollCarousel("right", index)}
                >
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuizForm;
