import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Modal } from "react-bootstrap";

const TranslationSkeleton = ({ fields }: any) => {
  return (
    
    <div className="d-flex flex-column flex-md-row flex-md-wrap w-100 w-md-100 pt-1 pe-1">
      {[...Array(fields)].map((_, index) => {
        return (
          <div
            className="d-flex flex-column flex-md-column p-3"
            key={index}
            style={{ width: "150px" }}
          >
            <div>
              <div className="d-flex card-body flex-column w-100">
                <div className="d-flex flex-row mb-3">
                 
                  <Skeleton className="me-6" width={65} height={20} />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TranslationSkeleton;
