import { TUserModel, defaultUser } from "./user"


export const  defaultLogin = {
  status:false,
    message:"",
    data:defaultUser,
    token: "",
    refreshToken: ""
}

export const TLoginModel = typeof defaultLogin

export type TLoginModel = {
    status:boolean,
    message:string,
    data?:TUserModel,
    token?: string
    refreshToken?: string
  }
  