import React from "react";
import QuizFormModal from "../../generatedFormModal/QuizFormModal";


const AddQuiz = () => {
  return (
    <div>
      <QuizFormModal isEdit={false} />
    </div>
  );
};

export default AddQuiz;
