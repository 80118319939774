import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TTableSkeletonProps } from '../../types/TableSkeleton';

const TableSkeleton = ({ rows, columns }: TTableSkeletonProps) => {
  return (
    <div className='card p-4'>
      <div className='card-body py-4'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-5'>
            <thead>
              <tr>
                {[...Array(columns)].map((_, index) => {
                  return <th key={index}><Skeleton /></th>;
                })}
              </tr>
            </thead>
            <tbody>
              {[...Array(rows)].map((_, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {[...Array(columns)].map((_, colIndex) => {
                      const cellIndex = rowIndex * columns + colIndex;
                      return <td key={cellIndex}><Skeleton height={24} /></td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TableSkeleton;