import React, { useCallback, useState } from "react";
import axios from "axios";
import { useDelete } from "../customHooks/useDelete/useDelete";
import useEdit from "../customHooks/useEdit/useEdit";
import ConfirmationPrompt from "../components/ConfirmationPrompt";
import { toast } from "react-toastify";
import { ActionCells } from "../components/ActionCells";
import { MODULE_DELETE_URL } from "../app/request/userRequest";
import { TModuleDelete } from "../types/Network_ Request/moduleWiseApiType/deleteApi";
import { TTableAction } from "../types/TableActions";
import PermissionPrompt from "../components/PermissionPrompt";
import { usePermissions } from "../utils/Customhooks/permissionHook/usePermissionHook";
import { deleteRetailerData, updateStatus } from "../generatedRequest/retailer";
import { useStatus } from "../customHooks/useStatus/useStatus";
import { setRerender } from "../generatedSlice/retatilerDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

const moduleName = "Retatilerdata";

const RetatilerdataTableAction = ({ retailer }: any) => {
  const { rerender1 } = useSelector((state: RootState) => state.retatilerData);
  const dispatch = useDispatch();
  const { doEdit } = useEdit(moduleName);
  const [showPermissionPrompt, setShowPermissionPrompt] =
    useState<boolean>(false);

  const handleAfterDelete = useCallback((flag: boolean, response: any) => {
    if (flag) {
      toast.success("record deleted");
      dispatch(setRerender(!rerender1));
    } else {
      toast.error("error");
    }
  }, [rerender1,dispatch]);

  const handleAfterStatus = useCallback((status: boolean, res: any) => {
    if (status) {
      toast.success("status updated successfully");
      dispatch(setRerender(!rerender1));
    } else {
      //TODO: make error when go live
      toast.error("something went wrong");
    }
  }, [rerender1,dispatch]);

  const { canDo, currentActivity } = usePermissions();

  const { onDelete, deleteRecord, showPrompt, onCancel, loading } = useDelete(
    deleteRetailerData,
    true,
    handleAfterDelete
  );

  const {
    onStatus,
    showStatusPrompt,
    statusloading,
    onstatusCancel,
    ChangeStatus,
  } = useStatus(updateStatus, true, handleAfterStatus);
  const actions = ["Change Status", "Delete"];

  const handleCancel = useCallback(() => {
    const fn = () => {
      setShowPermissionPrompt(false);
    };
    fn();
  }, []);

  const handleSelect = useCallback(
    (selectedAction: string) => {
      switch (selectedAction) {
        case "Change Status":
          onStatus(retailer);
          break;
          break;
        case "Delete":
          // if (canDo(moduleName, "Delete")) {
          onDelete(retailer?._id);
          // } else {
          //   setShowPermissionPrompt(true);
          // }
          break;
        default:
          break;
      }
    },
    [canDo, doEdit, retailer, onDelete]
  );
  return (
    <>
      {showPermissionPrompt && (
        <PermissionPrompt
          title={"Access Denied"}
          image="/media/auth/permission-error-light.svg"
          message={currentActivity ? currentActivity : ""}
          onCancel={handleCancel}
          moduleName={moduleName}
        />
      )}
      {showPrompt && (
        <ConfirmationPrompt
          title={"Delete Record"}
          message={"Are you sure to delete this record?"}
          onConfirm={deleteRecord}
          onCancel={onCancel}
          isloading={loading}
        />
      )}

      {showStatusPrompt && (
        <ConfirmationPrompt
          title="Change Status"
          message="Are you sure you want to change the status ?"
          onCancel={onstatusCancel}
          onConfirm={ChangeStatus}
          isloading={statusloading}
        />
      )}
      <ActionCells actions={actions} onSelect={handleSelect} />
    </>
  );
};
export default RetatilerdataTableAction;
