import CategoryForm from "../generatedForms/Category";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import {
  addCategory,
  editCategory,
  getSingleCategory,
} from "../generatedRequest/category";
import { useSearchParams } from "react-router-dom";
import { getAttribute } from "../generatedRequest/attribute";
import FormSkeleton from "../components/Skeleton/FormSkeleton";
import { attributeSchema } from "../utils/validationSchema/attributeSchema";
import { useNavigate } from "react-router-dom";
import { getSubCategory } from "../generatedRequest/subcategory";
import { CategorySchema } from "../generatedValidations/CategorySchema";
import { TCategoryModalProps, TFormValues, TPayload, TSubcategory } from "../types/Module/Category/CategoryFormModal";



const CategoryFormModal = ({ isEdit }: TCategoryModalProps) => {
  const [loading, setLoading] = useState(false);
  const [queryParameters] = useSearchParams();
  const [allSubCategories, setAllSubCategories] = useState<TSubcategory[]>([]);
  const [initload, setInitload] = useState(true);
  const categoryID = queryParameters.get("q") || "";
  const [initialValues, setInitialValues] = useState<TFormValues>({
    categoryName: "",
    subcategory: [],
    notes: [],
  });

  const handleSubmit = async (payload: TPayload) => {
    setLoading(true);
    try {
      const res = isEdit
        ? await editCategory(payload, categoryID)
        : await addCategory(payload);
      if (res.data.status) {
        isEdit
          ? toast.success("Category is edited Successfully")
          : toast.success("Category is added Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Network Error");
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<TFormValues>({
    initialValues: initialValues,
    validationSchema: CategorySchema,
    onSubmit: (values, { setSubmitting }) => {
      let payload = {
        categoryName: values.categoryName,
        subcategory: values.subcategory.map((item: TSubcategory) => item._id),
        notes: values.notes || [],
      };
      handleSubmit(payload);
    },
  });
  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      try {
        const response = await getSingleCategory(categoryID); // Call your API to get user data
        if (response.data.status) {
          formik.setValues(response.data.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        setLoading(false);
        setInitload(false);
      }
    };

    if (isEdit) {
      fetchCategoryData();
    }
    else {
      setInitload(false)
    }
  }, [isEdit, categoryID]);

  useEffect(() => {
    const fn = async () => {
      try {
        const resp = await getSubCategory();
        if (resp.data.status) {
          setAllSubCategories(resp.data.data.data);
        }
      } catch (error) {
        toast.error("Network error");
      }
    };

    fn();
  }, []);

  if (loading && initload) {
    return <FormSkeleton numberOfInput={8} />;
  }

  return (
    <>
      <CategoryForm
        allSubCategories={allSubCategories}
        {...formik}
        isloading={loading}
        isEdit={isEdit}
      />

      <div className="d-flex justify-content-end">
        <button
          type="button"
          id="kt_password_reset_submit"
          disabled={loading || !formik.isValid}
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {!loading && <span className="indicator-label">Save</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default CategoryFormModal;
