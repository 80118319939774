import * as Yup from 'yup'



export const unitSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Minimum 2 characters.')
    .max(50, 'Maximum 50 characters.')
    .required('Name is required.'),
  symbol: Yup.string()
    .min(1, 'Minimum 1 characters.')
    .max(50, 'Maximum 50 characters.')
    .required('Symbol is required.'),
  unitType:Yup.string()
  .min(2, 'Minimum 2 characters.')
  .max(50, 'Maximum 50 characters.')
  .required('Unit Type is required.'),    
  regions: Yup.array()
    .min(1, 'Unit should have atleast one Region.')
});
