import { Modal } from "react-bootstrap";
import { KTSVG } from "../../_metronic/helpers";
import Badge from "../Badge";

type TModalProps = {
  title: string;
  onCancel?: () => void;
  onBack?: () =>void;
  statusData?: any;
  isAccessory?:boolean;
  //onConfirm?: () => void;
  isloading?: boolean;
};

const CloneRegionModel = ({
  title,
  onCancel,
  onBack,
  isAccessory,
  statusData,
}: //   onConfirm,
//   isloading,
TModalProps) => {
  return (
    <Modal
      className="modal fade"
      id="kt_modal_select_location"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
      show={true}
      dialogClassName="modal-lg modal-dialog-centered"
      aria-hidden="true"
      onHide={onCancel}
    >
      <div className="modal-header">
        <h2 className="modal-title fw-bolder">{title}</h2>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onCancel}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body">
        <table className="table table-bordered">
          <thead>
            <th className="border p-3">{isAccessory ? "Accessory Name" : "Product Name"}</th>
            <th className="border p-3">Region Name</th>
            <th className="border p-3">Status</th>
          </thead>
          {statusData.map((item: any) => {
            return (
              <tbody>
                <td className="border p-3">
                  {item?.res?.data?.data?.accessoryName ? item?.res?.data?.data?.accessoryName : item?.res?.data?.data?.productName}
                </td>
                <td className="border p-3"></td>
                <td className="border p-3">
                  {item.status ? (
                    <Badge variant="success" className="fs-7 fw-semibold">
                      Clone created successfully
                    </Badge>
                  ) : (
                    <Badge variant="danger" className="fs-7 fw-semibold">
                      Cannot clone the record with same region name
                    </Badge>
                  )}
                </td>
              </tbody>
            );
          })}
        </table>
      </div>
      <div className="modal-footer">
        <button id="submit" type="button" className="btn btn-primary" onClick={onBack}>
          Back
        </button>
        <button type="button" className="btn btn-danger" onClick={onCancel}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default CloneRegionModel;
