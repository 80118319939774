import { useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setCurrentPage,
  setPerPage,
  setLoading,
  setAttribute,
  setSearchTerm,
  setTotalPage,
} from "../generatedSlice/attributeSlice";
import useSearchApi from "../customHooks/useSearchApi";
import { getAttribute } from "../generatedRequest/attribute";
import { toast } from "react-toastify";
import TableSkeleton from "../components/TableSkeleton";
import AttributeList from "../generatedTableList/AttributeList";
import { TTableProps } from "../types/TableView";
import TableLoader from "../utils/Loaders/tableLoader";
import { useNavigate } from "react-router-dom";
import AttributeFilters from "../generatedFilters/AttibuteFilters";
import { TOptionType } from "../types/CustomSelect";
import {
  TDataObjectType,
  ThandleAfterSearchData,
} from "../types/Module/Attribute/AttributePage";
import { columns } from "./columns";
import { toAbsoluteUrl } from "../_metronic/helpers";

const AttributePage = () => {
  const dispatch = useDispatch();
  const [initload, setInitload] = useState(true);
  const [filterloading, setFilterLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState<TOptionType<string>>({
    label: "",
    value: "",
  });

  const [unitFilter, setUnitFilter] = useState("");
  const {
    data,
    perPage,
    currentPage,
    totalPage,
    searchTerm,
    loading,
    rerender1,
  } = useSelector((state: RootState) => state.attribute);

  useEffect(() => {
    const fn = async () => {
      const searchQuery = `page=${currentPage}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&unit=${unitFilter}`;
      dispatch(setLoading(true));
      try {
        const response = await getAttribute(searchQuery);
        if (response.data.status) {
          dispatch(setAttribute(response.data.data.data));
          dispatch(
            setTotalPage(Math.ceil(response.data.data.totalCount / perPage))
          );
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error("Network Error");
      } finally {
        dispatch(setLoading(false));
        setInitload(false);
        if (filterloading) {
          setFilterLoading(false);
        }
      }
    };
    fn();
  }, [rerender1, dispatch, statusFilter, unitFilter]);

  useEffect(() => {
    return () => {
      dispatch(setSearchTerm(""));
    };
  }, []);

  function handleAfterSearch(
    isSuceess: boolean,
    data: ThandleAfterSearchData,
    value: number
  ) {
    if (isSuceess) {
      dispatch(setAttribute(data.data.data.data));
      dispatch(setTotalPage(Math.ceil(data.data.data.totalCount / value)));
    }
  }

  const { doSearch, searchLoading } = useSearchApi(
    getAttribute,
    handleAfterSearch
  );

  const handleSearchParams = useCallback(
    (value: string) => {
      dispatch(setCurrentPage(1));
      dispatch(setSearchTerm(value));
      const searchQuery = `page=${"1"}&limit=${perPage}&search=${value}&status=${
        statusFilter?.value
      }&unit=${unitFilter}`;
      doSearch(searchQuery, perPage);
    },
    [perPage, dispatch, doSearch, statusFilter?.value, unitFilter]
  );

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(setCurrentPage(value));
      const searchQuery = `page=${value}&limit=${perPage}&search=${searchTerm}&status=${statusFilter?.value}&unit=${unitFilter}`;
      doSearch(searchQuery, perPage);
    },
    [dispatch, perPage, searchTerm, doSearch, statusFilter?.value, unitFilter]
  );

  const handlePerPageChange = useCallback(
    (value: number) => {
      dispatch(setPerPage(value));
      const searchQuery = `page=${currentPage}&limit=${value}&search=${searchTerm}&status=${statusFilter?.value}&unit=${unitFilter}`;
      doSearch(searchQuery, value);
    },
    [
      dispatch,
      currentPage,
      searchTerm,
      doSearch,
      statusFilter?.value,
      unitFilter,
    ]
  );
  const handleStatusFilter = useCallback((data: TOptionType<string>) => {
    setStatusFilter(data);
    setFilterLoading(true);
  }, []);

  const handleUnitFilter = useCallback((data: TDataObjectType[]) => {
    let payload = data.map((value) => value.id).join(",");
    setUnitFilter(payload);
    setFilterLoading(true);
  }, []);

  const configuration: TTableProps = {
    columns: columns,
    rows: data,
    showPagination: true,
    showSearch: true,
    showPerPage: true,
    paginationData: {
      perPage: perPage,
      currentPage: currentPage,
      totalPage: totalPage,
    },
    onPageChange: handlePageChange,
    onPerPageChange: handlePerPageChange,
    handleSearchParams: handleSearchParams,
    searchPlaceholder: "Search Attributes",
    searchLoading: searchLoading || filterloading,
    showFilter: true,
    filters: (
      <AttributeFilters
        onStatusFilterChange={handleStatusFilter}
        onUnitFilterChange={handleUnitFilter}
        currentUnitFilter={unitFilter}
        currentStatusFilter={statusFilter}
      />
    ),
  };

  const navigate = useNavigate();

  if (loading && initload) {
    return <TableSkeleton rows={10} columns={6} />;
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div className="d-flex align-items-center" style={{ gap: "5px" }}>
          <h2>Attributes </h2>

          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/icons/duotune/art/exclamation-icon.svg")}
            className="h-20px cursor-pointer"
            onClick={() => navigate(`/helps?moduleName=Attribute`)}
          />
        </div>
        <Button
          variant="primary"
          size="medium"
          handleButtonClick={() => navigate("add")}
        >
          Add Attribute
        </Button>
      </div>
      <AttributeList {...configuration} />
    </>
  );
};

export default AttributePage;
