
      import React from 'react'
      import AttributeFormModal from '../../generatedFormModal/AttributeFormModal'

      const initialValues={name:"7m7h2",supported_units:"s1t2b",status:"wx3pd"}
      
      const EditAttribute=({currentRecord}:{currentRecord?:any})=> {
        return (
          <div>
           <AttributeFormModal isEdit={true} /> 
          </div>
        )
      }
      
      export default EditAttribute

      