import axios from "axios";
import { TAddBulkLanguagePayload } from "../../types/Module/Language/LanguageRequest";


const BASE_URL = process.env.REACT_APP_API_URL;
const DELETE_LANGUAGE_URL = BASE_URL + "/languages/";
const SAVE_LANGUAGE_URL =   BASE_URL + "/languages/";
const ADD_LANGUAGE_URL =   BASE_URL + "/languages";
const GET_LANGUAGE_URL = BASE_URL +"/languages"
const GET_GOOGLE_CLOUD_LANGUAGES = BASE_URL +"/languages/google"
const CHANGE_STATUS_URL = BASE_URL+"/languages/"+"update-status/"



export const getLanguage = (searchParams?:string) =>{
    return axios.get(GET_LANGUAGE_URL+"?"+searchParams);
}

export const deleteLanguage = (language:string) =>{
    return axios.delete(DELETE_LANGUAGE_URL + language);
}

export const saveLanguage = (language:{id:string})=>{
    const url = SAVE_LANGUAGE_URL + language.id;
    if(language.id !== ""){
        return axios.patch(url, language);
    }
    return axios.post(url, language);
}

export const addLanguage = (language:{id:string})=>{
    const url = SAVE_LANGUAGE_URL + language.id;
    if(language.id){
   
        return axios.patch(url, language);
    }
    return axios.post(ADD_LANGUAGE_URL, language);
}



export const changeStatus = (language:{id:string,status:string})=>{
    const url = CHANGE_STATUS_URL + language.id;
    const newStatus= language.status =="active" ? "inactive" :"active"
    const payload = {
        status:newStatus
    }
    if(language.id){
        
        return axios.patch(url, payload);
    }

}


export const getAllGoogleLanguage = ()=>{
    return axios.get(GET_GOOGLE_CLOUD_LANGUAGES);
}


export const addBulkLanguage = (language:TAddBulkLanguagePayload)=>{
  
    return axios.post(ADD_LANGUAGE_URL, language);
}